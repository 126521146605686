import {
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_STARTED,
    LOGOUT_USER_STARTED,
    LOGOUT_USER_SUCCESS
} from './type';
import {loginServiceApi} from "../../../api/authentication/AuthenticationApi";

const loginUserStarted = () => ({
    type: LOGIN_USER_STARTED,
});

const loginUser = (login, password) => (dispatch) => {
  dispatch(loginUserStarted());
  loginServiceApi(login, password).then((data) => {
      localStorage.setItem('token', data.accessToken);
          dispatch({
              type: LOGIN_USER_SUCCESS,
              payload: data,
          })
      }).catch(error => {
      dispatch({
          type: LOGIN_USER_FAILURE,
          error
      })
  });
};

const logoutUserStarted = () => ({
   type: LOGOUT_USER_STARTED,
});

const logoutUser = () => (dispatch) => {
  dispatch(logoutUserStarted());
  localStorage.removeItem('token');
  dispatch({
      type: LOGOUT_USER_SUCCESS,
  });
};


export {
    loginUser,
    logoutUser
};
