import {
    applyFilter,
    getAllProducts,
    getProductById,
    getTopThreeProducts,
    removeFilter
} from "./action";

const getTopThreeProductsAction = () => getTopThreeProducts();
const getAllProductsAction = () => getAllProducts();
const applyFilterAction = (filter) => applyFilter(filter);
const removeFilterAction = (filter) => removeFilter(filter);
const getProductByIdAction = (productId) => getProductById(productId);

export default {
    getTopThreeProductsAction,
    getAllProductsAction,
    applyFilterAction,
    removeFilterAction,
    getProductByIdAction,
}
