import React, {Component} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import './../GlobalAdmin.scss';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default class EventPopUpComponent extends Component {

    constructor(props) {
        super(props);
        if(!props.isAdding) {
            this.state = {
                text: props.event.text || '',
                title: props.event.title || '',
                date: new Date(props.event.eventDate) || new Date(),
                venue: props.event.venue || '',
                link: props.event.link || '',
                isLinkValid: true,
                isTitleValid: true,
                isTextValid: true,
                isError: false,
            }
        } else {
            this.state = {
                text: '',
                title: '',
                venue: '',
                link: '',
                date: new Date(),
                isLinkValid: true,
                isTitleValid: false,
                isTextValid: false,
                isError: false,
            }
        }
    }

    titleValidation = (title) => {
      return title.length > 0 && title.length < 30;
    };

    textValidation = (text) => {
        return text.length > 0 && text.length < 300;
    };

    linkValidation = (link) => {
      const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
      return link.match(urlRegexp) || link === '';
    };

    handleTitleChange = (event) => {
      this.setState({
          title: event.target.value,
          isTitleValid: this.titleValidation(event.target.value),
      });
    };

    handleTextChange = (event) => {
      this.setState({
          text: event.target.value,
          isTextValid: this.textValidation(event.target.value),
      });
    };

    handleVenueChange = (event) => {
        this.setState({
            venue: event.target.value,
        });
    };

    handleLinkChange = (event) => {
      this.setState({
          link: event.target.value,
          isLinkValid: this.linkValidation(event.target.value),
      });
    };

    addNewEvent = (event) => {
        event.preventDefault();
        const { isTextValid, isTitleValid, isLinkValid, title, text, date, link, venue } = this.state;
        const { addNewEventMethod, getAllEventsMethod, handleClose } = this.props;
        if(isTextValid && isTitleValid && isLinkValid) {
            this.setState({
                isError: false
            });
            const event = {
              title: title,
              text: text,
              eventDate: date,
              venue: venue,
              link: link,
            };
            addNewEventMethod(event).then(() => {
                getAllEventsMethod();
                handleClose();
            })
        } else {
            this.setState({
                isError: true,
            })
        }
    };

    editEvent = (event) => {
        event.preventDefault();
        const { isTextValid, isTitleValid, isLinkValid, title, text, date, link, venue } = this.state;
        const { editEventMethod, getAllEventsMethod, handleClose } = this.props;
        const eventFromProps = this.props.event;
        if(isTextValid && isTitleValid && isLinkValid) {
            this.setState({
                isError: false
            });
            const event = {
                title: title,
                text: text,
                eventDate: date,
                link: link,
                venue: venue,
            };
            editEventMethod(event, eventFromProps.id).then(() => {
                getAllEventsMethod();
                handleClose();
            })
        } else {
            this.setState({
                isError: true,
            })
        }
    };

    handleOnSaveClick = (event) => {
        const { isAdding } = this.props;
        if(isAdding) {
            this.addNewEvent(event);
        } else {
            this.editEvent(event);
        }
    };

    setStartDate = (date) => {
      this.setState({
          date: date,
      });
    };


    render() {
        const { isShow, handleClose, isAdding, event } = this.props;
        return (
            <div className='admin-modal-center'>
                <Modal show={isShow} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                isAdding ?
                                    'Nová událost' :
                                    event.title
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className="admin-margin">
                                <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Nadpis</Form.Label>
                                    <Form.Control isInvalid={!this.state.isTitleValid}
                                           id="form-horizontal-text"
                                           placeholder="Nadpis" value={this.state.title} name="title"
                                           onChange={this.handleTitleChange}
                                    />
                                </Form.Group>
                            </div>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Text</Form.Label>
                                <Form.Control isInvalid={!this.state.isTextValid}
                                              as='textarea'
                                              id="form-horizontal-text" rows={8}
                                              placeholder="Text" value={this.state.text}
                                              onChange={this.handleTextChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Datum události</Form.Label>
                                <DatePicker
                                    minDate={new Date()}
                                    dateFormat="d. M. yyyy"
                                    selected={this.state.date}
                                    onChange={date => this.setStartDate(date)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Místo konání</Form.Label>
                                <Form.Control id="form-horizontal-text"
                                              type="text"
                                              placeholder="Místo konání" value={this.state.venue} name="venue"
                                              onChange={this.handleVenueChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Link</Form.Label>
                                <Form.Control isInvalid={!this.state.isLinkValid}
                                              id="form-horizontal-text"
                                              type="text"
                                              placeholder="Link" value={this.state.link} name="link"
                                              onChange={this.handleLinkChange}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.isError &&
                            <p className="error-validation">
                                Chybně vyplněné údaje
                            </p>
                        }
                            <Button variant='outline-secondary' onClick={handleClose}>
                                Zrušit
                            </Button>
                            <Button variant='outline-success' onClick={this.handleOnSaveClick}>
                                Uložit
                            </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
