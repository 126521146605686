import React, {Component} from "react";
import LoadingDashboardComponent from "../../../common/LoadingDashboardComponent";
import GertnerCheckboxComponent from "../../../common/GertnerCheckboxComponent";
import './PaymentMethodsComponent.scss';

export default class PaymentMethodsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChanging: false
        }
    }

    componentDidMount() {
        const {getPaymentMethods, selectPaymentMethod} = this.props;
        getPaymentMethods().then(() => {
            const { paymentMethods } = this.props;
            selectPaymentMethod(paymentMethods[0]);
        });
    }

    handleCheckPaymentMethod = (paymentMethod) => {
        const { selectPaymentMethod } = this.props;
        selectPaymentMethod(paymentMethod);
        this.setState({
            isChanging: !this.state.isChanging,
        });

    };

    isCheckedVerify(method) {
        const { paymentMethod } = this.props;
        if(paymentMethod){
            return paymentMethod.id === method.id;

        }
        return false;
    }

    render() {
        const {paymentMethods} = this.props;
        if (!paymentMethods || paymentMethods.isLoading) {
            return (<LoadingDashboardComponent/>)
        } else if (paymentMethods && !paymentMethods.error) {
            return (
                <div className='payment-method-container'>
                    <div className='payment-method-container__title'>
                        Vyberte způsob platby
                    </div>
                    <div className='payment-method-container__selection'>
                        {
                            paymentMethods.filter(method => method.enabled === true).map(method => {
                                return (
                                    <GertnerCheckboxComponent
                                        isChecked={this.isCheckedVerify(method)}
                                        title={method.description}
                                        price={method.price}
                                        checkAction={() => this.handleCheckPaymentMethod(method)}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }
}
