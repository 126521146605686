import React, {Component} from "react";
import './CartBannerComponent.scss';
import {
    bottleNameRest,
    derivedBottleName,
    getCountBottleFromShoppingCart
} from "../../../../admin/common/utils/BasicUtils";
import {scroller} from "react-scroll";
import {withRouter} from "react-router";
import cartSvg from './../../../../assets/images/eshop/cart.svg';
import {Image} from "react-bootstrap";

class CartBannerComponent extends Component {

    scrollToCart = () => {
        const {pathname} = this.props.location;
        const { history } = this.props;
        const hasNumber = /\d/;
        let elementToScroll = 'shoping-cart-products';
        if(pathname.match(hasNumber)) {
            elementToScroll = 'shopping-car-product-detail';
        } else if (pathname === '/') {
            history.push('/eshop');
        }
        scroller.scrollTo(elementToScroll, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    getRestBottleToCartText() {
        const { shoppingCart } = this.props;
        const count = getCountBottleFromShoppingCart(shoppingCart);
        return bottleNameRest(count);
    }

    getCountBottleText(){
        const { shoppingCart } = this.props;
        const count = getCountBottleFromShoppingCart(shoppingCart);
        const bottleName = derivedBottleName(count);

        return count === 0 ? 'Prázdný košík' : count+' '+bottleName;
    }

    render() {
        return(
            <div className='cart-banner-container'>
                <div className='cart-banner-container__contacts'>
                    <div>
                        <a href="mailto:info@vinarstvigertner.cz">info@vinarstvigertner.cz</a>
                    </div>
                    <div>
                        <a href="tel:+420723210156'">+420 723 210 156</a>
                    </div>
                </div>
                <div className='cart-banner-container__cart-info' onClick={this.scrollToCart}>
                    <div className='cart-banner-container__info'>
                        <div>{this.getCountBottleText()}</div>
                        <div className='cart-banner-container__info--rest-bottle'>{this.getRestBottleToCartText()}</div>
                    </div>
                    <div className='cart-banner-container__icon'>
                        <Image src={cartSvg} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CartBannerComponent);
