import {connect} from "react-redux";
import ShoppingCartComponent from "./ShoppingCartComponent";
import ShoppingCartOperation from './duck/operation';

const mapStateToProps = (state) => {

    const { shoppingCart } = state.shoppingCart;

    return { shoppingCart };
};

const mapDispatchToProps = (dispatch) => {

    const removeFromShoppingCart = (idx) => {
      return dispatch(ShoppingCartOperation.removeFromShoppingCartAction(idx));
    };

    const increaseCountBottle = (idx, count, price) => {
        return dispatch(ShoppingCartOperation.increaseCountBottleAction(idx, count, price));
    };

    const decreaseCountBottle = (idx, price) => {
        return dispatch(ShoppingCartOperation.decreaseCountBottleAction(idx, price));
    };

    const changeBottleCount = (idx, count, price) => {
        return dispatch(ShoppingCartOperation.changeBottleCountAction(idx, count, price));
    }

    const recountShoppingCart = () => {
        return dispatch(ShoppingCartOperation.recountSumShoppingCartAction());
    };

    const checkVoucher = (code) => {
        return dispatch(ShoppingCartOperation.checkVoucherAction(code));
    };

    const removeVoucher = () => {
        return dispatch(ShoppingCartOperation.removeVoucherAction());
    };

    return {
        removeFromShoppingCart,
        increaseCountBottle,
        decreaseCountBottle,
        recountShoppingCart,
        checkVoucher,
        removeVoucher,
        changeBottleCount
    }
};

const ShoppingCartContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShoppingCartComponent);


export default ShoppingCartContainer;
