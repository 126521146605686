export const GET_ALL_ORDERS_STARTED = 'GET_ALL_ORDERS_STARTED';
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS';
export const GET_ALL_ORDERS_FAILURE = 'GET_ALL_ORDERS_FAILURE';
export const GET_ORDERS_STATE_STARTED = 'GET_ORDERS_STATE_STARTED';
export const GET_ORDERS_STATE_SUCCESS = 'GET_ORDERS_STATE_SUCCESS';
export const GET_ORDERS_STATE_FAILURE = 'GET_ORDERS_STATE_FAILURE';
export const CHANGE_ORDER_STATE_STARTED = 'CHANGE_ORDER_STATE_STARTED';
export const CHANGE_ORDER_STATE_SUCCESS = 'CHANGE_ORDER_STATE_SUCCESS';
export const CHANGE_ORDER_STATE_FAILURE = 'CHANGE_ORDER_STATE_FAILURE';
export const CONFIRM_ORDER_STATE_STARTED = 'CONFIRM_ORDER_STATE_STARTED';
export const CONFIRM_ORDER_STATE_SUCCESS = 'CONFIRM_ORDER_STATE_SUCCESS';
export const CONFIRM_ORDER_STATE_FAILURE = 'CONFIRM_ORDER_STATE_FAILURE';
export const GET_ORDER_STATISTICS_STARTED = 'GET_ORDER_STATISTICS_STARTED';
export const GET_ORDER_STATISTICS_SUCCESS = 'GET_ORDER_STATISTICS_SUCCESS';
export const GET_ORDER_STATISTICS_FAILURE = 'GET_ORDER_STATISTICS_FAILURE';
export const GET_ORDERS_STATUS_STARTED = 'GET_ORDERS_STATUS_STARTED';
export const GET_ORDERS_STATUS_SUCCESS = 'GET_ORDERS_STATUS_SUCCESS';
export const GET_ORDERS_STATUS_FAILURE = 'GET_ORDERS_STATUS_FAILURE';
export const GET_ORDER_HISTORY_STARTED = 'GET_ORDER_HISTORY_STARTED';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAILURE = 'GET_ORDER_HISTORY_FAILURE';
export const CLEAN_ORDERS_REDUCER = 'CLEAN_ORDERS_REDUCER';
