import React, {PureComponent} from 'react';
import './EshopMenuComponent.scss';
import {withRouter} from "react-router";
import {scroller} from "react-scroll";
import {connect} from "react-redux";
import RulesOperation from "../../common/duck/operation";
import facebookIco from './../../../assets/images/icons/ikona-facebook.svg';
import instagramIco from './../../../assets/images/icons/ikona-instagram.svg';
import logoGertner from './../../../assets/images/icons/logo-gertner-vertikalni.svg';
import {Image} from "react-bootstrap";

class EshopMenuComponent extends PureComponent {

    redirectTo = (path) => {
        const { history } = this.props;
        history.push(path);
        window.location.reload();
        window.scrollTo(0,0)
    };

    scrollToCart = () => {
        const {pathname} = this.props.location;
        const hasNumber = /\d/;
        let elementToScroll = 'shoping-cart-products';
        if(pathname.match(hasNumber)) {
            elementToScroll = 'shopping-car-product-detail';
        }
        scroller.scrollTo(elementToScroll, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    goToHome = () => {
        const { history } = this.props;
        history.push('/');
        window.location.reload();
        window.scrollTo(0,0,);
    };

    render() {
        return (
            <div className='eshop-menu-container'>
                <div className='eshop-menu-container__contacts'>
                    <div>
                        <a href='mailto:info@vinarstvigertner.cz'>info@vinarstvigertner.cz</a>
                    </div>
                    <div>
                        <a href='tel:+420723210156'>+420 723 210 156</a>
                    </div>
                </div>
                <div className='eshop-menu-container__media'>
                    <a href='https://www.facebook.com/vinarstvigertner/'>
                        <Image src={facebookIco} />
                    </a>
                    <a href='https://www.instagram.com/vinarstvigertner/'>
                        <Image src={instagramIco} />
                    </a>
                </div>
                <div className='eshop-menu-container__logo' onClick={this.goToHome}>
                    <Image src={logoGertner}/>
                </div>
                <div className='eshop-menu-container__navigation'>
                    <ul>
                        <li>
                            <span onClick={() => this.redirectTo('/')}>O vinařství</span>
                        </li>
                        <li>
                            <span onClick={() => this.redirectTo('/eshop')}>Naše vína</span>
                        </li>
                        <li>
                            <span onClick={this.scrollToCart}>Nákupní košík</span>
                        </li>
                        <li>
                            <span onClick={this.props.showRules}>Obchodní podmínky</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    const showRules = () => {
        dispatch(RulesOperation.showRulesAction());
    };
    return {
        showRules,
    }
};

EshopMenuComponent = connect(mapStateToProps, mapDispatchToProps)(EshopMenuComponent);

export default withRouter(EshopMenuComponent);
