import {
    GET_ALL_EVENTS_STARTED,
    GET_ALL_EVENTS_SUCCESS,
    GET_ALL_EVENTS_FAILURE,
    ADD_NEW_EVENT_FAILURE,
    ADD_NEW_EVENT_SUCCESS,
    ADD_NEW_EVENT_STARTED,
    EDIT_EVENT_FAILURE,
    EDIT_EVENT_SUCCESS,
    EDIT_EVENT_STARTED,
    REMOVE_EVENT_FAILURE,
    REMOVE_EVENT_SUCCESS, REMOVE_EVENT_STARTED,
} from "./type";

const initialState = {
    events: null,
    isLoading: false,
};


const eventsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_EVENTS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_EVENTS_SUCCESS: {
            return {
                ...state,
                events: action.payload,
                isLoading: false
            }
        }

        case GET_ALL_EVENTS_FAILURE: {
            return {
                ...state,
                error: action.error.message,
                isLoading: false,
            }
        }

        case ADD_NEW_EVENT_STARTED: {
            return {
                ...state,
            }
        }

        case ADD_NEW_EVENT_SUCCESS: {
            return {
                ...state,
                error: null
            }
        }

        case ADD_NEW_EVENT_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        case EDIT_EVENT_STARTED: {
            return {
                ...state,
            }
        }

        case EDIT_EVENT_SUCCESS: {
            return {
                ...state,
                error: null
            }
        }

        case EDIT_EVENT_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        case REMOVE_EVENT_STARTED:{
            return {
                ...state,
            }
        }

        case REMOVE_EVENT_SUCCESS: {
            return {
                ...state,
                error: null
            }
        }

        case REMOVE_EVENT_FAILURE:{
            return {
                ...state,
                error: action.error.message,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default eventsReducer;
