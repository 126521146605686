import {
    ADD_TO_SHOPPING_CART, CHANGE_BOTTLE_COUNT,
    CHECK_VOUCHER_CODE_FAILURE,
    CHECK_VOUCHER_CODE_STARTED,
    CHECK_VOUCHER_CODE_SUCCESS,
    CLEAN_SHOPPING_CART,
    DECREASE_COUNT_BOTTLE,
    INCREASE_COUNT_BOTTLE,
    RECOUNT_SUM_OF_SHOPPING_CART,
    REMOVE_FROM_SHOPPING_CART,
    REMOVE_VOUCHER_CODE,
} from "./type";
import {recountShoppingCart} from "../../utils/EshopUtils";

const initialState = {
    shoppingCart: {
        products: [],
        sumOfShoppingCart: 0,
        sumNoVat: 0,
        voucher: null,
        voucherValid: true,
    },
};

const shoppingCartReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_TO_SHOPPING_CART: {
            return {
                ...state,
                shoppingCart: {
                    products: state.shoppingCart.products.concat([
                        {
                            product: action.product,
                            count: action.count,
                            price: action.price
                        }
                    ]),
                    voucher: state.shoppingCart.voucher,
                    voucherValid: state.shoppingCart.voucherValid
                }
            }
        }

        case REMOVE_FROM_SHOPPING_CART: {
            const { products } = state.shoppingCart;
            return {
                ...state,
                shoppingCart: {
                    products: state.shoppingCart.products.filter((product) => {
                        return product !== products[action.productId];
                    }),
                    voucher: state.shoppingCart.voucher,
                    voucherValid: state.shoppingCart.voucherValid,
                }
            }
        }

        case INCREASE_COUNT_BOTTLE: {
            const lengthCart = state.shoppingCart.products.length;
            return {
                ...state,
                shoppingCart: {
                    products: [
                        ...state.shoppingCart.products.slice(0, action.productId),
                        {
                            ...state.shoppingCart.products[action.productId],
                            count: state.shoppingCart.products[action.productId].count + action.count,
                            price: state.shoppingCart.products[action.productId].price + action.price
                        },
                        ...state.shoppingCart.products.slice(action.productId+1, lengthCart)
                    ],
                    voucher: state.shoppingCart.voucher,
                    voucherValid: state.shoppingCart.voucherValid,
                }
            }
        }

        case CHANGE_BOTTLE_COUNT: {
            const lengthCart = state.shoppingCart.products.length;
            console.log('count is: ' + action.count + ' and price is: ' + action.price)
            return {
                ...state,
                shoppingCart: {
                    products: [
                        ...state.shoppingCart.products.slice(0, action.productId),
                        {
                            ...state.shoppingCart.products[action.productId],
                            count: action.count,
                            price: action.price
                        },
                        ...state.shoppingCart.products.slice(action.productId+1, lengthCart)
                    ],
                    voucher: state.shoppingCart.voucher,
                    voucherValid: state.shoppingCart.voucherValid
                }
            }
        }

        case DECREASE_COUNT_BOTTLE: {
            const lengthCart = state.shoppingCart.products.length;
            return {
                ...state,
                shoppingCart: {
                    products: [
                        ...state.shoppingCart.products.slice(0, action.productId),
                        {
                            ...state.shoppingCart.products[action.productId],
                            count: state.shoppingCart.products[action.productId].count-1,
                            price: state.shoppingCart.products[action.productId].price - action.price
                        },
                        ...state.shoppingCart.products.slice(action.productId+1, lengthCart)
                    ],
                    voucher: state.shoppingCart.voucher,
                    voucherValid: state.shoppingCart.voucherValid
                }
            }
        }

        case RECOUNT_SUM_OF_SHOPPING_CART: {
            const recountedSum = recountShoppingCart(state.shoppingCart.products, state.shoppingCart.voucher);
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    sumOfShoppingCart: recountedSum.sumWithVat,
                    sumNoVat: recountedSum.sumNoVat,
                    ...state.shoppingCart.voucher,
                    voucherValid: state.shoppingCart.voucherValid
                }
            }
        }

        case CHECK_VOUCHER_CODE_STARTED: {
            return {
                ...state,
            }
        }

        case CHECK_VOUCHER_CODE_SUCCESS: {
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    voucher: action.voucher,
                    voucherValid: true
                }
            }
        }

        case CHECK_VOUCHER_CODE_FAILURE: {
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    voucher: null,
                    voucherValid: false,
                }
            }
        }

        case REMOVE_VOUCHER_CODE: {
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    voucher: null,
                    voucherValid: true,
                }
            }
        }

        case CLEAN_SHOPPING_CART : {
            return {
                ...state,
                shoppingCart: {
                    products: [],
                    sumOfShoppingCart: 0,
                    voucher: null,
                    voucherValid: true,
                },

            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default shoppingCartReducer;
