import {connect} from "react-redux";
import ProductFullDetailComponent from "./ProductFullDetailComponent";
import ProductOperation from "../../../duck/operation";
import ShoppingCartOperation from "../../../cart/duck/operation";

const mapStateToProps = (state) => {

    const { selectedProduct, isLoading, error } = state.productsDashboard;

    return { selectedProduct, isLoading, error };
};

const mapDispatchToProps = (dispatch) => {

    const getProductById = (productId) => {
       return dispatch(ProductOperation.getProductByIdAction(productId));
    };

    const addToShoppingCart = (product, count, price) => {
       return dispatch(ShoppingCartOperation.addToShoppingCartAction(product, count, price));
    };

    const recountShoppingCart = () => {
      return dispatch(ShoppingCartOperation.recountSumShoppingCartAction());
    };

    return {
        getProductById,
        addToShoppingCart,
        recountShoppingCart,
    }
};

const ProductFullDetailContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductFullDetailComponent);


export default ProductFullDetailContainer;
