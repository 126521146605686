import React, {PureComponent} from "react";
import './ContactCardComponent.scss';
import {Image} from "react-bootstrap";

export default class ContactCardComponent extends PureComponent {

    render() {
        const { image, name, text, phone, mail } = this.props;
        return (
            <div className='contact-card-container'>
                <div className='contact-card-container__image'>
                    <Image className='contact-card-container__img' src={image} />
                </div>
                <span className='contact-card-container__name'>{name}</span>
                <div className='contact-card-container__text'>{text}</div>
                <div className='contact-card-container__contacts'>
                    {
                        phone &&
                        <div className='contact-card-container__phone'>
                           <a href={`tel:${phone}`}>{phone}</a>
                        </div>
                    }
                    {
                        mail &&
                        <div className='contact-card-container__mail'>
                            e-mail: <a href={`mailto:${mail}`}> {mail} </a>
                        </div>
                    }
                </div>
            </div>
        );
    }

}
