import {connect} from "react-redux";
import DeliveryComponent from "./DeliveryComponent";
import OrdersOperation from "../duck/operation";

const mapStateToProps = (state) => {

    const { typesDelivery } = state.orderConfirmation;
    const { typeDelivery } = state.orderConfirmation.order;
    return { typesDelivery, typeDelivery };
};

const mapDispatchToProps = (dispatch) => {

    const getTypesDelivery = () => {
        return dispatch(OrdersOperation.getTypesDeliveryAction());
    };

    const selectTypeDelivery = (delivery) => {
        dispatch(OrdersOperation.selectTypeDeliveryAction(delivery));
    };

    return {
        getTypesDelivery,
        selectTypeDelivery
    }
};

const DeliveryContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryComponent);


export default DeliveryContainer;
