import React, {Component} from "react";
import LoadingComponent from "../../common/LoadingComponent";
import './OrderStatisticsComponent.scss';
import OrderPerMonthChart from "./charts/OrderPerMonthChart";
import ProductOrdersChart from "./charts/ProductOrdersChart";

export default class OrderStatisticsComponent extends Component {

    componentDidMount() {
        this.props.getOrderStatistics();
    }

    getProductsArray = () => {
        const {statistics} = this.props;
        let products = [];
        let ordersCount = [];
        if (statistics) {
            Object.entries(statistics.countOfSelling).map(stat => {
                products.push(stat[0]);
                ordersCount.push(stat[1]);
            });
            return {
                products: products,
                ordersCount: ordersCount,
            };
        }
    };

    render() {
        const {statistics} = this.props;
        if (this.props.isLoading || !statistics) {
            return (
                <LoadingComponent/>
            )
        } else {
            const {statisticsOrderPerMonthModel} = statistics;
            const productSellsData = this.getProductsArray();
            return (
                <div className="order-stats-container">
                    <span className='title-page'>Statistiky</span>
                    <span className="">Počet objednávek</span>
                    <OrderPerMonthChart orders={statisticsOrderPerMonthModel}/>
                    <span className="">Prodej produktů</span>
                    <ProductOrdersChart products={productSellsData.products} countOrders={productSellsData.ordersCount}/>
                </div>
            )
        }
    }
}
