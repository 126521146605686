import React, {Component} from "react";
import './ProductIteratorComponent.scss';
import {derivedBottleName} from "../../../admin/common/utils/BasicUtils";

export default class ProductIteratorComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countBottle: 1,
        };
    }

    handleMinus = () => {
        this.props.onMinus();
        if(this.state.countBottle !== 0){
            this.setState({
                countBottle: this.state.countBottle-1,
            })
        }
    };

    handlePlus = () => {
      this.props.onPlus();
      this.setState({
          countBottle: this.state.countBottle+1,
      });
    };

    render() {
        const { countBottle } = this.state;
        return(
          <div className='product-iterator-container'>
              <button className='product-iterator-container__btn' onClick={this.handleMinus}>-</button>
              <span className='product-iterator-container__text'>{countBottle} {derivedBottleName(countBottle)}</span>
              <button className='product-iterator-container__btn' onClick={this.handlePlus}>+</button>
          </div>
        );
    }

}
