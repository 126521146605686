import {
    SHOW_RULES,
    HIDE_RULES
} from "./type";

const initialState = {
    isRulesShow: false,
};


const shoppingRules = (state = initialState, action) => {
    switch (action.type) {

        case SHOW_RULES: {
            return {
                ...state,
                isRulesShow: true,
            }
        }

        case HIDE_RULES: {
            return {
                ...state,
                isRulesShow: false,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default shoppingRules;
