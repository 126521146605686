import {combineReducers} from "redux";
import authReducer from "./admin/login/duck/reducer";
import ordersReducer from "./admin/orders/duck/reducer";
import productsReducer from "./admin/products/duck/reducer";
import toastReducer from "./admin/common/toast/duck/reducer";
import eventsReducer from "./admin/events/duck/reducer";
import productsDashboardReducer from "./application/eshop/duck/reducer";
import eventsDashboard from "./application/dashboard/events/duck/reducer";
import constantsReducer from "./admin/dashboard/duck/reducer";
import shoppingCartReducer from "./application/eshop/cart/duck/reducer";
import vouchersReducer from "./admin/vouchers/duck/reducer";
import eshopToastReducer from "./application/eshop/eshop-toast/duck/reducer";
import orderConfirmationReducer from "./application/eshop/order-confirmation/duck/reducer";
import medalsReducer from "./admin/actualities/duck/reducer";
import shoppingRules from "./application/common/duck/reducer";
import subsidy from "./application/subsidy/duck/reducer";

export default () => combineReducers({
    authReducer: authReducer,
    ordersReducer: ordersReducer,
    productsReducer: productsReducer,
    toastReducer: toastReducer,
    medals: medalsReducer,
    eventsReducer: eventsReducer,
    productsDashboard: productsDashboardReducer,
    eventsDashboard: eventsDashboard,
    constants: constantsReducer,
    shoppingCart: shoppingCartReducer,
    vouchers: vouchersReducer,
    eshopToast: eshopToastReducer,
    orderConfirmation: orderConfirmationReducer,
    shoppingRules: shoppingRules,
    subsidy: subsidy
})
