import React, {Component} from "react";
import './PhotoBannerComponent.scss';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {Image} from "react-bootstrap";

export default class PhotoBannerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    handleClickLightBox = (idx) => {
      this.setState({
          isOpen: true,
          photoIndex: idx,
      })
    };

    render() {
        const { images } = this.props;
        const { isOpen, photoIndex } = this.state;
        return (
          <div className='photo-banner-container'>
              {
                  images.map((image, idx) => {
                      return (
                          <Image onClick={() => this.handleClickLightBox(idx)} className='photo-banner-container__image' src={image} />
                      )
                  })
              }

              {
                  isOpen &&(
                      <Lightbox
                          mainSrc={images[photoIndex]}
                          nextSrc={images[(photoIndex + 1) % images.length]}
                          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                              this.setState({
                                  photoIndex: (photoIndex + images.length - 1) % images.length,
                              })
                          }
                          onMoveNextRequest={() =>
                              this.setState({
                                  photoIndex: (photoIndex + 1) % images.length,
                              })
                          }
                      />
                  )
              }
          </div>
        );
    }

}
