import React, {Component} from "react";
import EventHeaderComponent from "./header/EventHeaderComponent";
import EventDashboardContentContainer from "./content/EventDashboardContentContainer";

export default class EventDashboardComponent extends Component {

    render() {
        return (
            <div>
                <EventHeaderComponent />
                <EventDashboardContentContainer />
            </div>

        );
    }
}
