import {
    cleanCompleteOrder,
    getPaymentMethods,
    getTypesDelivery,
    selectPaymentMethod,
    selectTypeDelivery,
    submitOrder
} from "./action";


const getTypesDeliveryAction = () => getTypesDelivery();
const selectTypeDeliveryAction = (delivery) => selectTypeDelivery(delivery);
const submitOrderAction = (order) => submitOrder(order);
const cleanCompletedOrderAction = () => cleanCompleteOrder();
const getPaymentMethodsAction = () => getPaymentMethods();
const selectPaymentMethodAction = (paymentMethod) => selectPaymentMethod(paymentMethod);

export default {
    getTypesDeliveryAction,
    selectTypeDeliveryAction,
    submitOrderAction,
    cleanCompletedOrderAction,
    getPaymentMethodsAction,
    selectPaymentMethodAction
}
