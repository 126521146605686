export const GET_TOP_THREE_PRODUCTS_STARTED = 'GET_TOP_THREE_PRODUCTS_STARTED';
export const GET_TOP_THREE_PRODUCTS_SUCCESS = 'GET_TOP_THREE_PRODUCTS_SUCCESS';
export const GET_TOP_THREE_PRODUCTS_FAILURE = 'GET_TOP_THREE_PRODUCTS_FAILURE';
export const GET_ALL_PRODUCTS_STARTED = 'GET_ALL_PRODUCTS_STARTED';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE';
export const GET_PRODUCT_BY_ID_STARTED = 'GET_PRODUCT_BY_ID_STARTED';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_FAILURE = 'GET_PRODUCT_BY_ID_FAILURE';
export const APPLY_FILTER = 'APPLY_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
