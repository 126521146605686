import React,{Component} from "react";
import {MdAddBox} from "react-icons/all";
import "./AddButtonComponent.scss";

export default class AddButtonComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            size: '50px',
            color: 'rgba(83,83,83,0.71)',
        }
    }

    handleOnMouseEnter = () => {
      this.setState({
          size: '50px',
          color: 'rgba(0,102,103,0.82)',
      });
    };

    handleOnMouseLeave = () => {
        this.setState({
            size: '50px',
            color: 'rgba(83,83,83,0.71)',
        });
    };

    render() {
        const { color, size } = this.state;
        return(
            <span className="add-box">
                <MdAddBox size={size} color={color}
                          onMouseEnter={this.handleOnMouseEnter}
                          onMouseLeave={this.handleOnMouseLeave}

                />
            </span>
        )
    }

}
