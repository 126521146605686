import {addNewVoucher, changeVoucherActivity, deleteVoucher, getAllVouchers} from "./action";

const getAllVouchersAction = () => getAllVouchers();
const changeVoucherActivityAction = (voucherId) => changeVoucherActivity(voucherId);
const deleteVoucherAction = (voucherId) => deleteVoucher(voucherId);
const addNewVoucherAction = (voucher) => addNewVoucher(voucher);

export default {
    getAllVouchersAction,
    changeVoucherActivityAction,
    deleteVoucherAction,
    addNewVoucherAction,
}
