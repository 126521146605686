import React, {PureComponent} from "react";
import {Modal} from "react-bootstrap";
import GertnerButtonComponent from "../common/GertnerButtonComponent";
import './AgeVerificationComponent.scss';

export default class AgeVerificationComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            accepted: false,
        }
    }

    componentDidMount() {
        this.setState({
            accepted: !!localStorage.getItem('age-verification'),
        });
    }

    handleAccept = () => {
      localStorage.setItem('age-verification', true);
      this.setState({
          accepted: true,
      })
    };

    render() {
        return (
                <Modal show={!this.state.accepted} size={'lg'}>
                    <div className='age-verification-container'>
                    <div className='age-verification-container__header'>Vstupujete na stránky s prodejem alkoholu.</div>
                    <div className='age-verification-container__body'>
                        Pokračováním potvrzuji, že jsem starší 18 let.
                    </div>
                    <div className='age-verification-container__button'>
                        <GertnerButtonComponent
                            text={'Pokračovat'}
                            handleClick={this.handleAccept}
                        />
                    </div>
                    </div>
                </Modal>
        )
    }

}
