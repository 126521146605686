import { connect } from 'react-redux';
import EshopToastComponent from "./EshopToastComponent";
import EshopToastOperation from "./duck/operation";

const mapStateToProps = (state) => {
    const { toasts } = state.eshopToast;
    return { toasts };
};

const mapDispatchToProps = (dispatch) => {

    const hideToast = (idx) => {
        dispatch(EshopToastOperation.hideToastAction(idx));
    };

    return {
        hideToast
    }
};

const EshopToastContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EshopToastComponent);


export default EshopToastContainer;
