import { connect } from 'react-redux';
import AdminHomeComponent from "./AdminHomeComponent";

const mapStateToProps = (state) => {
    const { oidc } = state.authReducer;
    return {
        oidc,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const AdminHomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminHomeComponent);

export default AdminHomeContainer;
