import {HIDE_TOAST_ACTION, SHOW_TOAST_ACTION} from "./type";

const showToast = (title, message, isError) => (dispatch) => {
    dispatch({
        type: SHOW_TOAST_ACTION,
        payload: {
            title,
            message,
            isError,
        }
    });
};

const hideToast = () => (dispatch) => {
  dispatch({
      type: HIDE_TOAST_ACTION,
  });
};

export {
    showToast, hideToast
};
