import {connect} from "react-redux";
import ProductListComponent from "./ProductListComponent";
import ProductOperation from "../../duck/operation";

const mapStateToProps = (state) => {

    const { allProducts, isLoading, error, filters } = state.productsDashboard;

    return { allProducts, isLoading, error, filters };
};

const mapDispatchToProps = (dispatch) => {

    const getAllProducts = () => {
        dispatch(ProductOperation.getAllProductsAction());
    };

    const removeFilter = (filter) => {
        dispatch(ProductOperation.removeFilterAction(filter));
    };

    return {
        getAllProducts,
        removeFilter,
    }
};

const ProductListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductListComponent);


export default ProductListContainer;
