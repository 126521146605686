import {
    LOGIN_USER_FAILURE,
    LOGIN_USER_STARTED,
    LOGIN_USER_SUCCESS, LOGOUT_USER_FAILURE,
    LOGOUT_USER_STARTED,
    LOGOUT_USER_SUCCESS
} from "./type";

const initialState = {
   oidc: {
       accessToken: localStorage.getItem('token'),
       isLogin: !!localStorage.getItem('token'),
       login: null,
   },
   error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER_STARTED: {
            return {
                ...state,
            }
        }

        case LOGIN_USER_SUCCESS: {
            return {
                ...state,
                oidc: {
                    accessToken: action.payload.accessToken,
                    isLogin: true,
                },
                error: null,
            }
        }

        case LOGIN_USER_FAILURE: {
            return {
                ...state,
                error: action.error.message,

            }
        }

        case LOGOUT_USER_STARTED: {
            return {
                ...state,
            }
        }

        case LOGOUT_USER_SUCCESS: {
            return {
                ...state,
                oidc: {
                    accessToken: null,
                    isLogin: false,
                },
                error: null,
            }
        }

        case LOGOUT_USER_FAILURE: {
            return {
                ...state,
                error: action.error,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default authReducer;
