import {
    ADD_NEW_EVENT_FAILURE,
    ADD_NEW_EVENT_STARTED,
    ADD_NEW_EVENT_SUCCESS, EDIT_EVENT_FAILURE, EDIT_EVENT_STARTED, EDIT_EVENT_SUCCESS,
    GET_ALL_EVENTS_FAILURE,
    GET_ALL_EVENTS_STARTED,
    GET_ALL_EVENTS_SUCCESS, REMOVE_EVENT_FAILURE, REMOVE_EVENT_STARTED, REMOVE_EVENT_SUCCESS
} from "./type";
import {addNewEventAPI, editEventAPI, getAllEventsAPI, removeEventAPI} from "../../../api/event/EventApi";
import {showToast} from "../../common/toast/duck/action";

const getAllEvents = () => {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_EVENTS_STARTED,
        });
        return getAllEventsAPI().then((data) => {
            dispatch({
                type: GET_ALL_EVENTS_SUCCESS,
                payload: data,
            });
        }).catch((error) => {
            dispatch({
                type: GET_ALL_EVENTS_FAILURE,
                error: error,
            });
        })
    }
};

const addNewEvent = (event) => {
    return (dispatch) => {
        dispatch({
            type: ADD_NEW_EVENT_STARTED,
        });
        return addNewEventAPI(event).then((data) => {
            dispatch({
                type: ADD_NEW_EVENT_SUCCESS
            });
            dispatch(showToast('Přidáno', 'Událost byla úspěšně přidána', false));
        }).catch(error => {
            dispatch({
                type: ADD_NEW_EVENT_FAILURE,
                error
            });
            dispatch(showToast('Chyba', 'Nepodařilo se přidat novou událost.', true));
        })
    }
};

const editEvent = (event, eventId) => {
  return (dispatch) => {
      dispatch({
          type: EDIT_EVENT_STARTED,
      });
      return editEventAPI(event, eventId).then((data) => {
         dispatch({
             type: EDIT_EVENT_SUCCESS
         });
          dispatch(showToast('Upraveno', 'Událost byla v pořádku upravena.', false));
      }).catch(error => {
          dispatch({
              type: EDIT_EVENT_FAILURE,
              error,
          });
          dispatch(showToast('Chyba', 'Nepodařilo se uložit úpravy.', true));
      });
  }
};

const removeEvent = (eventId) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_EVENT_STARTED,
        });
        return removeEventAPI(eventId).then(() => {
            dispatch({
                type: REMOVE_EVENT_SUCCESS,
            });
            dispatch(showToast('Smazáno', 'Událost byla úspěšně smazána.', false));
        }).catch(error => {
            dispatch({
                type: REMOVE_EVENT_FAILURE,
                error,
            });
            dispatch(showToast('Chyba', 'Nepodařilo se smazat událost.', true));
        });
    }
};

export {
    getAllEvents,
    addNewEvent,
    editEvent,
    removeEvent
}
