import ProductOperation from './duck/operation'
import {connect} from "react-redux";
import ProductComponent from "./ProductComponent";
import MedalOperation from "../actualities/duck/operation";

const mapStateToProps = (state) => {
    const { products, isLoading } = state.productsReducer;
    const { category, kind, color,  } = state.constants;
    const { medals } = state.medals;
    return { products, isLoading, category, kind, color, medals };
};

const mapDispatchToProps = (dispatch) => {

    const getAllProducts = () => {
        dispatch(ProductOperation.getAllProductsAction());
    };

    const updateProduct = (productId, product) => {
        return dispatch(ProductOperation.updateProductAction(productId, product));
    };

    const addNewProduct = (product) => {
        return dispatch(ProductOperation.addNewProductAction(product));
    };

    const removeProduct = (productId) => {
        return dispatch(ProductOperation.removeProductAction(productId));
    };

    const getAllMedals = () => {
      return dispatch(MedalOperation.getAllMedalsAction());
    };

    return {
        getAllProducts, updateProduct, addNewProduct, removeProduct, getAllMedals
    }
};

const ProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductComponent);


export default ProductsContainer;
