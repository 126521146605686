import {addNewProductAPI, getAllProductsAPI, removeProductAPI, updateProductAPI} from "../../../api/product/ProductApi";
import {
    GET_ALL_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_FAILURE,
    GET_ALL_PRODUCTS_STARTED,
    UPDATE_PRODUCT_STARTED,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    ADD_NEW_PRODUCT_STARTED,
    ADD_NEW_PRODUCT_SUCCESS,
    ADD_NEW_PRODUCT_FAILURE,
    REMOVE_PRODUCT_STARTED, REMOVE_PRODUCT_SUCCESS, REMOVE_PRODUCT_FAILURE
} from './type';
import {showToast} from "../../common/toast/duck/action";

const getAllProducts = () => (dispatch) => {
    dispatch({
        type: GET_ALL_PRODUCTS_STARTED,
    });
    return getAllProductsAPI().then((data) => {
        return dispatch({
            type: GET_ALL_PRODUCTS_SUCCESS,
            payload: {
                products: data
            }
        });
    }).catch(error => {
        dispatch({
            type: GET_ALL_PRODUCTS_FAILURE,
            error
        })
    });
};

const updateProduct = (productId, product) => {
   return (dispatch) => {
       dispatch({
           type: UPDATE_PRODUCT_STARTED,
       });
       return updateProductAPI(productId, product).then((data) => {
           dispatch({
               type: UPDATE_PRODUCT_SUCCESS,
           });
           dispatch(showToast('Editováno', `Víno: ${product.name} úspěšně editováno.`, false));
       }).catch(error => {
           dispatch({
               type: UPDATE_PRODUCT_FAILURE,
               error
           });
           dispatch(showToast('Chyba', `Nepodařilo se editovat víno: ${product.name}`, true));
       });
   }
};

const addNewProduct = (product) => (dispatch) => {
    dispatch({
        type: ADD_NEW_PRODUCT_STARTED,
    });
   return addNewProductAPI(product).then((data) => {
       dispatch({
           type: ADD_NEW_PRODUCT_SUCCESS,
       });
       dispatch(showToast('Úspěšně uloženo', `Víno: ${product.name} bylo úspěšně uloženo`, false));
    }).catch(error => {
        dispatch({
            type: ADD_NEW_PRODUCT_FAILURE,
            error
        });
       dispatch(showToast('Chyba', `Víno: ${product.name} se nepodařilo uložit`, true));
    });
};

const removeProduct = (productId) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_PRODUCT_STARTED,
        });
       return removeProductAPI(productId).then(() => {
            dispatch({
                type: REMOVE_PRODUCT_SUCCESS,
            });
            dispatch(showToast('Smazáno', 'Víno bylo ostraněno', false));
        }).catch(error => {
            dispatch({
                type: REMOVE_PRODUCT_FAILURE,
                error
            });
           dispatch(showToast('Chyba', 'Víno se nepodařilo ostranit', true));
        });
    };
};

export { getAllProducts, updateProduct, addNewProduct, removeProduct };
