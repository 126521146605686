import React, {Component} from "react";
import Chart from 'react-apexcharts';
import {withBreakpoints} from "react-breakpoints";

class ProductOrdersChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: props.products,
                }
            },
            series: [{
                data: props.countOrders,
            }],
        }
    }

    render() {
        const { currentBreakpoint, breakpoints } = this.props;
        let width = "700";
        if(breakpoints[currentBreakpoint] < breakpoints.tablet){
            width = "350";
        }
        return (
            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="bar" height="350" width={width} />
            </div>
        );
    }
}

export default withBreakpoints(ProductOrdersChart);
