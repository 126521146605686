import normalize from "normalize-strings";


export function derivedBottleName(count) {

    const parsedCount = parseInt(count);

    if(parsedCount === 0 || parsedCount > 4) {
        return 'lahví';
    } else if(parsedCount === 1) {
        return 'láhev';
    } else if(parsedCount === 2 || parsedCount === 3 || parsedCount === 4) {
        return 'láhve';
    } else {
        return 'lahví';
    }
}

export function bottleNameRest(count) {
    const derivedCount = derivedBottleName(6-count);
    if(count === 4) {
        return `Zbývají ${6-count} ${derivedCount} do plného kartónu`;
    } else if(count <= 3) {
        return 'Objednat lze minimálně 4 láhve.';
    } else if (count > 6) {
        return  'Nebojte se naplnit další kartón';
    } else if(count === 6) {
        return 'Hurá máte plný kartón.';
    } else {
        return `Zbývá ${6-count} ${derivedCount} do plného kartónu`;
    }
}


export function getCountBottleFromShoppingCart(shoppingCart) {

    const bottleCount = shoppingCart.products.reduce((total, product) => {
        return total + product.count;
    }, 0);

    console.log('Bottle count is ' + bottleCount);

    return bottleCount;
}

export function getImageProductPathByProduct(product) {
    let name = product.name.toLowerCase();
    name = normalize(name);
    name = name.replace('-', '');
    name = name.replace(' ', '_');
    name = name.concat(`.png`);
    return `/images/products/${name}`;
}
