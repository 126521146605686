export const validateProductName = (productName) => {
    return productName.length > 0 && productName.length < 20;
};

export const validateVintage = (vintage) => {
  const vintageAsNumber = parseInt(vintage, 10);
  return vintageAsNumber > 1900 && vintageAsNumber < 2100;
};

export const validateDescription = (description) => {
  return description.length > 0 && description.length < 250;
};

export const validatePrice = (price) => {
  const priceAsNumber = parseInt(price, 10);
  return priceAsNumber > 50 && priceAsNumber < 1000;
};

export const validateCategory = (category) => {
  return category.length > 1 && category.length < 35;
};

export const validateSugar = (sugar) => {
  return sugar > 0 && sugar < 50;
};
