import {
    SHOW_CART_TOAST,
    HIDE_CART_TOAST
} from './type';

const showToast = (product, count, price) => (dispatch) => {
    dispatch({
        type: SHOW_CART_TOAST,
        payload: {
            product,
            count,
            price
        }
    });
};

const hideToast = (idx) => (dispatch) => {
    dispatch({
        type: HIDE_CART_TOAST,
        index: idx,
    });
};

export {
    showToast, hideToast
};
