import {
    GET_ALL_EVENTS_STARTED,
    GET_ALL_EVENTS_SUCCESS,
    GET_ALL_EVENTS_FAILURE,
} from "./type";

const initialState = {
    events: null,
    isLoading: false,
};


const eventsDashboard = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_EVENTS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_EVENTS_SUCCESS: {
            return {
                ...state,
                events: action.payload,
                isLoading: false
            }
        }

        case GET_ALL_EVENTS_FAILURE: {
            return {
                ...state,
                error: action.error.message,
                isLoading: false,
            }
        }


        default: {
            return {
                ...state,
            };
        }
    }
};

export default eventsDashboard;
