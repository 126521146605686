import React, {Component} from "react";
import LoadingComponent from "../common/LoadingComponent";
import AddButtonComponent from "../common/buttons/AddButtonComponent";
import {AiFillDelete} from "react-icons/all";
import VoucherPopUpComponent from "./VoucherPopUpComponent";
import {Badge, Table} from "react-bootstrap";

export default class VoucherComponent extends Component{

    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
        }
    }

    componentDidMount() {
        const { getAllVouchers } = this.props;
        getAllVouchers();
    }

    handleChangeActivity = (voucherId) => {
        const { changeVoucherActivity, getAllVouchers } = this.props;
        changeVoucherActivity(voucherId).then(() =>{
               getAllVouchers();
         }
        );
    };

    handleDeleteVoucher = (voucherId) => {
        const { getAllVouchers, deleteVoucher} = this.props;
        deleteVoucher(voucherId).then(() => {
            getAllVouchers();
        });
    };

    handleClosePopUp = () => {
      this.setState({
          showPopUp: false,
      })
    };

    handelNewVoucher = () => {
      this.setState({
          showPopUp: true,
      });
    };

    render() {
        const { isLoading } = this.props;

        if(isLoading) {
            return (
                <LoadingComponent />
            )
        } else {
            const { vouchers, voucherConstants, getAllConstants } = this.props;
            if(!voucherConstants) {
                getAllConstants();
            }
            return (
                <div className="event-admin-container">
                    <span className='title-page'>Vouchery</span>
                    <span className='product-admin-container__new-btn' onClick={this.handelNewVoucher}>
                        <AddButtonComponent/>
                            Nový voucher
                    </span>
                    <Table striped hover className="event-admin-container__table">
                        <thead>
                        <tr>
                            <th>Jmeno</th>
                            <th>Sleva</th>
                            <th>Aktivita</th>
                        </tr>
                        </thead>
                        <tbody>
                        {vouchers.map((voucher, idx) => {
                            return (
                                <tr>
                                    <td>{voucher.name}</td>
                                    <td>{voucher.price} {voucher.typeOfVoucher === 'price' ? 'Kč' : '%'}</td>
                                    <td style={{cursor: "pointer"}} onClick={() => this.handleChangeActivity(voucher.id)}>{
                                        voucher.active ?
                                            <Badge variant='success'>Aktivní</Badge> :
                                            <Badge variant='danger'>Neaktivní</Badge>
                                    }
                                    </td>
                                    <td>
                                        <AiFillDelete className="product-admin-container__edit-icon"
                                                      onClick={() => this.handleDeleteVoucher(voucher.id)}
                                                      color={'#d42116'}
                                                      size={'30px'}
                                                      id={idx} />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    {
                        <VoucherPopUpComponent
                            isShow={this.state.showPopUp}
                            handleClose={this.handleClosePopUp}
                            addNewVoucherMethod={this.props.addNewVoucher}
                            getAllVouchersMethod={this.props.getAllVouchers}
                            voucherConstants={voucherConstants}
                        />
                    }
                </div>
            )
        }
    }

}
