import React, {Component} from "react";
import './DeliveryComponent.scss';
import GertnerCheckboxComponent from "../../../common/GertnerCheckboxComponent";
import LoadingDashboardComponent from "../../../common/LoadingDashboardComponent";

export default class DeliveryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChanging: false,
        }
    }

    componentDidMount() {
        const { getTypesDelivery, selectTypeDelivery } = this.props;
        getTypesDelivery().then(() => {
            const { typesDelivery } = this.props;
            selectTypeDelivery(typesDelivery[0]);
        });
    }

    handleCheckDelivery = (typeDelivery) => {
      const { selectTypeDelivery } = this.props;
      selectTypeDelivery(typeDelivery);
      this.setState({
          isChanging: !this.state.isChanging,
      });

    };

    isCheckedVerify(delivery) {
        const { typeDelivery } = this.props;
        if(typeDelivery){
            return delivery.id === typeDelivery.id;

        }
        return false;
    }

    render() {
        const { typesDelivery } = this.props;

        if(typesDelivery.isLoading) {
            return (
                <LoadingDashboardComponent/>
            )
        } else if(typesDelivery && !typesDelivery.error) {
            return (
                <div className='delivery-container'>
                    <div className='delivery-container__title'>
                        Jak vám víno doručíme ?
                    </div>
                    <div className='delivery-container__checkboxes'>
                        {
                            typesDelivery.map(typeDelivery => {
                                return (
                                    <GertnerCheckboxComponent
                                        isChecked={this.isCheckedVerify(typeDelivery)}
                                        title={typeDelivery.title}
                                        description={typeDelivery.description}
                                        price={typeDelivery.price}
                                        checkAction={() => this.handleCheckDelivery(typeDelivery)}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

}
