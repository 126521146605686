import React, { Component } from "react";
import { Toast } from "react-bootstrap";
import "./ToastComponent.scss"
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export default class ToastComponent extends Component{

    constructor(props) {
        super(props);
    }

    handleOnClose = () => {
      const { hideToast } = this.props;
      hideToast();
    };

    render() {
        const { showToast, toastTitle, toastMessage, isError } = this.props;
        let toastClassName;
        if(showToast) {
            toastClassName = "toast-component toast-component__hidden";
        }
        return(
            <Toast className={toastClassName} onClose={this.handleOnClose} show={showToast} delay={5000} autohide>
                <Toast.Header>
                    {
                        !isError ?
                            <FaCheckCircle color={'#18ff54'} size={'2em'}/>
                            : <FaTimesCircle color={'#ba0108'} size={'2em'}/>

                    }
                    <span className="toast-component__title">{toastTitle}</span>
                </Toast.Header>
                <Toast.Body>
                    <div className="toast-component__text">
                        {toastMessage}
                    </div>
                </Toast.Body>
            </Toast>
        )
    }
}
