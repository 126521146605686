import { connect } from 'react-redux';
import LoginOperation from './duck/operation';
import LoginComponent from "./LoginComponent";

const mapStateToProps = (state) => {
    const { error } = state.authReducer;
    return { error };
};

const mapDispatchToProps = (dispatch) => {
    const loginUser = (login, password) => {
        dispatch(LoginOperation.loginUserAction(login, password));
    };
    return {
        loginUser,
    };
};

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginComponent);


export default LoginContainer;
