import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import './../GlobalAdmin.scss';

export default class LoginComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            login: '',
            password: '',
        }
    }

    onClickLogin = () => {
        const { loginUser } = this.props;
        const { login, password } = this.state;
        loginUser(login, password);
    };

    handleEnterPress = (event) => {
        if(event.charCode === 13) {
            this.onClickLogin();
        }
    };

    handleLoginChange = (event) => {
        this.setState({login: event.target.value});
    };

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    };

    render() {
        const { error } = this.props;
        return(
            <div className="admin-login-center">
                <h3>Administrace</h3>
                <Form>
                    <Form.Group>
                        <Form.Control
                            type="text" placeholder="Login"
                            value={this.state.login}
                            onChange={this.handleLoginChange}
                            onKeyPress={this.handleEnterPress}
                        />
                    </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="password" placeholder="Heslo"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                onKeyPress={this.handleEnterPress}/>
                        </Form.Group>
                        <div className='admin-margin'>
                            <Button onClick={this.onClickLogin}>Přihlásit</Button>
                        </div>
                </Form>
                {error && <span className="error-validation">{error}</span>}
            </div>
        )
    }
}
