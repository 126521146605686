import {
    CHANGE_ORDER_STATE_FAILURE,
    CHANGE_ORDER_STATE_STARTED,
    CHANGE_ORDER_STATE_SUCCESS, CLEAN_ORDERS_REDUCER,
    CONFIRM_ORDER_STATE_STARTED,
    CONFIRM_ORDER_STATE_SUCCESS,
    GET_ALL_ORDERS_FAILURE,
    GET_ALL_ORDERS_STARTED,
    GET_ALL_ORDERS_SUCCESS, GET_ORDER_HISTORY_FAILURE, GET_ORDER_HISTORY_STARTED, GET_ORDER_HISTORY_SUCCESS,
    GET_ORDER_STATISTICS_FAILURE,
    GET_ORDER_STATISTICS_STARTED,
    GET_ORDER_STATISTICS_SUCCESS,
    GET_ORDERS_STATE_FAILURE,
    GET_ORDERS_STATE_STARTED,
    GET_ORDERS_STATE_SUCCESS, GET_ORDERS_STATUS_FAILURE, GET_ORDERS_STATUS_STARTED, GET_ORDERS_STATUS_SUCCESS,
} from './type';
import {
    changeOrderStateAPI,
    getAllOrdersAPI, getOrdersHistory,
    getOrderStatisticsAPI,
    getOrderStatusAPI
} from "../../../api/order/OrdersApi";
import {getOrdersStateAPI} from "../../../api/constant/ConstantApi";
import {showToast} from "../../common/toast/duck/action";

const gettingAllOrdersStarted = () => ({
    type: GET_ALL_ORDERS_STARTED,
});

const gettingOrderHistoryStarted = () => ({
    type: GET_ORDER_HISTORY_STARTED,
});

const cleanOrdersReducer = () => ({
   type: CLEAN_ORDERS_REDUCER,
});

const getOrderHistory = (page) => (dispatch) => {
    dispatch(gettingOrderHistoryStarted());
    getOrdersHistory(page, 30).then((data) => {
        let orders = makeProductCounts(data.content);
        dispatch({
            type: GET_ORDER_HISTORY_SUCCESS,
            payload: {
                orders: orders,
                page: data.number,
                last: data.last
            }
        });
    }).catch(error => {
        dispatch({
            type: GET_ORDER_HISTORY_FAILURE,
            error
        })
    });
};



const gettingOrderStateStarted = () => ({
    type: GET_ORDERS_STATE_STARTED,
});

const getAllOrders = () => (dispatch) => {
  dispatch(gettingAllOrdersStarted());
  getAllOrdersAPI().then((data) => {
      let orders = makeProductCounts(data);
          dispatch({
              type: GET_ALL_ORDERS_SUCCESS,
              payload: {
                  orders: orders,
              }
          });
      }).catch(error => {
      dispatch({
          type: GET_ALL_ORDERS_FAILURE,
          error
      })
  });
};

const getOrderStates = () => (dispatch) => {
  dispatch(gettingOrderStateStarted());
  getOrdersStateAPI().then((data) => {
          dispatch({
              type: GET_ORDERS_STATE_SUCCESS,
              payload: data
          })
      }).catch(error => {
          dispatch({
              type: GET_ORDERS_STATE_FAILURE,
              error
          })
  });

};

const changeOrderState = (orderId, orderState) => (dispatch) => {
  dispatch({
      type: CHANGE_ORDER_STATE_STARTED
  });
  dispatch({
      type: CHANGE_ORDER_STATE_SUCCESS,
      payload: {idOrder: orderId, stateOrder: orderState}
  })
};

const confirmOrderState = (orderId) => {
    return (dispatch, getState) => {
        dispatch({
            type: CONFIRM_ORDER_STATE_STARTED
        });
        const orderToChange = getState().ordersReducer.ordersData.orders[orderId];
        return changeOrderStateAPI(orderToChange.id, orderToChange.state)
            .then(() => {
                dispatch({
                    type: CONFIRM_ORDER_STATE_SUCCESS
                });
                dispatch(showToast('Stav změněn', 'Stav objednávky změněn informace zaslána zákazníkovi mailem', false));
            }).catch((error) => {
                dispatch({
                    type: CHANGE_ORDER_STATE_FAILURE,
                    error: error
                });
                dispatch(showToast('Chyba', 'Nepodařilo se změnit stav objednávky', true));
            })
    };
};

const getOrderStatistics = () => (dispatch) => {
  dispatch({
      type: GET_ORDER_STATISTICS_STARTED
  });
  getOrderStatisticsAPI()
      .then((data) => {
          dispatch({
              type: GET_ORDER_STATISTICS_SUCCESS,
              payload: data
          })
      }).catch((error) => {
          dispatch({
              type: GET_ORDER_STATISTICS_FAILURE,
              error: error
          })
      })
};

const getOrderStatus = () => (dispatch) => {
    dispatch({
        type: GET_ORDERS_STATUS_STARTED
    });
    getOrderStatusAPI()
        .then((data) => {
            dispatch({
                type: GET_ORDERS_STATUS_SUCCESS,
                payload: data
            })
        }).catch((error) => {
            dispatch({
                type: GET_ORDERS_STATUS_FAILURE,
                error: error
            })
        })
};

const makeProductCounts = (data) => {
    return data.map(order => {
        return {
            ...order,
            productMap: createProductMap(order.product)[0]
        }
    });
};

const createProductMap = (products) => {
    const productMap = mergeProductsAndCount(products);
    let resultProductList = [];
    resultProductList.push(Object.entries(productMap).map(product => {
        let found = products.find(prod => prod.id.toString() === product[0]);
        return {
            ...found,
            counts: product[1],
        };
    }));
    return resultProductList;
};

const mergeProductsAndCount = (products) => {
    return products.reduce((totals, product) => ({
        ...totals, [product.id]: (totals[product.id] || 0)+1
    }), {})
};

export {
    getAllOrders,
    getOrderStates,
    changeOrderState,
    confirmOrderState,
    getOrderStatistics,
    getOrderStatus,
    getOrderHistory,
    cleanOrdersReducer
};
