import {
    ADD_NEW_MEDAL_FAILURE,
    ADD_NEW_MEDAL_STARTED,
    ADD_NEW_MEDAL_SUCCESS,
    EDIT_MEDAL_FAILURE,
    EDIT_MEDAL_STARTED,
    EDIT_MEDAL_SUCCESS,
    GET_ALL_MEDALS_FAILURE,
    GET_ALL_MEDALS_STARTED,
    GET_ALL_MEDALS_SUCCESS, REMOVE_MEDAL_FAILURE, REMOVE_MEDAL_STARTED, REMOVE_MEDAL_SUCCESS,
} from "./type";

const initialState = {
    medals: null,
    isLoading: false,
};


const medalsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_MEDALS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_MEDALS_SUCCESS: {
            return {
                ...state,
                medals: action.payload.actualities,
                isLoading: false
            }
        }

        case GET_ALL_MEDALS_FAILURE: {
            return {
                ...state,
                error: action.error.message,
                isLoading: false,
            }
        }

        case ADD_NEW_MEDAL_STARTED:{
            return {
                ...state,
            }
        }

        case ADD_NEW_MEDAL_SUCCESS:{
            return {
                ...state,
                error: null
            }
        }

        case ADD_NEW_MEDAL_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        case EDIT_MEDAL_STARTED: {
            return {
                ...state,
            }
        }

        case EDIT_MEDAL_SUCCESS: {
            return {
                ...state,
                error: null
            }
        }

        case EDIT_MEDAL_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        case REMOVE_MEDAL_STARTED: {
            return {
                ...state,
            }
        }

        case REMOVE_MEDAL_SUCCESS: {
            return {
                ...state,
                error: null
            }
        }

        case REMOVE_MEDAL_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default medalsReducer;
