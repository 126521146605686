import React, {PureComponent} from "react";
import "./OrderStatsCardComponent.scss";

export default class OrderStatsCardComponent extends PureComponent {

    render() {
        const { stats } = this.props;
        return (
            <div>
                <div className="order-statistics-container">
                    <span>Nevyřízené objednávky: {stats.countOfWait} </span>
                    <span>Kompletní objednávky: {stats.countOfComplete} </span>
                </div>
            </div>
        );
    }

}
