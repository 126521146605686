import {
    GET_ALL_VOUCHERS_STARTED,
    GET_ALL_VOUCHERS_SUCCESS,
    GET_ALL_VOUCHERS_FAILURE,
    CHANGE_VOUCHER_ACTIVITY_STARTED,
    CHANGE_VOUCHER_ACTIVITY_SUCCESS,
    CHANGE_VOUCHER_ACTIVITY_FAILURE,
    DELETE_VOUCHER_STARTED,
    DELETE_VOUCHER_SUCCESS,
    DELETE_VOUCHER_FAILURE,
    ADD_NEW_VOUCHER_STARTED, ADD_NEW_VOUCHER_SUCCESS, ADD_NEW_VOUCHER_FAILURE
} from "./type";
import {
    addNewVoucherAPI,
    changeVoucherActivityApi,
    deleteVoucherAPI,
    getAllVouchersAPI
} from "../../../api/voucher/VoucherApi";
import {showToast} from "../../common/toast/duck/action";

const getAllVouchers = () => {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_VOUCHERS_STARTED,
        });
        return getAllVouchersAPI().then((data) => {
            dispatch({
                type: GET_ALL_VOUCHERS_SUCCESS,
                payload: data,
            });
        }).catch((error) => {
            dispatch({
                type: GET_ALL_VOUCHERS_FAILURE,
                error: error,
            });
        })
    }
};

const changeVoucherActivity = (voucherId) => {
    return (dispatch) => {
        dispatch({
            type: CHANGE_VOUCHER_ACTIVITY_STARTED,
        });

        return changeVoucherActivityApi(voucherId).then((data) => {
            dispatch({
                type: CHANGE_VOUCHER_ACTIVITY_SUCCESS,
            });
        }).catch((error) => {
            dispatch(showToast('Chyba', 'Nepdodařilo se změnit aktivitu voucheru', true));
            dispatch({
                type: CHANGE_VOUCHER_ACTIVITY_FAILURE,
                error,
            });
        })
    }
};

const deleteVoucher = (voucherId) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_VOUCHER_STARTED,
        });
        return deleteVoucherAPI(voucherId).then(() => {
            dispatch({
                type: DELETE_VOUCHER_SUCCESS,
            })
        }).catch((error) => {
            dispatch(showToast("Ajeeje", "Nepodařilo se smazat voucher", true));
            dispatch({
                type: DELETE_VOUCHER_FAILURE,
                error,
            });
        })
    }
};

const addNewVoucher = (voucher) => {
    return (dispatch) => {
        dispatch({
            type: ADD_NEW_VOUCHER_STARTED,
        });
        return addNewVoucherAPI(voucher).then(() => {
            dispatch({
                type: ADD_NEW_VOUCHER_SUCCESS,
            })
        }).catch((error) => {
            dispatch(showToast("Chyba", "Nepodařilo se vložit nový voucher", true));
            dispatch({
                type: ADD_NEW_VOUCHER_FAILURE,
                error,
            });
        })
    }
};

export {
    getAllVouchers,
    changeVoucherActivity,
    deleteVoucher,
    addNewVoucher
}
