import {connect} from "react-redux";
import ProductViewComponent from "./ProductViewComponent";
import ShoppingCartOperation from "../cart/duck/operation";

const mapStateToProps = (state) => {

    return {};
};

const mapDispatchToProps = (dispatch) => {

    const addToShoppingCart = (product, count, price) => {
        return dispatch(ShoppingCartOperation.addToShoppingCartAction(product, count, price));
    };

    const recountShoppingCart = () => {
      return dispatch(ShoppingCartOperation.recountSumShoppingCartAction());
    };

    return {
        addToShoppingCart,
        recountShoppingCart
    }
};

const ProductViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductViewComponent);


export default ProductViewContainer;
