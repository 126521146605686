import React, { Component } from 'react';
import "./VideoComponent.scss";
import video from './../../../../assets/video/video.mp4'
export default class VideoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            collapsedClass: '',
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = (event) => {
        if(this.state.collapse && window.pageYOffset === 0) {
            this.setState({collapse: false, collapsedClass: ''});
        } else {
            this.setState({collapse: true, collapsedClass: 'video-clipp'});
        }

    };

    render() {
        const { collapsedClass } = this.state;
        return(
            <div>
                <video width="314" height="314" autoPlay playsInline
                       preload='metadata' muted controls={false}
                       disablePictureInPicture loop className={collapsedClass}>
                    <source src={video} type="video/mp4"/>
                </video>
            </div>

        )
    }
}
