import {
    GET_ALL_CONSTANTS_FAILURE,
    GET_ALL_CONSTANTS_SUCCESS,
    GET_ALL_CONSTANTS_STARTED,
} from "./type";

const initialState = {
    color: null,
    kind: null,
    category: null,
    isLoading: false,
};

const getSpecificConstant = (constants, spec) => {
    let specific = [];
  constants.forEach(constant => {
      if(constant.category === spec) {
          specific.push(constant);
      }
  });
    return specific;
};

const constantsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_CONSTANTS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_CONSTANTS_SUCCESS: {
            return {
                ...state,
                color: getSpecificConstant(action.payload, 'color'),
                kind: getSpecificConstant(action.payload, 'kind'),
                category: getSpecificConstant(action.payload, 'category'),
                voucher: getSpecificConstant(action.payload, 'voucher'),
                isLoading: false,
            }
        }

        case GET_ALL_CONSTANTS_FAILURE: {
            return {
                ...state,
                error: action.error.message,
                isLoading: false,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default constantsReducer;
