import {environment} from "../configApi";

export const getAllProductsAPI = () => {
    return fetch(`${environment}/api/product`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const getAllActiveProductsAPI = () => {
    return fetch(`${environment}/api/active/product`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const updateProductAPI = (productId, product) => {
  return fetch(`${environment}/api/product/${productId}`, {
      method: 'put',
      headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token'),
          'Content-Type': 'application/json'
      }, body: JSON.stringify(product)
  })
      .then(res => {
          if (res.status >= 400) {
              return res.json().then(data => {
                  return Promise.reject(new Error(data.message));
              });
          }
      }).catch(error => {
          return Promise.reject(error)
      });
};


export const addNewProductAPI = (product) => {
    return fetch(`${environment}/api/product/`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }, body: JSON.stringify(product)
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const removeProductAPI = (productId) => {
    return fetch(`${environment}/api/product/${productId}`, {
        method: 'delete',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const getTopThreeProductsAPI = () => {
    return fetch(`${environment}/api/product/best`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            console.log(error);
            return Promise.reject(error)
        });
};

export const getRandomProductsAPI = () => {
    return fetch(`${environment}/api/product/random`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            console.log(error);
            return Promise.reject(error)
        });
};

export const getProductByIdAPI = (productId) => {
    return fetch(`${environment}/api/product/${productId}`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};
