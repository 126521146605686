export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART';
export const INCREASE_COUNT_BOTTLE = 'INCREASE_COUNT_BOTTLE';
export const DECREASE_COUNT_BOTTLE = 'DECREASE_COUNT_BOTTLE';
export const RECOUNT_SUM_OF_SHOPPING_CART = 'RECOUNT_SUM_OF_SHOPPING_CART';
export const CHECK_VOUCHER_CODE_STARTED = 'CHECK_VOUCHER_CODE_STARTED';
export const CHECK_VOUCHER_CODE_SUCCESS = 'CHECK_VOUCHER_CODE_SUCCESS';
export const CHECK_VOUCHER_CODE_FAILURE = 'CHECK_VOUCHER_CODE_FAILURE';
export const REMOVE_VOUCHER_CODE = 'REMOVE_VOUCHER_CODE';
export const CLEAN_SHOPPING_CART = 'CLEAN_SHOPPING_CART';
export const CHANGE_BOTTLE_COUNT = 'CHANGE_BOTTLE_COUNT';
