import React, { PureComponent } from 'react';
import './GertnerInputComponent.scss';


export default class GertnerInputComponent extends PureComponent {

    render() {
        const {
            labelText,
            type,
            placeHolder,
            isImportant,
            isError,
            name,
            handleChange
        } = this.props;

        let inputClassName = 'gertner-input-container__input';
        if(isError) {
            inputClassName = 'gertner-input-container__input gertner-input-container__input--nonvalid';
        }
        return (
            <div className='gertner-input-container'>
                <label className='gertner-input-container__label'>{labelText}</label>
                <input className={inputClassName} onChange={handleChange} name={name && name}
                       type={type} placeholder={placeHolder} autoComplete="on"/>
            </div>
        )
    }

}

GertnerInputComponent.defaultProps = {
  labelText: 'default',
  placeHolder: 'somePlaceHolder',
  type: 'text',
  isImportant: false,
};
