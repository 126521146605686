import { connect } from 'react-redux';
import OrdersOperation from './duck/operation';
import OrdersComponent from "./OrdersComponent";

const mapStateToProps = (state) => {
    const { isLoading, ordersData, ordersState, orderStatus } = state.ordersReducer;
    const { orders } = ordersData;
    const { orderState } = ordersState;
    return { orders, orderState, isLoading, orderStatus };
};

const mapDispatchToProps = (dispatch) => {
    const getAllOrders = () => {
        dispatch(OrdersOperation.getAllOrdersAction());
    };

    const getOrderStates = () => {
        dispatch(OrdersOperation.getOrdersStateAction());
    };

    const changeOrderState = (orderId, orderState) => {
        dispatch(OrdersOperation.changeOrderStateAction(orderId, orderState));
    };

    const confirmOrderState = (orderId) => {
       return dispatch(OrdersOperation.confirmOrderStateAction(orderId));
    };

    const getOrderStatus = () => {
        dispatch(OrdersOperation.getOrderStatusAction());
    };

    return {
        getAllOrders,
        getOrderStates,
        changeOrderState,
        confirmOrderState,
        getOrderStatus
    };
};

const OrdersContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrdersComponent);


export default OrdersContainer;
