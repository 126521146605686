import { connect } from 'react-redux';
import EventOperation from '../duck/operation';
import EventDashboardContent from "./EventDashboardContent";

const mapStateToProps = (state) => {
    const { isLoading, events } = state.eventsDashboard;
    return { events, isLoading };
};

const mapDispatchToProps = (dispatch) => {
    const getAllEvents = () => {
        return dispatch(EventOperation.getAllEventsAction());
    };

    return {
        getAllEvents,
    };
};

const EventDashboardContentContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventDashboardContent);


export default EventDashboardContentContainer;
