import { connect } from 'react-redux';
import OrdersOperation from '../duck/operation';
import OrdersComponent from "./OrdersHistoryComponent";

const mapStateToProps = (state) => {
    const { isLoading, ordersData, ordersState, orderStatus, page, last } = state.ordersReducer;
    const { orders } = ordersData;
    const { orderState } = ordersState;
    return { orders, orderState, isLoading, orderStatus, page, last };
};

const mapDispatchToProps = (dispatch) => {

    const getOrdersHistory = (page) => {
        dispatch(OrdersOperation.getOrdersHistoryAction(page));
    };

    const getAllOrders = () => {
        dispatch(OrdersOperation.getAllOrdersAction());
    };

    const getOrderStates = () => {
        dispatch(OrdersOperation.getOrdersStateAction());
    };

    const changeOrderState = (orderId, orderState) => {
        dispatch(OrdersOperation.changeOrderStateAction(orderId, orderState));
    };

    const confirmOrderState = (orderId) => {
       return dispatch(OrdersOperation.confirmOrderStateAction(orderId));
    };

    const getOrderStatus = () => {
        dispatch(OrdersOperation.getOrderStatusAction());
    };

    const cleanOrdersReducer = () => {
        dispatch(OrdersOperation.cleanOrdersReducerAction());
    }

    return {
        getAllOrders,
        getOrderStates,
        changeOrderState,
        confirmOrderState,
        getOrderStatus,
        getOrdersHistory,
        cleanOrdersReducer
    };
};

const OrdersHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrdersComponent);


export default OrdersHistoryContainer;
