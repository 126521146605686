import { connect } from 'react-redux';
import OrdersOperation from './../duck/operation';
import OrderStatisticsComponent from "./OrderStatisticsComponent";

const mapStateToProps = (state) => {
    const { isLoading } = state.ordersReducer;
    const { statistics } = state.ordersReducer.ordersData;
    return { statistics, isLoading };
};

const mapDispatchToProps = (dispatch) => {

    const getOrderStatistics = () => {
        dispatch(OrdersOperation.getOrderStatisticsAction());
    };

    return {
        getOrderStatistics
    };
};

const OrdersContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderStatisticsComponent);


export default OrdersContainer;
