import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import './MedalProductComponent.scss';

export default class MedalProductComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedMedals:[]
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.product && nextProps.isShow) {
            this.createInitialMedalArray(nextProps.product.medal).then((result) => {
                this.setState({
                    selectedMedals: result,
                });
            });
        }
    }

    async createInitialMedalArray(medals) {
        let selectedMedals = [];
        await medals.forEach(medal => {
            selectedMedals.push({
                id: medal.id,
            });
        });
        return selectedMedals;
    }



    isChecked(medal, medals) {
        return medals.find(medalProduct => {
            return medalProduct.id === medal.id
        });
    }

    renderMedalCheckbox() {
        const { medals } = this.props;
        return (
            medals.map(medal => {
                return(
                    <div className='product-medal-modal__row'>
                        <div className='product-medal-modal__row--label'>
                            <label>{medal.type}, {medal.description}</label>
                        </div>
                        <div className='product-medal-modal__row--checkbox'>
                            <input type='checkbox' name={medal.type}
                                   checked={this.isChecked(medal, this.state.selectedMedals)}
                                   onChange={(event) => this.handleCheck(medal.id, event)}
                            />
                        </div>
                    </div>
                )
            })
        )
    }

    handleCheck = (medalId, event) => {
        if(event.target.checked) { //Checking
            this.setState(state => {
                const selectedMedals = state.selectedMedals.concat({
                    id: medalId,
                });

                return {
                    selectedMedals
                }
            });
        } else { //Unchecking
            this.setState(state => {
                const selectedMedals = state.selectedMedals.filter(selectedMedal => selectedMedal.id !== medalId);

                return {
                    selectedMedals
                }
            });
        }
    };


    createEditObject() {
        const { selectedMedals } = this.state;
        const { product } = this.props;
        return {
            name: product.name,
            available: product.available,
            medal: selectedMedals,
        }
    }

    handleSave = () => {
        const { handleEdit, product, getProducts } = this.props;
        handleEdit(product.id, this.createEditObject()).then(() => {
            getProducts();
            this.handleCloseModal();
        });
    };

    async resetSelectedMedals() {
        await this.setState({
            selectedMedals: [],
        });
    }

    handleCloseModal = () => {
        const { handleClose } = this.props;
        this.resetSelectedMedals().then(() => {
            handleClose();
        });
    };

    render() {
        const { isShow, product, medals } = this.props;
        return (
            <div className='product-medal-modal'>
                <Modal show={isShow} onHide={this.handleCloseModal} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Medaile
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(product && medals && medals.length > 0) &&
                        <div>{this.renderMedalCheckbox()}</div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleSave}>Uložit</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}
