import React, {Component} from "react";
import './EshopHeaderComponent.scss';
import {Link} from "react-router-dom";
import GertnerButtonComponent from "../../common/GertnerButtonComponent";
import {withBreakpoints} from "react-breakpoints";
import {scroller} from "react-scroll";
import {EshopLinkComponent} from "../link/EshopLinkComponent";
import eshopRuka from './../../../assets/images/eshop-gerner-ruka.png';
import {Image} from "react-bootstrap";

class EshopHeaderComponent extends Component {

    handleFilterProduct = (filter) => {
        const { applyFilter } = this.props;
        applyFilter(filter);
        this.scrollToProductList();
    };

    scrollToProductList = () => {
        const elementToScroll = 'product-list-element';
        scroller.scrollTo(elementToScroll, {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    render() {

        const { breakpoints, currentBreakpoint} = this.props;

        return (
            <div className='eshop-header-container'>
                    <EshopLinkComponent />
                    <div className='eshop-header-container__bottom'>
                        {
                            breakpoints[currentBreakpoint] >= breakpoints.desktop &&
                            <div>
                                <Image className='eshop-header-container__hand' src={eshopRuka} />
                            </div>
                        }
                        <div className='eshop-header-container__description'>
                            <span className='eshop-header-container__title'>Život je jízda plná chutí</span>
                            <div className='eshop-header-container__text'>
                                Myslíme na různé chuti lidí, kteří se s vámi u vína sejdou – vybírat můžete z
                                několika odrůd <span onClick={() => this.handleFilterProduct('white')}>bílých</span>,
                                <span onClick={() => this.handleFilterProduct('red')}> červených </span>a
                                <span onClick={() => this.handleFilterProduct('rose')}> rosé</span>,
                                <span onClick={() => this.handleFilterProduct('suché')}> suché </span>,
                                <span onClick={() => this.handleFilterProduct('polosuché')}> polosuché</span>,
                                <span onClick={() => this.handleFilterProduct('polosladké')}> polosladké </span> i
                                <span onClick={() => this.handleFilterProduct('sladké')}> sladké</span>- zkrátka podle toho,
                                co nejvíc jede právě vám.
                            </div>
                            <div className='eshop-header-container__btn'>
                                <Link to={'/'}>
                                    <GertnerButtonComponent
                                        text='O vinařství'
                                    />
                                </Link>
                            </div>
                        </div>
                        {
                            breakpoints[currentBreakpoint] < breakpoints.desktop &&
                                <div>
                                    <Image className='eshop-header-container__hand--mobile'
                                         src={eshopRuka} />
                                </div>
                        }
                    </div>
            </div>
        )
    }

}

export default withBreakpoints(EshopHeaderComponent);
