import React, {PureComponent} from "react";
import './RychardComponent.scss';
import {withBreakpoints} from "react-breakpoints";

const title = 'Už se s vámi vezl náš Rychard?';
const description = 'Už se s vámi vezl náš Rychard?\n' +
    'Náš vinařský rukopis nejvýrazněji poznáte v Cuvée Rychard, které v sobě spojuje Ryzlink rýnský,\n' +
    ' prokvašený do sucha a aromatické, sladší Chardonnay.\n' +
    ' Jaké je tajemství jeho vzniku? Na počátku to byl náš začátečnický omyl, který nám pomohl dosáhnout skvělé chuti.\n' +
    ' Technologicky jsme výrobu zdokonalili a vznikl tak náš charakteristický rukopis Rycharda, který je nezaměnitelný.';

class RychardComponent extends PureComponent {

    renderDesktop() {
        return (
            <div  className='rychard-container'>
                <div className='rychard-container__text'>
                    <span className='rychard-container__title'>
                        {title}
                    </span>
                    <div>
                       {description}
                    </div>
                </div>
            </div>
        );
    }

    renderMobile(){
        return (
            <div className='rychard-mobile-container'>
            <div className='rychard-mobile-container__bcg'></div>
                <div className='rychard-mobile-container__text'>
                    <span className='rychard-mobile-container__title'>
                        {title}
                    </span>
                    <div className='rychard-mobile-container__description'>
                        {description}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { breakpoints, currentBreakpoint } = this.props;

        if(breakpoints[currentBreakpoint] < breakpoints.desktop) {
            return (
                <>
                    {this.renderMobile()}
                </>
            )
        }
        return(
            <>
                {this.renderDesktop()}
            </>
        )
    }
}

export default withBreakpoints(RychardComponent);
