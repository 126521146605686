import {connect} from "react-redux";
import CartBannerComponent from "./CartBannerComponent";

const mapStateToProps = (state) => {

    const { shoppingCart } = state.shoppingCart;

    return { shoppingCart };
};

const mapDispatchToProps = (dispatch) => {

    return {

    }
};

const CartBannerContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CartBannerComponent);


export default CartBannerContainer;
