import {connect} from "react-redux";
import OrdersOperation from "../duck/operation";
import PaymentMethodsComponent from "./PaymentMethodsComponent";

const mapStateToProps = (state) => {

    const { paymentMethods } = state.orderConfirmation;
    const { paymentMethod } = state.orderConfirmation.order;
    return { paymentMethods, paymentMethod };
};

const mapDispatchToProps = (dispatch) => {

    const getPaymentMethods = () => {
        return dispatch(OrdersOperation.getPaymentMethodsAction());
    };

    const selectPaymentMethod = (paymentMethod) => {
        dispatch(OrdersOperation.selectPaymentMethodAction(paymentMethod));
    };

    return {
        getPaymentMethods,
        selectPaymentMethod
    }
};

const PaymentMethodsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentMethodsComponent);


export default PaymentMethodsContainer;
