import React, { Component } from "react";
import LoadingComponent from "../common/LoadingComponent";
import ProductPopUpComponent from "./ProductPopUpComponent";
import './ProductComponent.scss';
import {withBreakpoints} from "react-breakpoints";
import {AiFillDelete, FaEdit, FaMedal} from "react-icons/all";
import AddButtonComponent from "../common/buttons/AddButtonComponent";
import './../GlobalAdmin.scss';
import MedalProductComponent from "./MedalProductComponent";
import {Badge, Table} from "react-bootstrap";

class ProductComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            showPopUp: false,
            showMedal: false,
            productToEdit: null,
            isAddingClick: false,
            medalEdit: null,
        }
    }

    componentDidMount() {
        const { getAllProducts, getAllMedals } = this.props;
        getAllProducts();
        getAllMedals();
    }

    renderAvailability = (idx) => {
        const { products } = this.props;
        if(products) {
            return (
                <>
                    {
                        products[idx].available ?
                            <Badge variant="success">Dostupný</Badge> :
                            <Badge variant="danger">Nedostupný</Badge>
                    }
                </>
            )
        }
    };

    handleEdit = (idx) => {
        const { products } = this.props;
        this.setState({
            showPopUp: true,
            productToEdit: products[idx],
            isAddingClick: false,
        });
    };

    handleRemove = (idx) => {
      const { products, removeProduct, getAllProducts } = this.props;
      removeProduct(products[idx].id).then(() => {
          getAllProducts();
      });
    };

    handleCloseEdit = () => {
      this.setState({
          showPopUp: false,
          productToEdit: null,
          isAddingClick: false,
      });
    };

    handleClickNewProduct = () => {
        this.setState({
            showPopUp: true,
            isAddingClick: true,
        })
    };

    handleSearchProduct = (event) => {
        let filter = event.target.value.toLowerCase().normalize('NFD');
        const table = document.getElementById('product-table');
        const tr = table.getElementsByTagName('tr');
        for (let i = 0; i < tr.length; i++) {
            let td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                let txtValue = td.textContent || td.innerText;
                if (txtValue.toLowerCase().normalize('NFD').indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    };

    handleClickMedal = (id) => {
        this.setState({
            showMedal: true,
            medalEdit: this.props.products[id],
        })
    };

    handleCloseMedal = () => {
        this.setState({
            showMedal: false,
        });
    };

    renderEditBtn = (id) => {
        const { breakpoints, currentBreakpoint } = this.props;
        if(breakpoints[currentBreakpoint] > breakpoints.tablet){
            return (
                <FaEdit className="product-admin-container__edit-icon" size={'35px'}
                        color={'#D4AF37'} id={id} onClick={() => this.handleEdit(id)} />
            )
        } else {
            return (
                <FaEdit className="product-admin-container__edit-icon" size={'30px'}
                        color={'#D4AF37'} id={id} onClick={() => this.handleEdit(id)} />
            )
        }
    };

    renderRemoveBtn = (id) => {
        const { breakpoints, currentBreakpoint } = this.props;
        if(breakpoints[currentBreakpoint] > breakpoints.tablet){
            return (
                <AiFillDelete className="product-admin-container__edit-icon"
                              color={'#d42116'}
                              size={'35px'}
                              id={id} onClick={() => this.handleRemove(id)} />
            )
        } else {
            return (
                <AiFillDelete className="product-admin-container__edit-icon"
                          color={'#d42116'}
                          size={'30px'}
                          id={id} onClick={() => this.handleRemove(id)} />
            )
        }
    };

    renderMedalBtn = (id) => {
        const { breakpoints, currentBreakpoint } = this.props;
        if(breakpoints[currentBreakpoint] > breakpoints.tablet){
            return (
                <FaMedal className="product-admin-container__edit-icon"
                              color={'#b08d57'}
                              size={'35px'}
                              id={id} onClick={() => this.handleClickMedal(id)} />
            )
        } else {
            return (
                <FaMedal className="product-admin-container__edit-icon"
                              color={'#b08d57'}
                              size={'30px'}
                              id={id} onClick={() => this.handleClickMedal(id)} />
            )
        }
    };

    render() {
        const {
            products,
            updateProduct,
            getAllProducts,
            addNewProduct,
            isLoading,
            currentBreakpoint,
            breakpoints,
            color,
            kind,
            category,
        } = this.props;
        const {
            showPopUp,
            productToEdit,
            isAddingClick,
            showMedal,
            medalEdit,
        } = this.state;

        if(!products || isLoading) {
            return (
                <LoadingComponent />
            )
        }
        let mobileTdClassName = '';
        if(breakpoints[currentBreakpoint] < breakpoints.tablet){
             mobileTdClassName = 'product-admin-container__mobile-td';
        }

        return(
            <div className="product-admin-container">
                <span className='title-page'>Administrace produktu</span>
                <span className='product-admin-container__new-btn' onClick={this.handleClickNewProduct}>
                    <AddButtonComponent />
                    Nový
                </span>
                <input type="text" className="product-admin-container__search-bar" placeholder="Hledej" onChange={this.handleSearchProduct}/>
                <Table striped hover id="product-table" className="product-admin-container__table">
                    <thead>
                        <tr>
                            <th>Nazev</th>
                            <th>Rocnik</th>
                            <th className={mobileTdClassName}>Cena</th>
                            <th className={mobileTdClassName}>dostupnost</th>
                        </tr>
                    </thead>
                    <tbody>
                    {products.map((product, idx) => {
                      return (
                          <tr>
                              <td>{product.name}</td>
                              <td>{product.vintage}</td>
                              <td className={mobileTdClassName}>{product.price}</td>
                              <td className={mobileTdClassName}>{this.renderAvailability(idx)}</td>
                              <td>{this.renderEditBtn(idx)}</td>
                              <td>{this.renderMedalBtn(idx)}</td>
                              <td>{this.renderRemoveBtn(idx)}</td>
                          </tr>
                      )
                    })}
                    </tbody>
                </Table>
                {
                    (productToEdit || isAddingClick) &&
                    <ProductPopUpComponent
                        product={productToEdit}
                        isShow={showPopUp}
                        handleClose={this.handleCloseEdit}
                        updateProductMethod={updateProduct}
                        addNewProductMethod={addNewProduct}
                        getProducts={getAllProducts}
                        isAdding={isAddingClick}
                        colors={color}
                        kinds={kind}
                        categories={category}
                    />
                }
                {
                    <MedalProductComponent isShow={showMedal}
                                           handleClose={this.handleCloseMedal}
                                           product={medalEdit}
                                           medals={this.props.medals}
                                           handleEdit={updateProduct}
                                           getProducts={getAllProducts}
                    />
                }
            </div>
        )
    }
}

export default withBreakpoints(ProductComponent);
