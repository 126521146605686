import { connect } from 'react-redux';
import ToastOperation from './duck/operation';
import ToastComponent from "./ToastComponent";

const mapStateToProps = (state) => {
    const { showToast, toastTitle, toastMessage, isError } = state.toastReducer;
    return { showToast, toastMessage, toastTitle, isError };
};

const mapDispatchToProps = (dispatch) => {

    const hideToast = () => {
      dispatch(ToastOperation.hideToastAction());
    };

    return {
        hideToast
    };
};

const ToastContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ToastComponent);


export default ToastContainer;
