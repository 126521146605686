import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Badge, Button} from "react-bootstrap";
import "./NavBarComponent.scss";

export default class NavBarComponent extends Component {

    onLogoutClick = () => {
        const {logoutUser} = this.props;
        logoutUser();
    };

    render() {
        const { orderStatus } = this.props;
        return (
            <div className="admin-navbar">
            <nav>
                <div className="admin-navbar__list">
                    <ul className="admin-navbar__navigation">
                        <li><Link className="admin-navbar__link" to={'/admin/orders'}>Objednávky
                            {
                                (orderStatus && orderStatus.countOfWait > 0) &&
                                <span className="admin-navbar__badge">
                                    <Badge pill variant="info" className="admin-navbar__badge">
                                        {orderStatus.countOfWait}
                                    </Badge>
                                </span>
                            }
                        </Link></li>
                        <li><Link className="admin-navbar__link" to={'/admin/history'}>Historie objednávek</Link></li>
                        <li><Link className="admin-navbar__link" to={'/admin/products'}>Produkty</Link></li>
                        <li><Link className="admin-navbar__link" to={'/admin/medals'}>Medaile</Link></li>
                        <li><Link className="admin-navbar__link" to={'/admin/events'}>Události</Link></li>
                        <li><Link className="admin-navbar__link" to={'/admin/vouchers'}>Vouchery</Link></li>
                        <li><Link className="admin-navbar__link" to={'/admin/stats'}>Statistiky</Link></li>
                        <Button variant="secondary" className="admin-navbar__logout-btn" onClick={this.onLogoutClick}>
                            Odhlasit
                        </Button>
                    </ul>
                </div>
            </nav>
            </div>
        );
    };

}
