import React, {Component} from "react";
import {EshopLinkComponent} from "../../link/EshopLinkComponent";
import './ProductDetailComponent.scss';
import ProductFullDetailContainer from "./detail/ProductFullDetailContainer";
import ShoppingCartContainer from "../../cart/ShoppingCartContainer";
import CartBannerContainer from "../../cart/cart-banner/CartBannerContainer";
import { Element } from 'react-scroll';
import TopThreeProductsContainer from "../topthree/TopThreeProductContainer";
import EshopMenuComponent from "../../menu/EshopMenuComponent";
import {IoIosArrowBack} from "react-icons/all";
import {withRouter} from "react-router";

class ProductDetailComponent extends Component {

    handleBackToEshop = () => {
        const { history } = this.props;
        history.push('/eshop');
        window.location.reload();
        window.scrollTo(0,0,);
    };

    render() {
        const { productId } = this.props.match.params;
        return(
            <div className='product-detail-container'>
                <EshopLinkComponent />
                <CartBannerContainer/>
                <div className='product-detail-container__back-to-list' onClick={this.handleBackToEshop}>
                    <IoIosArrowBack /> Zpět k nabídce vín
                </div>
                <ProductFullDetailContainer productId={productId} />
                <div>
                    <div className='product-detail-container__similar-products'>
                        Mohlo by se vám taky líbit
                    </div>
                    <div className='product-detail-container__top-three'>
                        <TopThreeProductsContainer/>
                    </div>
                </div>
                <Element name='shopping-car-product-detail'>
                    <div className='product-detail-container__cart'>
                        <ShoppingCartContainer/>
                    </div>
                </Element>
                <EshopMenuComponent />
            </div>
        )
    }

}

export default withRouter(ProductDetailComponent);
