import {
    GET_TOP_THREE_PRODUCTS_FAILURE,
    GET_TOP_THREE_PRODUCTS_SUCCESS,
    GET_TOP_THREE_PRODUCTS_STARTED,
    GET_ALL_PRODUCTS_FAILURE,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_STARTED,
    APPLY_FILTER,
    REMOVE_FILTER,
    GET_PRODUCT_BY_ID_STARTED,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_FAILURE,
} from "./type";

const initialState = {
    topThreeProducts: [],
    allProducts: [],
    filters: [],
    isLoading: false,
    selectedProduct: null,
};


const productsDashboardReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TOP_THREE_PRODUCTS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_TOP_THREE_PRODUCTS_SUCCESS: {
            return {
                ...state,
                topThreeProducts: action.payload.products,
                isLoading: false,
                error: null
            }
        }

        case GET_TOP_THREE_PRODUCTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error.message,
            }
        }

        case GET_ALL_PRODUCTS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_PRODUCTS_SUCCESS: {
            return {
                ...state,
                allProducts: action.payload.products,
                isLoading: false,
                error: null
            }
        }

        case GET_ALL_PRODUCTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error.message,
            }
        }

        case GET_PRODUCT_BY_ID_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_PRODUCT_BY_ID_SUCCESS: {
            return {
                ...state,
                selectedProduct: action.payload,
                isLoading: false,
                error: null
            }
        }

        case GET_PRODUCT_BY_ID_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error.message,
            }
        }

        case APPLY_FILTER: {
            return {
                ...state,
                filters: [
                    ...state.filters,
                    action.payload
                ]
            }
        }

        case REMOVE_FILTER: {
            const updatedFilter = state.filters.filter(item => item !== action.payload);
            return {
                ...state,
                filters: updatedFilter,
            }
        }


        default: {
            return {
                ...state,
            };
        }
    }
};

export default productsDashboardReducer;
