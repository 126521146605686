import React, {PureComponent} from "react";
import ContactCardComponent from "./ContactCardComponent";
import './ContactComponent.scss';
import facebookIco from './../../../assets/images/icons/ikona-facebook.svg';
import instagramIco from './../../../assets/images/icons/ikona-instagram.svg';
import lenkaImg from './../../../assets/images/lenka-contact.png';
import marekImg from './../../../assets/images/marek-contact.png';
import {Image} from "react-bootstrap";
import SubsidyOperation from "../../subsidy/duck/operation";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class ContactComponent extends PureComponent {

    renderContactPanel() {
        return (
            <div className='contact-panel'>
                <div className='contact-panel__icons'>
                    <a href='https://www.facebook.com/vinarstvigertner/'>
                        <Image src={facebookIco} />
                    </a>
                    <a href='https://www.instagram.com/vinarstvigertner/'>
                        <Image src={instagramIco} />
                    </a>
                </div>
                <div className='contact-panel__subsidy'>
                    <li>
                        <span onClick={this.props.showSubsidy}>Projekt dotace</span>
                    </li>
                </div>
                <div className='contact-panel__contacts'>
                    <a href='mailto:info@vinarstvigertner.cz'>info@vinarstvigertner.cz</a>
                    <a href='tel:+420723210156'>+420 723 210 156</a>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='contact-container'>
                <span className='contact-container__title'>Kdo řídí ?</span>
                  <div className='contact-component'>
                      <div className='contact-component__marek'>
                          <ContactCardComponent
                              image={marekImg}
                              text='Majitel a zakladatel vinařství si přidal k vinařské práci vše co má rád.
                              Adrenalin, zábavu, svobodu a volnost. Zodpovídá za produkci, technologii a výrobu.'
                              name='Ing. Marek Gertner'
                              phone='+420 721 873 340'
                          />
                      </div>
                      <div className='contact-component__lenka'>
                          <ContactCardComponent
                              image={lenkaImg}
                              text='Markova manželka Lenka má na starosti obchod a marketing.
                              Často ji s Markem potkáte na různých akcích, festivalech nebo na degustaci.'
                              name='Lenka Gertner'
                          />
                      </div>
                  </div>
                {this.renderContactPanel()}
            </div>
        );
    }

}


const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    const showSubsidy = () => {
        dispatch(SubsidyOperation.showSubsidyAction());
    };
    return {
        showSubsidy,
    }
};

ContactComponent = connect(mapStateToProps, mapDispatchToProps)(ContactComponent);

export default withRouter(ContactComponent);
