import {
    SHOW_SUBSIDY,
    HIDE_SUBSIDY
} from "./type";

const initialState = {
    isSubsidyShow: false,
};


const subsidy = (state = initialState, action) => {
    switch (action.type) {

        case SHOW_SUBSIDY: {
            return {
                ...state,
                isSubsidyShow: true,
            }
        }

        case HIDE_SUBSIDY: {
            return {
                ...state,
                isSubsidyShow: false,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default subsidy;
