import {
    SHOW_TOAST_ACTION,
    HIDE_TOAST_ACTION
} from "./type";

const initialState = {
    showToast: false,
    toastTitle: '',
    toastMessage: '',
    isError: false,
};

const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_TOAST_ACTION: {
            return {
                showToast: true,
                toastMessage: action.payload.message,
                toastTitle: action.payload.title,
                isError: action.payload.isError,
            }
        }

        case HIDE_TOAST_ACTION: {
            return {
                showToast: false,
                isError: false,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default toastReducer;
