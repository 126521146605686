import React, {Component} from "react";
import LoadingComponent from "../common/LoadingComponent";
import MedalPopUpComponent from "./MedalPopUpComponent";
import './MedalAdminComponent.scss';
import AddButtonComponent from "../common/buttons/AddButtonComponent";
import './../GlobalAdmin.scss';
import {AiFillDelete, FaEdit} from "react-icons/all";
import {withBreakpoints} from "react-breakpoints";
import {Table} from "react-bootstrap";

class MedalAdminComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
            medalToEdit: null,
            isAddingClick: false,
        }
    }

    componentDidMount() {
        const { getAllMedals } = this.props;
        getAllMedals();
    }

    handleCloseEdit = () => {
      this.setState({
          showPopUp: false,
          medalToEdit: null,
          isAddingClick: false,
      });
    };

    handleEdit = (idx) => {
        const { medals } = this.props;
        this.setState({
            showPopUp: true,
            medalToEdit: medals[idx],
            isAddingClick: false,
        });
    };

    handleClickNew = () => {
      this.setState({
          showPopUp: true,
          isAddingClick: true,
      });
    };

    formatLongText = (text) => {
      if(text.length > 30) {
          return `${text.substring(0, 30)}...`;
      }
      return text;
    };

    handleRemove = (id) => {
        const { removeMedal, getAllMedals } = this.props;
        removeMedal(id).then(() => {
            getAllMedals();
        })
    };

    renderRemoveBtn = (id) => {
        const { breakpoints, currentBreakpoint } = this.props;
        if(breakpoints[currentBreakpoint] > breakpoints.tablet){
            return (
                <AiFillDelete className="product-admin-container__edit-icon"
                              color={'#d42116'}
                              size={'35px'}
                              id={id} onClick={() => this.handleRemove(id)} />
            )
        } else {
            return (
                <AiFillDelete className="product-admin-container__edit-icon"
                              color={'#d42116'}
                              size={'30px'}
                              id={id} onClick={() => this.handleRemove(id)} />
            )
        }
    };

    render() {
        const {
            medals,
            isLoading,
            editMedal,
            addNewMedal,
            getAllMedals,
            breakpoints,
            currentBreakpoint
        } = this.props;

        const {
            medalToEdit,
            isAddingClick,
            showPopUp
        } = this.state;

        let mobileTdClassName = '';
        if(breakpoints[currentBreakpoint] < breakpoints.tablet) {
            mobileTdClassName = 'medal-admin-container__mobile-td';
        }

        if(!medals || isLoading) {
            return (
                <LoadingComponent />
            )
        }
        return(
            <div className='medal-admin-container'>
                <span className='title-page'>Medaile</span>
                <span className='product-admin-container__new-btn' onClick={this.handleClickNew}>
                    <AddButtonComponent />
                    Nová
                </span>
                <Table striped hover className="medal-admin-container__table">
                    <thead>
                        <tr>
                            <th>Typ medaile</th>
                            <th className={mobileTdClassName}>Popis</th>
                        </tr>
                    </thead>
                    <tbody>
                    {medals.map((medal, idx) => {
                        return (
                            <tr>
                                <td>{medal.type}</td>
                                <td className={mobileTdClassName}>{this.formatLongText(medal.description)}</td>
                                <td><FaEdit className="medal-admin-container__edit-icon" size={'30px'}
                                        color={'#D4AF37'} id={idx} onClick={() => this.handleEdit(idx)} />
                                </td>
                                <td>
                                    {this.renderRemoveBtn(medal.id)}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                {
                    (medalToEdit || isAddingClick) &&
                    <MedalPopUpComponent
                        medal={medalToEdit}
                        isShow={showPopUp}
                        handleClose={this.handleCloseEdit}
                        editMedalMethod={editMedal}
                        addNewMedalMethod={addNewMedal}
                        getAllMedalsMethod={getAllMedals}
                        isAdding={isAddingClick}
                    />
                }
            </div>
        )
    }
}

export default withBreakpoints(MedalAdminComponent);
