import React, {Component} from "react";
import './OrderFormComponent.scss';
import GertnerInputComponent from "../../../common/GertnerInputComponent";
import {
    isAddressValid,
    isCityValid, isCompanyNameValid,
    isEmailValid, isIcoValid,
    isNameValid, isPscValid,
    isSurnameValid,
    isTelephoneValid, validateMoreInfo
} from "./ValidatorUtil";
import {derivedBottleName, getCountBottleFromShoppingCart} from "../../../../admin/common/utils/BasicUtils";
import GertnerButtonComponent from "../../../common/GertnerButtonComponent";
import {withRouter} from "react-router";
import LoadingDashboardComponent from "../../../common/LoadingDashboardComponent";
import GertnerCheckboxComponent from "../../../common/GertnerCheckboxComponent";
import {countNoVatSum} from "../../utils/EshopUtils";

class OrderFormComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            surname: null,
            address: null,
            psc: null,
            email: null,
            telephone: null,
            city: null,
            ico: null,
            companyName: null,
            dic: null,
            isNameValid: true,
            isSurnameValid: true,
            isPhoneValid: true,
            isCityValid: true,
            isEmailValid: true,
            isPscValid: true,
            isAddressValid: true,
            isError: false,
            moreInfoShow: false,
            moreInfo: null,
            isMoreInfoValid: true,
            isICValid: true,
            charRestMoreInfo: 250,
            isShoppingOnCompany: false,
            isCompanyNameValid: true,
            isDICValid: true,
        }
    }

    handleNameChange = (event) => {
        this.setState({
            isNameValid: isNameValid(event.target.value),
            name: event.target.value,
        });
    };

    handleSurnameChange = (event) => {
      this.setState({
          isSurnameValid: isSurnameValid(event.target.value),
          surname: event.target.value,
      });
    };

    handlePhoneChange = (event) => {
        this.setState({
            isPhoneValid: isTelephoneValid(event.target.value),
            telephone: event.target.value,
        });
    };

    handleEmailChange = (event) => {
      this.setState({
          isEmailValid: isEmailValid(event.target.value),
          email: event.target.value,
      });
    };

    handleCityChange = (event) => {
        this.setState({
            isCityValid: isCityValid(event.target.value),
            city: event.target.value,
        });
    };

    handleAddressChange = (event) => {
        this.setState({
            isAddressValid: isAddressValid(event.target.value),
            address: event.target.value,
        });
    };

    handlePscChange = (event) => {
        this.setState({
            isPscValid: isPscValid(event.target.value),
            psc: event.target.value,
        });
    };

    handleIcoChange = (event) => {
      this.setState({
          isICValid: isIcoValid(event.target.value),
          ico: event.target.value,
      })
    };

    handleCompanyNameChange = (event) => {
      this.setState({
          isCompanyNameValid: isCompanyNameValid(event.target.value),
          companyName: event.target.value,
      });
    };

    handleDicChange = (event) => {
      this.setState({
          isDICValid: isIcoValid(event.target.value),
          dic: event.target.value,
      });
    };

    handleLinkToBackShopping = () => {
      const { history } = this.props;
      history.push('/eshop');
      window.location.reload();
      window.scrollTo(0,0,);
    };

    isOrderValid() {
        const { shoppingCart } = this.props;
        const {
            name,
            surname,
            address,
            psc,
            email,
            telephone,
            city,
            isNameValid,
            isSurnameValid,
            isPhoneValid,
            isCityValid,
            isEmailValid,
            isPscValid,
            isAddressValid,
            isMoreInfoValid,
            isICValid,
            isCompanyNameValid,
            isDICValid,
        } = this.state;

        return !!(name && surname && address && psc && email && telephone && city &&
            isNameValid && isSurnameValid && isPhoneValid && isCityValid && isEmailValid &&
            isPscValid && isAddressValid && getCountBottleFromShoppingCart(shoppingCart) >= 4 &&
            isMoreInfoValid && isICValid && isDICValid && isCompanyNameValid
        );
    }

    makeProductsArray() {
        const { products } = this.props.shoppingCart;
        let productArray = [];
        products.forEach((product) => {
           for (let i = 0; i < product.count; i++) {
               productArray.push({
                   'id': product.product.id,
               })
           }
        });
        return productArray;
    }

    makeVoucherModel() {
        const { shoppingCart } = this.props;
        if(shoppingCart.voucher && shoppingCart.voucherValid) {
            return {
                'id': shoppingCart.voucher.id,
            }
        }
    }

    makeOrderObject() {
        const {
            name,
            surname,
            address,
            psc,
            email,
            telephone,
            city,
            moreInfo,
            ico,
            dic,
            companyName
        } = this.state;
        const { typeDelivery, shoppingCart, paymentMethod } = this.props;
        return {
            'customer': {
                name,
                surname,
                address,
                psc,
                email,
                telephone,
                city,
            },
            'product': this.makeProductsArray(),
            'typeDelivery': {
                'id': typeDelivery.id,
            },
            'paymentMethod': {
                'id': paymentMethod.id,
            },
            'sumOfShoppingCart': shoppingCart.sumOfShoppingCart,
            'voucher': this.makeVoucherModel(),
            'moreInfo': moreInfo,
            'ico': ico,
            'dic': dic,
            'companyName': companyName,
        }
    }

    handleSubmitOrder = () => {
        const { submitOrder, history, cleanShoppingCart } = this.props;
        if(this.isOrderValid()) {
            submitOrder(this.makeOrderObject()).then(() => {
                history.push('/order-complete');
                cleanShoppingCart();
            });
        } else {
            this.setState({
                isError: true,
            })
        }
    };

    handleMoreInfoClick = () => {
        this.setState({
            moreInfoShow: !this.state.moreInfoShow,
        });
    };

    renderOrderSummary() {
        const { shoppingCart, typeDelivery, typesDelivery, paymentMethod, paymentMethods } = this.props;

        const payment = paymentMethods.find(method => {
            return method.id === paymentMethod.id;
        });

        const delivery = typesDelivery.find(delivery => {
            return delivery.id === typeDelivery.id
        });

        if(delivery && typeDelivery && payment) {
            const bottleCount = getCountBottleFromShoppingCart(shoppingCart);
            return (
                <div className='order-summary-container'>
                    <div className='order-summary-container__info'>
                        <div>
                            <div>
                                {bottleCount} {derivedBottleName(bottleCount)} za {shoppingCart.sumOfShoppingCart} Kč
                            </div>
                            <div>
                                {delivery.title} za {delivery.price} Kč
                            </div>
                            <div>
                                {payment.description} za {payment.price} Kč
                            </div>
                        </div>
                        <div className='order-summary-container__summary'>
                            <div className='order-summary-container__summary--title'>Celkem k úhradě</div>
                            <div>{shoppingCart.sumOfShoppingCart + delivery.price + payment.price} Kč</div>
                            <div className='order-summary-container__no-vat'>{shoppingCart.sumNoVat + countNoVatSum(delivery.price) + countNoVatSum(payment.price)} Kč bez DPH</div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <LoadingDashboardComponent/>
            )
        }
    }

    handleChangeMoreInfo = (event) => {
        const restChar = 250 - event.target.value.length;
        if(restChar < 0) {
            this.setState({
                isMoreInfoValid: false,
            })
        } else {
            this.setState({
                moreInfo: event.target.value,
                charRestMoreInfo: restChar,
                isMoreInfoValid: validateMoreInfo(event.target.value),
            })
        }
    };

    renderFinishBlock() {
        return (
            <div className='order-summary-container__finish-block'>
                <div className='order-summary-container__finish-block--text'>
                    Dokončením objednávky souhlasíte s
                    <span onClick={this.props.showRules}>obchodními podmínkami.</span>
                </div>
                <div className='order-summary-container__finish-block--buttons'>
                    <div>
                        <GertnerButtonComponent
                            text={'Zpět k nákupu'}
                            handleClick={this.handleLinkToBackShopping}
                        />
                    </div>
                    <div className='order-summary-container__finish-block--button'>
                        <GertnerButtonComponent
                            text={'Dokončit objednávku'}
                            handleClick={this.handleSubmitOrder}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderShoppingOnCompanyForm() {
        const {
            isICValid,
            isCompanyNameValid,
            isDICValid,
        } = this.state;
        return (
            <>
                <div className='order-form-container__form--item'>
                    <GertnerInputComponent
                        labelText={'Název firmy'}
                        placeHolder={'Název firmy'}
                        type={'text'}
                        isImportant={false}
                        name={'company_name'}
                        handleChange={this.handleCompanyNameChange}
                        isError={!isCompanyNameValid}
                    />
                </div>
                <div className='order-form-container__form--item'>
                    <GertnerInputComponent
                        labelText={'IČ'}
                        placeHolder={'IČ'}
                        type={'text'}
                        isImportant={false}
                        name={'ico'}
                        handleChange={this.handleIcoChange}
                        isError={!isICValid}
                    />
                </div>
                <div className='order-form-container__form--item'>
                    <GertnerInputComponent
                        labelText={'DIČ'}
                        placeHolder={'DIČ'}
                        type={'text'}
                        isImportant={false}
                        name={'dico'}
                        handleChange={this.handleDicChange}
                        isError={!isDICValid}
                    />
                </div>
            </>
        );
    }

    handleCheckCompanyShopping = () => {
        this.setState({
            isShoppingOnCompany: !this.state.isShoppingOnCompany
        })
    };

    render() {
        const {
            isNameValid,
            isSurnameValid,
            isPhoneValid,
            isCityValid,
            isEmailValid,
            isPscValid,
            isAddressValid,
            isShoppingOnCompany,
            charRestMoreInfo,
            isMoreInfoValid
        } = this.state;
        const { typeDelivery, typesDelivery, isLoading, paymentMethods, paymentMethod } = this.props;
        const moreInfoTextAreaClassName = isMoreInfoValid ? 'order-summary-container__more-info--textarea'
            : 'order-summary-container__more-info--textarea order-summary-container__more-info--textarea-invalid';
        if(typeDelivery && typesDelivery.length > 0  && !isLoading && paymentMethods && paymentMethod) {
            return (
                <div className='order-form-container'>
                    <div className='order-form-container__title'>
                        Komu zboží doručíme ?
                    </div>
                    <div className='order-form-container__company-shopping'>
                        <GertnerCheckboxComponent
                            isChecked={isShoppingOnCompany}
                            title='Nakupuji na firmu'
                            size='small'
                            checkAction={this.handleCheckCompanyShopping}
                        />
                    </div>
                    <form  autoComplete="on">
                    <div className='order-form-container__form'>
                        {
                            isShoppingOnCompany &&
                                this.renderShoppingOnCompanyForm()
                        }
                        <div className='order-form-container__form--item'>
                            <GertnerInputComponent
                                labelText={'Jméno'}
                                placeHolder={'Jméno'}
                                type={'text'}
                                isImportant={true}
                                handleChange={this.handleNameChange}
                                isError={!isNameValid}
                                name={'name'}
                            />
                        </div>
                        <div className='order-form-container__form--item'>
                            <GertnerInputComponent
                                labelText={'Příjmení'}
                                placeHolder={'Příjmení'}
                                type={'text'}
                                isImportant={true}
                                handleChange={this.handleSurnameChange}
                                isError={!isSurnameValid}
                                name={'lastname'}
                            />
                        </div>
                        <div className='order-form-container__form--item'>
                            <GertnerInputComponent
                                labelText={'E-mail'}
                                placeHolder={'E-mail'}
                                type={'text'}
                                handleChange={this.handleEmailChange}
                                isImportant={true}
                                isError={!isEmailValid}
                                name={'email'}
                            />
                        </div>
                        <div className='order-form-container__form--item'>
                            <GertnerInputComponent
                                labelText={'Telefon'}
                                placeHolder={'Telefon'}
                                type={'tel'}
                                isImportant={true}
                                handleChange={this.handlePhoneChange}
                                isError={!isPhoneValid}
                                name={'phone'}
                            />
                        </div>
                        <div className='order-form-container__form--item'>
                            <GertnerInputComponent
                                labelText={'Ulice a číslo popisné'}
                                placeHolder={'Adresa'}
                                type={'text'}
                                isImportant={true}
                                handleChange={this.handleAddressChange}
                                isError={!isAddressValid}
                                name={'address'}
                            />
                        </div>
                        <div className='order-form-container__form--item'>
                            <GertnerInputComponent
                                labelText={'Město'}
                                placeHolder={'Město'}
                                type={'text'}
                                isImportant={true}
                                handleChange={this.handleCityChange}
                                isError={!isCityValid}
                                name={'city'}
                            />
                        </div>
                        <div className='order-form-container__form--item'>
                            <GertnerInputComponent
                                labelText={'PSČ'}
                                placeHolder={'PSČ'}
                                type={'text'}
                                isImportant={true}
                                handleChange={this.handlePscChange}
                                isError={!isPscValid}
                                name={'zipcode'}
                            />
                        </div>
                    </div>
                        <div>
                            <div className='order-summary-container__more-info'>
                                <div className='order-summary-container__more-info--title'>
                                    <label>Chcete něco dodat ?</label>
                                    <span>Zbývá {charRestMoreInfo} znaků</span>
                                </div>
                                <textarea
                                    className={moreInfoTextAreaClassName}
                                    cols={20}
                                    rows={3}
                                    onChange={this.handleChangeMoreInfo}
                                />
                            </div>
                        </div>
                    </form>
                    {this.renderOrderSummary()}
                    {this.renderFinishBlock()}
                    {
                        this.state.isError &&
                        <div className='order-form-container__error'>
                            Chybně vyplněný formulář nebo nedostatek lahví v košíku.
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <LoadingDashboardComponent/>
            )
        }
    }

}

export default withRouter(OrderFormComponent);
