import React, {Component} from "react";
import './OrderCompleteComponent.scss';
import {EshopLinkComponent} from "../../link/EshopLinkComponent";
import EshopMenuComponent from "../../menu/EshopMenuComponent";
import GertnerButtonComponent from "../../../common/GertnerButtonComponent";
import {withRouter} from "react-router";

class OrderCompleteComponent extends Component {

    getAmountOfProducts = (products, product) => {
        let amount = 0;
        products.forEach(item => {
            if(item.id === product.id) {
                amount++;
            }
        });

        return amount;
    };

    isThereMoreThanOneProduct = (productList, product) => {
        const found = productList.find(item => {
            return item.id === product.id;
        });
        return !!found;

    };

    linkProductList = (product) => {

        let linkedProductList = [];
        product.forEach(item => {
            if(!this.isThereMoreThanOneProduct(linkedProductList, item)) {
                const amount = this.getAmountOfProducts(product, item);
                linkedProductList.push({
                    id: item.id,
                    name: item.name,
                    price: amount*item.price,
                    amount: amount,
                    vintage: item.vintage
                })
            }
        });

        return linkedProductList;
    };

    renderProductSummary() {
        const { product, typeDelivery, voucher, paymentMethod } = this.props.completedOrder;
        return (
            <>
                <div className='order-complete-container__row order-complete-container__row--header'>
                    <div className='order-complete-container__col order-complete-container__col--bold'>
                        Víno
                    </div>
                    <div className='order-complete-container__col order-complete-container__col--cart'>
                        Počet
                    </div>
                    <div className='order-complete-container__col order-complete-container__col--cart'>
                        Cena
                    </div>
                </div>
                {
                    this.linkProductList(product).map((item) => {
                        return (
                            <div className='order-complete-container__row'>
                                <div className='order-complete-container__col order-complete-container__col--bold'>
                                    {item.name}, {item.vintage}:
                                </div>
                                <div className='order-complete-container__col order-complete-container__col--cart'>
                                    {item.amount} ks
                                </div>
                                <div className='order-complete-container__col order-complete-container__col--cart'>
                                    {item.price} Kč
                                </div>
                            </div>
                        )
                    })
                }
                {
                    voucher && (
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Voucher:
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--cart'>
                                {voucher.name}
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--cart'>
                                - {voucher.price} {voucher.typeOfVoucher === 'price' ? 'Kč' : '%'}
                            </div>
                        </div>
                    )
                }
                {
                    (typeDelivery && typeDelivery.price > 0) &&
                    (
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                               Doprava:
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--cart'>
                                {typeDelivery.nameDelivery}
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--cart'>
                                {typeDelivery.price} Kč
                            </div>
                        </div>
                    )
                }
                {
                    (paymentMethod && paymentMethod.price > 0) &&
                    (
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Platba:
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--cart'>
                                {paymentMethod.description}
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--cart'>
                                {paymentMethod.price} Kč
                            </div>
                        </div>
                    )
                }

            </>
        )
    }

    renderOrderSummary() {
        const { completedOrder, history } = this.props;
        const queries = new URLSearchParams(this.props.location.search);
        const status = queries.get("status");
        if(completedOrder && completedOrder.paymentUrl && !status) {
            history.push({
                pathname: '/pgw-redirect',
                state: {goPayRedirectUri: completedOrder.paymentUrl}
            });
        }
        if(completedOrder) {
            const { customer } = completedOrder;
            return (
                <div>
                    <div className='order-complete-container__text'>
                        Vaši objednávku jsme přijali.
                        Zde je shrnutí:
                    </div>
                    <div className='order-complete-container__table'>
                        {
                            completedOrder.companyName &&
                            <div className='order-complete-container__row'>
                                <div className='order-complete-container__col order-complete-container__col--bold'>
                                    Název firmy:
                                </div>
                                <div className='order-complete-container__col'>{completedOrder.companyName}</div>
                            </div>
                        }
                        {
                            completedOrder.ico &&
                            <div className='order-complete-container__row'>
                                <div className='order-complete-container__col order-complete-container__col--bold'>
                                    IČ:
                                </div>
                                <div className='order-complete-container__col'>{completedOrder.ico}</div>
                            </div>
                        }
                        {
                            completedOrder.dic &&
                            <div className='order-complete-container__row'>
                                <div className='order-complete-container__col order-complete-container__col--bold'>
                                    DIČ:
                                </div>
                                <div className='order-complete-container__col'>{completedOrder.dic}</div>
                            </div>
                        }
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Jméno a příjmení:
                            </div>
                            <div className='order-complete-container__col'>{customer.name} {customer.surname}</div>
                        </div>
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Adresa:
                            </div>
                            <div className='order-complete-container__col'>
                                {customer.address}, {customer.city}, psc: {customer.psc}
                            </div>
                        </div>
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Email:
                            </div>
                            <div className='order-complete-container__col'>
                                {customer.email}
                            </div>
                        </div>
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Telefon:
                            </div>
                            <div className='order-complete-container__col'>
                                {customer.telephone}
                            </div>
                        </div>
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Způsob doručení:
                            </div>
                            <div className='order-complete-container__col'>
                                {completedOrder.typeDelivery.nameDelivery}
                            </div>
                        </div>
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Platba:
                            </div>
                            <div className='order-complete-container__col'>
                                {completedOrder.paymentMethod.description}
                            </div>
                        </div>
                        <div className='order-complete-container__cart-title'>
                            Obsah košíku
                        </div>
                        {
                            this.renderProductSummary()
                        }
                        <div className='order-complete-container__row order-complete-container__row--sum'>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                Celkem:
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--bold'>
                                {completedOrder.sumOfShoppingCart} Kč
                            </div>
                        </div>
                        <div className='order-complete-container__row'>
                            <div className='order-complete-container__col order-complete-container__col--no-vat'>
                                Celkem bez DPH:
                            </div>
                            <div className='order-complete-container__col order-complete-container__col--no-vat'>
                                {completedOrder.sumNoVat} Kč
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    async orderClean() {
        const {cleanCompletedOrder} = this.props;
        await cleanCompletedOrder();
    }

    handleBackToEshop = () => {
        const {history} = this.props;
        this.orderClean().then(() => {
            history.push('/eshop');
            window.location.reload();
            window.scrollTo(0,0,);
        });
    };

    handleBackToHome = () => {
        const { history } = this.props;
        this.orderClean().then(() => {
            history.push('/');
            window.location.reload();
            window.scrollTo(0,0,);
        });
    };

    renderButtons() {
        return (
            <div className='order-complete-container__buttons-content'>
                <div>
                    <GertnerButtonComponent
                        handleClick={this.handleBackToEshop}
                        text={'Zpět do obchodu'}
                    />
                </div>
                <div className='order-complete-container__link-parent'>
                    <span className='order-complete-container__link' onClick={this.handleBackToHome}>
                        Přejít na web vinařství
                    </span>
                </div>
            </div>
        )
    }

    render() {
        const { completedOrder, history } = this.props;
        window.scrollTo(0,0,);
        if(!completedOrder) {
            history.push('/error');
            window.location.reload();
            window.scrollTo(0,0,);
        }

        return (
            <div className='order-complete-container'>
                <EshopLinkComponent/>
                <div className='order-complete-container__header'>
                    <div className='order-complete-container__header-image'>
                        <div className='order-complete-container__title'>
                            Děkujeme,
                            že jedete s námi
                        </div>
                    </div>
                </div>
                <div className='order-complete-container__content'>
                    {this.renderOrderSummary()}
                </div>
                <div className='order-complete-container__buttons'>
                    {this.renderButtons()}
                </div>
                <div className='order-complete-container__eshop-menu'>
                    <EshopMenuComponent />
                </div>
            </div>
        );
    }

}

export default withRouter(OrderCompleteComponent);
