import React, {Component} from "react";
import './EshopComponent.scss';
import ProductListContainer from "./product/product-list/ProductListContainer";
import EshopHeaderContainer from "./header/EshopHeaderContainer";
import ShoppingCartContainer from "./cart/ShoppingCartContainer";
import CartBannerContainer from "./cart/cart-banner/CartBannerContainer";
import { Element } from 'react-scroll';
import EshopMenuComponent from "./menu/EshopMenuComponent";

export default class EshopComponent extends Component {

    render() {
        return(
            <div className='eshop-container'>
                <EshopHeaderContainer />
                <CartBannerContainer/>
                <ProductListContainer />
                <Element name='shoping-cart-products'>
                <div className='eshop-container__shopping-cart'>
                    <ShoppingCartContainer/>
                </div>
                </Element>
                <EshopMenuComponent />
            </div>
        );
    }

}
