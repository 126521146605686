import React, {Component} from "react";
import VideoComponent from "./video/VideoComponent";
import "./HeaderComponent.scss";
import {Image} from "react-bootstrap";
import {withRouter} from "react-router";


class HeaderComponent extends Component {

    goToHome = () => {
        const { history } = this.props;
        history.push('/');
        window.location.reload();
        window.scrollTo(0,0,);
    };

    render() {
        return(
            <div className="header-container">
                <VideoComponent />
                <div className="header-container__description" onClick={this.goToHome}>
                        <Image src="header_text.svg"/>
                </div>
            </div>
        )
    }
}

export default withRouter(HeaderComponent);
