import {environment} from "../configApi";

export const getAllVouchersAPI = () => {
    return fetch(`${environment}/api/voucher/all`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const checkVoucherAPI = (voucherCode) => {
  return fetch(`${environment}/api/check/voucher?voucherCode=${voucherCode}`, {
      method: 'get',
      headers: {
          'Accept': 'application/json',
      }
  }).then(res => {
          if (res.status >= 400) {
              return res.json().then(data => {
                  return Promise.reject(new Error(data.message));
              });
          }
          return res.json();
      }).catch(error => {
          return Promise.reject(error)
      });
};


export const addNewVoucherAPI = (voucher) => {
    return fetch(`${environment}/api/voucher/create`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }, body: JSON.stringify(voucher)
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const deleteVoucherAPI = (voucherId) => {
    return fetch(`${environment}/api/voucher/${voucherId}`, {
        method: 'delete',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const changeVoucherActivityApi = (voucherId) => {
    return fetch(`${environment}/api/voucher/activity/${voucherId}`, {
        method: "put",
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            console.log(error);
            return Promise.reject(error)
        });
};
