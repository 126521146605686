import React, {Component} from "react";
import './TopThreeProductComponent.scss';
import {withBreakpoints} from "react-breakpoints";
import LoadingDashboardComponent from "../../../common/LoadingDashboardComponent";
import ProductViewContainer from "../ProductViewContainer";
import {withRouter} from "react-router";

class TopThreeProductComponent extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getTopThreeProducts } = this.props;
        getTopThreeProducts();
    }

    handleLinkToEshop = () => {
        const { history } = this.props;
        history.push('/eshop');
        window.location.reload();
        window.scrollTo(0,0,);
    };

    renderDesktop() {
        const { topThreeProducts } = this.props;
        return (
            <div className='top-three-product-container'>
                <div className='top-three-product-container__products'>
                    {
                        topThreeProducts.map(product => (
                            <div className='top-three-product-container__product'>
                                <ProductViewContainer product={product}/>
                            </div>
                        ))
                    }
                </div>
                <span className='top-three-product-container__link' onClick={this.handleLinkToEshop}>
                    Všechna vína
                </span>
            </div>
        );
    }

    getProductViewsForMobile(topThreeProducts) {
        let productViews = [];
        if(topThreeProducts.length > 2) {
            for (let i = 0; i < 2; i++) {
                productViews.push(
                    <div className='top-three-product-container__product'>
                        <ProductViewContainer product={topThreeProducts[i]} size='mobile' />
                    </div>
                    );
            }
        } else {
            topThreeProducts.forEach(product => {
                productViews.push(
                    <div className='top-three-product-container__product'>
                        <ProductViewContainer product={product} size='mobile' />
                    </div>
                    );
            })
        }
        return productViews;
    }

    renderMobile() {
        const { topThreeProducts } = this.props;
        return (
            <div className='top-three-product-container'>
                <div className='top-three-product-container__products'>
                    {this.getProductViewsForMobile(topThreeProducts)}
                </div>
                <span className='top-three-product-container__link' onClick={this.handleLinkToEshop}>
                    Všechna vína
                </span>
            </div>
        );
    }

    render() {
        const { topThreeProducts, isLoading, breakpoints, currentBreakpoint } = this.props;
        if(isLoading) {
            return (
                <LoadingDashboardComponent />
            );
        } else if (topThreeProducts && topThreeProducts.length > 0) {
            if(breakpoints[currentBreakpoint] < breakpoints.desktop){
                return (
                    this.renderMobile()
                );
            } else {
                return (
                    this.renderDesktop()
                );
            }
        } else {
            return (
                <></>
            );
        }
    }

}

export default withBreakpoints(withRouter(TopThreeProductComponent));
