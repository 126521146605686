import React, {Component} from 'react';
import {Toast, ToastBody, ToastHeader} from "react-bootstrap";
import './EshopToastComponent.scss';

export default class EshopToastComponent extends Component {

    handleOnClose = (idx) => {
        const { hideToast } = this.props;
        hideToast(idx);
    };

    render() {
        const { toasts } = this.props;
        return (
            <div className='eshop-toast-container'>
                {toasts.map((toast, idx) => {
                    return (
                        <Toast onClose={() => this.handleOnClose(idx)} show={toast.showToast} delay={1500} autohide
                               className='eshop-toast-container__toast'>
                            <ToastBody>
                                <div className='eshop-toast-container__title'>
                                    Produkt <span className='eshop-toast-container__product'>{toast.product.name} {toast.product.vintage}</span> byl přidán do košíku
                                </div>
                            </ToastBody>
                        </Toast>
                    )
                })}
            </div>
            )
     }
}
