import {
    changeOrderState,
    getAllOrders,
    getOrderStates,
    confirmOrderState,
    getOrderStatistics,
    getOrderStatus,
    getOrderHistory, cleanOrdersReducer
} from './action';

const getAllOrdersAction = () => getAllOrders();
const getOrdersStateAction = () => getOrderStates();
const changeOrderStateAction = (orderId, orderState) => changeOrderState(orderId, orderState);
const confirmOrderStateAction = (orderId) => confirmOrderState(orderId);
const getOrderStatisticsAction = () => getOrderStatistics();
const getOrderStatusAction = () => getOrderStatus();
const getOrdersHistoryAction = (page) => getOrderHistory(page);
const cleanOrdersReducerAction = () => cleanOrdersReducer();

export default {
    getAllOrdersAction,
    getOrdersStateAction,
    changeOrderStateAction,
    confirmOrderStateAction,
    getOrderStatisticsAction,
    getOrderStatusAction,
    getOrdersHistoryAction,
    cleanOrdersReducerAction,
};
