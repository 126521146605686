import {connect} from "react-redux";
import OrderCompleteComponent from "./OrderCompleteComponent";
import OrderConfirmationOperations from "../duck/operation";

const mapStateToProps = (state) => {

    const { completedOrder } = state.orderConfirmation;
    return { completedOrder };
};

const mapDispatchToProps = (dispatch) => {

    const cleanCompletedOrder = () => {
      dispatch(OrderConfirmationOperations.cleanCompletedOrderAction());
    };

    return {
        cleanCompletedOrder,
    }
};

const OrderCompleteContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderCompleteComponent);


export default OrderCompleteContainer;
