import React, {Component} from 'react';
import Chart from 'react-apexcharts';
import {withBreakpoints} from "react-breakpoints";

class OrderPerMonthChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Prodej za měsíc',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen',
                        'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
                }
            },
            series: props.orders,
        }
    }

    render() {
        let width = "700";
        const { currentBreakpoint, breakpoints } = this.props;
        if(breakpoints[currentBreakpoint] < breakpoints.tablet){
            width = "350";
        }
        return (
            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="line" height="350" width={width} />
            </div>
        );
    }
}

export default withBreakpoints(OrderPerMonthChart);
