import {environment} from "../configApi";

export const getAllNotHiddenEventsAPI = () => {
    return fetch(`${environment}/api/event`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const getAllEventsAPI = () => {
    return fetch(`${environment}/api/event/all`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const addNewEventAPI = (event) => {
    return fetch(`${environment}/api/event`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
            'Content-Type': 'application/json',
        }, body: JSON.stringify(event)
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const editEventAPI = (event, eventId) => {
    return fetch(`${environment}/api/event/${eventId}`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
            'Content-Type': 'application/json',
        }, body: JSON.stringify(event)
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const removeEventAPI = (eventId) => {
    return fetch(`${environment}/api/event/${eventId}`,{
        method: 'delete',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        }
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};
