

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegExp =  /^(\+(\d){3})?[0-9]{9}$/;
const pscRegExp = /^[0-9]{5}$/;

export const isNameValid = (name) => {
    return name.length > 0 && name.length < 15;
};

export const isSurnameValid = (surname) => {
    return surname.length > 0 && surname.length < 15;
};

export const isEmailValid = (email) => {
    return emailRegExp.test(email);
};

export const isTelephoneValid = (phone) => {
    return phoneRegExp.test(phone);
};

export const isPscValid = (psc) => {
    return pscRegExp.test(psc);
};

export const isCityValid = (city) => {
  return city.length > 0;
};

export const isAddressValid = (address) => {
    return address.length > 0;
};

export const isIcoValid = (ico) => {
  return ico.length < 20;
};

export const validateMoreInfo = (moreInfo) => {
    return moreInfo.length <= 250;
};

export const isCompanyNameValid = (companyName) => {
    return companyName.length <= 50;
};
