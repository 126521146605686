import {
    SHOW_RULES,
    HIDE_RULES,
} from "./type";


const showRules = () => (dispatch) => {
    dispatch({
        type: SHOW_RULES,
    });
};

const hideRules = () => (dispatch) => {
    dispatch({
        type: HIDE_RULES,
    })
};


export {
    showRules,
    hideRules,
}
