import {
    CLEAN_COMPLETED_ORDER, GET_PAYMENT_METHOD_FAILURE, GET_PAYMENT_METHOD_STARTED, GET_PAYMENT_METHOD_SUCCESS,
    GET_TYPES_DELIVERY_FAILURE,
    GET_TYPES_DELIVERY_STARTED,
    GET_TYPES_DELIVERY_SUCCESS, SELECT_PAYMENT_METHOD,
    SELECT_TYPE_DELIVERY,
    SUBMIT_ORDER_FAILURE,
    SUBMIT_ORDER_STARTED,
    SUBMIT_ORDER_SUCCESS,
} from "./type";
import {getPaymentMethodsAPI, getTypesDeliveryAPI} from "../../../../api/enum/EnumApi";
import {createNewOrderAPI} from "../../../../api/order/OrdersApi";


const getTypesDelivery = () => {
    return (dispatch) => {
        dispatch({
            type: GET_TYPES_DELIVERY_STARTED,
        });
        return getTypesDeliveryAPI().then((data) => {
            return dispatch({
                type: GET_TYPES_DELIVERY_SUCCESS,
                payload: {
                    delivery: data
                }
            });
        }).catch(error => {
            dispatch({
                type: GET_TYPES_DELIVERY_FAILURE,
                error
            })
        });
    };
};

const submitOrder = (order) => {
    return (dispatch) => {
        dispatch({
            type: SUBMIT_ORDER_STARTED,
        });
        return createNewOrderAPI(order).then((data) => {
            dispatch({
                type: SUBMIT_ORDER_SUCCESS,
                order: data,
            });
        }).catch(error => {
            dispatch({
                type: SUBMIT_ORDER_FAILURE,
                error,
            });
        })
    }
};

const selectTypeDelivery = (delivery) => (dispatch) => {

    dispatch({
        type: SELECT_TYPE_DELIVERY,
        delivery: delivery,
    })
};

const selectPaymentMethod = (paymentMethod) => (dispatch) => {
    dispatch({
        type: SELECT_PAYMENT_METHOD,
        paymentMethod: paymentMethod
    });
}

const cleanCompleteOrder = () => (dispatch) => {
    dispatch({
        type: CLEAN_COMPLETED_ORDER,
    });
};

const getPaymentMethods = () => {
    return (dispatch) => {
        dispatch({
            type: GET_PAYMENT_METHOD_STARTED,
        });
        return getPaymentMethodsAPI().then((data) => {
            return dispatch({
                type: GET_PAYMENT_METHOD_SUCCESS,
                payload: {
                    methods: data
                }
            });
        }).catch(error => {
            dispatch({
                type: GET_PAYMENT_METHOD_FAILURE,
                error
            })
        });
    };
};

export {
    getTypesDelivery,
    selectTypeDelivery,
    submitOrder,
    cleanCompleteOrder,
    getPaymentMethods,
    selectPaymentMethod
}
