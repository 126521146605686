import {connect} from "react-redux";
import OrderFormComponent from "./OrderFormComponent";
import OrderOperation from "../duck/operation";
import ShoppingCartOperations from "../../cart/duck/operation";
import ShoppingRulesOperation from "../../../common/duck/operation";

const mapStateToProps = (state) => {
    const { typesDelivery, isLoading, paymentMethods } = state.orderConfirmation;
    const { typeDelivery, paymentMethod } = state.orderConfirmation.order;
    const { shoppingCart } = state.shoppingCart;
    return { typesDelivery, typeDelivery, shoppingCart, isLoading, paymentMethod, paymentMethods};
};

const mapDispatchToProps = (dispatch) => {

    const submitOrder = (order) => {
      return dispatch(OrderOperation.submitOrderAction(order));
    };

    const cleanShoppingCart = () => {
       dispatch(ShoppingCartOperations.cleanShoppingCartAction());
    };

    const showRules = () => {
        dispatch(ShoppingRulesOperation.showRulesAction())
    };

    return {
        submitOrder,
        cleanShoppingCart,
        showRules,
    }
};

const OrderFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderFormComponent);


export default OrderFormContainer;
