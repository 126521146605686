import React, {Component} from "react";
import "./OrderTileComponent.scss";
import LoadingComponent from "../../common/LoadingComponent";
import {Button, Form} from "react-bootstrap";
import OrderPopUpComponent from "../OrderPopUpComponent";

export default class OrderTileComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOrder: null,
            showPopUp: false,
        }

    }

    handleShowPopUp = () => {
        const {orderProp} = this.props;
        this.setState({
            showPopUp: true,
            selectedOrder: orderProp,
        })
    };

    handleConfirmState = (id) => {
        const {confirmOrderState, getAllOrders, getOrderStatus} = this.props;
        confirmOrderState(id).then(() => {
                getAllOrders();
                getOrderStatus();
            }
        );
    };

    handleCloseModal = () => {
        this.setState({
            showPopUp: false,
            selectedOrder: null,
        })
    };

    handleSelectState = (event) => {
        const {changeOrderState} = this.props;
        changeOrderState(event.target.id, event.target.value);
    };

    render() {
        const {orderProp, addressString, loading, orderStates, id, getSelectboxNames, readOnly} = this.props;
        const {selectedOrder, showPopUp} = this.state;
        if (!orderProp || loading || !orderProp.customer) {
            return (
                <LoadingComponent/>
            );
        } else {
            return (
                <div className="order-tile-container">
                    <div className="order-tile-container__row">
                        <span
                            className="order-tile-container__label">Jmeno a příjmení:</span> {orderProp.customer.name} {orderProp.customer.surname}
                    </div>
                    <div className="order-tile-container__row">
                        <span className="order-tile-container__label">Adresa:</span> {addressString(orderProp.customer)}
                    </div>
                    <div className="order-tile-container__row">
                        <span className="order-tile-container__label">Telefon:</span> {orderProp.customer.telephone}
                    </div>
                    <div className="order-tile-container__row">
                        <span className="order-tile-container__label">Email:</span> {orderProp.customer.email}
                    </div>
                    <div className="order-tile-container__row">
                        <span className="order-tile-container__label">Celková cena:</span> {orderProp.sumOfShoppingCart},-
                    </div>
                    <div className="order-tile-container__row">
                        <Button id={id} onClick={this.handleShowPopUp} variant="secondary">Zobraz detail</Button>
                    </div>
                    {!readOnly ?
                        (
                            <div>
                                <div className="order-tile-container__row">
                                    <Form>
                                        <Form.Control as='select' custom value={orderProp.state}
                                                      className="order-tile-container__select" id={id}
                                                      onChange={this.handleSelectState}>
                                            {
                                                orderStates.map(state => (
                                                    <option value={state}>
                                                        {getSelectboxNames(state)}
                                                    </option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form>
                                </div>
                                <div className="order-tile-container__row order-tile-container__confirm-btn">
                                    <Button variant="dark" onClick={() => this.handleConfirmState(id)}>Potvrdit</Button>
                                </div>
                            </div>
                        ) :
                        (
                            <div className="order-tile-container__row">
                                <span
                                    className="order-tile-container__label">{getSelectboxNames(orderProp.state)}</span>
                            </div>
                        )
                    }
                    <OrderPopUpComponent
                        order={selectedOrder}
                        isShow={showPopUp}
                        handleClose={this.handleCloseModal}
                    />
                </div>
            )
        }
    }
};
