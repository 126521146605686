import {environment} from "../configApi";

export const getOrdersStateAPI = () => {
    return fetch(`${environment}/api/enum/orderStates`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};
