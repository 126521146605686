import React, {PureComponent} from "react";
import './AndZweiComponent.scss';
import {withBreakpoints} from "react-breakpoints";
import crossPattern from './../../../../assets/images/cross-pattern-white.svg';
import {Image} from "react-bootstrap";

const title = 'Zkuste to An-Zwei!';
const description = 'Toto cuvée je naše vlastní pojetí André a Zweigeltrebe. ' +
    'Daří se mu ve vinařských soutěžích a spoustu přátel si našlo také mezi našimi zákazníky, kteří se pro něj rádi vrací. ';

class AndZweiComponent extends PureComponent {

    renderList() {
        return (
            <div className='an-zwei-container__list'>
                <ul>
                    <li>Zlatá medaile<br/>
                        <b>Cuvée Ostrava 2019</b>
                    </li>
                    <li>Zlatá medaile<br/>
                        <b>Mikulovské vinné trhy 2019</b>
                    </li>
                    <li>Zlatá medaile<br/>
                        <b>Výstava vín Čejkovice 2019</b>
                    </li>
                    <li>Bronzová medaile<br/>
                        <b>Jarovín rosé 2019</b>
                    </li>
                    <li>Bronzová medaile <br/>
                        <b>Víno Bojnice 2019</b>
                    </li>
                </ul>
            </div>
        )
    }

    renderDesktop() {
        return (
            <div  className='an-zwei-container'>
                <Image className='an-zwei-container__pattern' src={crossPattern}/>
                <div className='an-zwei-container__text'>
                    <span className='an-zwei-container__title'>{title}</span>
                    <div>{description}</div>
                    {this.renderList()}
                </div>
            </div>
        );
    }

    renderMobile(){
        return (
            <div className='an-zwei-mobile-container'>
                <div className='an-zwei-mobile-container__bcg'></div>
                <div className='an-zwei-mobile-container__text'>
                    <span className='an-zwei-mobile-container__title'>
                        {title}
                    </span>
                    <div className='an-zwei-mobile-container__description'>
                        {description}
                    </div>
                    {
                        this.renderList()
                    }
                    <Image className='an-zwei--mobile-container__pattern' src={crossPattern}/>
                </div>
            </div>
        )
    }

    render() {
        const { breakpoints, currentBreakpoint } = this.props;

        if(breakpoints[currentBreakpoint] < breakpoints.desktop) {
            return (
                <>
                    {this.renderMobile()}
                </>
            )
        }
        return(
            <>
                {this.renderDesktop()}
            </>
        )
    }
}

export default withBreakpoints(AndZweiComponent);
