import {environment} from "../configApi";


export const getOrdersHistory = (page, size) => {
    return fetch(`${environment}/api/order/pageable?page=${page}&pageSize=${size}`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        }
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        })
}

export const getAllOrdersAPI = () => {
    return fetch(`${environment}/api/order/all`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const changeOrderStateAPI = (orderId, orderState) => {
    return fetch(`${environment}/api/order/change/${orderState}?idOrder=${orderId}`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.status >= 400) {
            return res.json().then(data => {
                return Promise.reject(new Error(data.message));
            });
        }
    }).catch(error => {
        return Promise.reject(error)
    });
};

export const getOrderStatisticsAPI = () => {
    return fetch(`${environment}/api/order/statistics`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    }).then(res => {
        if (res.status >= 400) {
            return res.json().then(data => {
                return Promise.reject(new Error(data.message));
            });
        }
        return res.json();
    }).catch(error => {
        return Promise.reject(error)
    });
};

export const getOrderStatusAPI = () => {
    return fetch(`${environment}/api/order/status`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    }).then(res => {
        if (res.status >= 400) {
            return res.json().then(data => {
                return Promise.reject(new Error(data.message));
            });
        }
        return res.json();
    }).catch(error => {
        return Promise.reject(error)
    });
};

export const createNewOrderAPI = (order) => {
    return fetch(`${environment}/api/order/create`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }, body: JSON.stringify(order)
    }).then(res => {
        if (res.status >= 400) {
            return res.json().then(data => {
                return Promise.reject(new Error(data.message));
            });
        }
        return res.json();
    }).catch(error => {
        return Promise.reject(error)
    });
};
