import React, {Component} from "react";
import './OrderConfiramtionComponent.scss';
import ShoppingCartContainer from "../cart/ShoppingCartContainer";
import {EshopLinkComponent} from "../link/EshopLinkComponent";
import DeliveryContainer from "./delivery/DeliveryContainer";
import EshopMenuComponent from "../menu/EshopMenuComponent";
import OrderFormContainer from "./order-form/OrderFormContainer";
import {getCountBottleFromShoppingCart} from "../../../admin/common/utils/BasicUtils";
import {withRouter} from "react-router";
import PaymentMethodsContainer from "./payment-method/PaymentMethodsContainer";

class OrderConfirmationComponent extends Component {

    componentDidMount() {
    }

    render() {
        const { shoppingCart } = this.props;
        if(!shoppingCart.products || getCountBottleFromShoppingCart(shoppingCart) < 4) {
            const { history } = this.props;
            history.push('/eshop');
            window.location.reload();
            window.scrollTo(0,0,);
        }
        return (
            <div className='order-confirmation-container'>
                <div className='order-confirmation-container__link-logo'>
                    <EshopLinkComponent/>
                </div>
                <div className='order-confirmation-container__content'>
                    <div className='order-confirmation-container__cart'>
                        <ShoppingCartContainer
                            liteCart={true}
                        />
                    </div>
                    <div className='order-confirmation-container__delivery'>
                        <DeliveryContainer />
                    </div>
                    <div className='order-confirmation-container__payment-method'>
                        <PaymentMethodsContainer />
                    </div>
                    <div className='order-confirmation-container__form'>
                        <OrderFormContainer />
                    </div>
                    <div className='order-confirmation-container__eshop-menu'>
                        <EshopMenuComponent />
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(OrderConfirmationComponent);
