// configureStore.js

import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createRootReducer from './reducers';
import jwt_decode from 'jwt-decode';
import {LOGOUT_USER_SUCCESS} from "./admin/login/duck/type";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

export const history = createBrowserHistory();

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: hardSet,
};

export default function configureStore(preloadedState) {
    let middleware = [thunk, routerMiddleware(history), checkTokenExpirationMiddleware];
    if(process.env.NODE_ENV === 'development') {
        middleware = [...middleware, logger];
    }
  const store = createStore(
    persistReducer(persistConfig, createRootReducer(history)), // root reducer with router state
    preloadedState,
    compose(
      composeWithDevTools(applyMiddleware(...middleware)),
    ),
  );

  const persistor = persistStore(store);

  return { store, persistor };
}

const checkTokenExpirationMiddleware = store => next => action => {
    const token = localStorage.getItem("token");
    if(store.getState().authReducer.oidc.isLogin) {
        if (token) {
            if (jwt_decode(token).exp < Date.now() / 1000) {
                //next(action);
                localStorage.clear();
                store.dispatch({
                    type: LOGOUT_USER_SUCCESS,
                });
                history.push('/admin');
            }
        } else {
            history.push('/admin');
        }
    }
    next(action);
};
