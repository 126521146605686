import {environment} from "../configApi";

export const loginServiceApi = (login, password) => {
   return fetch(`${environment}/api/auth/signin`, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
            login: login,
            password: password
        })
    })
        .then(res => {
             if (res.status >= 400) {
                 return res.json().then(data => {
                     return Promise.reject(new Error(data.message));
                 });

             }
            return res.json();
        }).catch(error => {
            console.log(error);
            return Promise.reject(error)
        });
    };
