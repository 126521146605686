import { connect } from 'react-redux';
import DashboardComponent from "./DashboardComponent";
import LoginOperation from "../login/duck/operation";
import ConstantsOperation from "./duck/operation";

const mapStateToProps = (state) => {
    const { oidc } = state.authReducer;
    const { constants } = state.constants;
    const { showToast } = state.toastReducer;
    return {
        oidc, showToast, constants
    };
};

const mapDispatchToProps = (dispatch) => {
    const logoutUser = () => {
        dispatch(LoginOperation.logoutUserAction());
    };

    const getAllConstants = () => {
        dispatch(ConstantsOperation.getAllConstantsAction());
    };

    return {
        logoutUser,
        getAllConstants,
    };
};

const DashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardComponent);

export default DashboardContainer;
