import React, {PureComponent} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import "./OrderPopUpComponent.scss";
import './../GlobalAdmin.scss';
import {toReadableDateFormat} from "../../application/eshop/utils/DateUtils";
import {getStatusLocalizationText} from "../../application/eshop/utils/PaymentStatusUtils";

export default class OrderPopUpComponent extends PureComponent {

    constructor(props) {
        super(props);
    }

    renderIcoTable() {
        const {order} = this.props;
        if (order.ico || order.companyName || order.dic) {
            return (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Název firmy</th>
                        <th>IČ</th>
                        <th>DIČ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <td>
                        {order.companyName ? order.companyName : '---'}
                    </td>
                    <td>
                        {order.ico ? order.ico : '---'}
                    </td>
                    <td>
                        {order.dic ? order.dic : '---'}
                    </td>
                    </tbody>
                </Table>
            );
        } else {
            return (<></>);
        }
    }

    render() {
        const {isShow, handleClose, order} = this.props;
        const productMap = order && order.productMap;
        return (
            <div>
                {order &&
                <Modal className="order-popup-container" show={isShow} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title className="order-popup-container__title">
                            Objednavka pro: {order.customer.name + ' ' + order.customer.surname}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped bordered hover>
                            <tr>
                                <td>Datum objednávky</td>
                                <td>{toReadableDateFormat(order.dateOrder)}</td>
                            </tr>
                        </Table>
                        {
                            this.renderIcoTable()
                        }
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Vino</th>
                                <th>Rocnik</th>
                                <th>Pocet ks</th>
                                <th>Cena za ks</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                productMap.map((product) => {
                                    return (
                                        <tr>
                                            <td>{product.name}</td>
                                            <td>{product.vintage}</td>
                                            <td>{product.counts}</td>
                                            <td>{product.price},-</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </Table>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Zpusob dopravy</th>
                                <th>Cena za dopravu</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{order.typeDelivery.nameDelivery}</td>
                                <td>{order.typeDelivery.price},-</td>
                            </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Zpusob platby</th>
                                {order.transaction &&
                                    (<th>Stav platby</th>)
                                }
                                <th>Cena za platbu</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{order.paymentMethod.description}</td>
                                {order.transaction && (
                                    <td>{getStatusLocalizationText(order.transaction.state)}</td>
                                )}
                                <td>{order.paymentMethod.price},-</td>
                            </tr>
                            </tbody>
                        </Table>
                        {
                            order.moreInfo &&
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Doplňující informace</th>
                                </tr>
                                </thead>
                                <tbody>
                                <td>{order.moreInfo}</td>
                                </tbody>
                            </Table>
                        }
                        {
                            order.voucher && (
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Jméno voucheru</th>
                                        <th>Sleva</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{order.voucher.name}</td>
                                        <td>{order.voucher.price} %</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            )
                        }
                        <Table striped bordered hover>
                            <tbody>
                            <tr>
                                <td className="admin-bold">Cena celkem</td>
                                <td className="admin-bold">{order.sumOfShoppingCart},-</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Zavřít
                        </Button>
                    </Modal.Footer>
                </Modal>
                }
            </div>
        );
    }

}
