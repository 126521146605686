import {connect} from "react-redux";
import EshopHeaderComponent from "./EshopHeaderComponent";
import ProductOperation from "../duck/operation";

const mapStateToProps = (state) => {

    const { allProducts, isLoading, error } = state.productsDashboard;

    return { allProducts, isLoading, error };
};

const mapDispatchToProps = (dispatch) => {

    const applyFilter = (filter) => {
        dispatch(ProductOperation.applyFilterAction(filter));
    };

    return {
        applyFilter,
    }
};

const EshopHeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EshopHeaderComponent);


export default EshopHeaderContainer;
