import {addNewMedal, editMedal, getAllMedals, removeMedal} from "./action";

const getAllMedalsAction = () => getAllMedals();
const editMedalAction = (actualityId, actuality) => editMedal(actualityId, actuality);
const addNewMedalAction = (actuality) => addNewMedal(actuality);
const removeMedalAction = (medalId) => removeMedal(medalId);

export default {
    getAllMedalsAction,
    editMedalAction,
    addNewMedalAction,
    removeMedalAction,
}
