import {
    ADD_NEW_MEDAL_FAILURE,
    ADD_NEW_MEDAL_STARTED, ADD_NEW_MEDAL_SUCCESS,
    EDIT_MEDAL_FAILURE,
    EDIT_MEDAL_STARTED, EDIT_MEDAL_SUCCESS,
    GET_ALL_MEDALS_FAILURE,
    GET_ALL_MEDALS_STARTED,
    GET_ALL_MEDALS_SUCCESS, REMOVE_MEDAL_FAILURE, REMOVE_MEDAL_STARTED, REMOVE_MEDAL_SUCCESS,
} from "./type";
import {
    addNewMedalAPI,
    getAllMedalsAPI, removeMedalApi,
    updateMedalAPI
} from "../../../api/actuality/MedalApi";
import {showToast} from "../../common/toast/duck/action";

const getAllMedals = () => {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_MEDALS_STARTED,
        });
        return getAllMedalsAPI().then((data) => {
           dispatch({
               type: GET_ALL_MEDALS_SUCCESS,
               payload: {
                   actualities: data,
               }
           });
        }).catch(error => {
            dispatch({
                type: GET_ALL_MEDALS_FAILURE,
                error,
            });
        });
    }
};

const editMedal = (actualityId, actuality) => {
    return (dispatch) => {
        dispatch({
            type: EDIT_MEDAL_STARTED,
        });
        return updateMedalAPI(actualityId, actuality).then(() => {
            dispatch({
                type: EDIT_MEDAL_SUCCESS,
            });
            dispatch(showToast('Upraveno', 'Medaile byla v pořádku upravena.', false));
        }).catch(error => {
            dispatch({
                type: EDIT_MEDAL_FAILURE,
                error,
            });
            dispatch(showToast('Chyba', 'Nepodařilo se uložit úpravy.', true));
        });
    }
};

const addNewMedal = (actuality) => {
  return (dispatch) => {
      dispatch({
          type: ADD_NEW_MEDAL_STARTED,
      });
      return addNewMedalAPI(actuality).then(() => {
          dispatch({
              type: ADD_NEW_MEDAL_SUCCESS,
          });
          dispatch(showToast('Přidáno', 'Medaile byla úspěšně přidána', false));
      }).catch(error => {
          dispatch({
              type: ADD_NEW_MEDAL_FAILURE,
              error,
          });
          dispatch(showToast('Chyba', 'Nepodařilo se přidat novou medaili.', true));
      });
  }
};

const removeMedal = (medalId) => {
  return (dispatch) => {
    dispatch({
        type: REMOVE_MEDAL_STARTED,
    });
    return removeMedalApi(medalId).then(() => {
        dispatch({
            type: REMOVE_MEDAL_SUCCESS,
        });
        dispatch(showToast("Odstraněno", "Medaile byla úspěšně odstraněna", false));
    }).catch(error => {
        dispatch({
            type: REMOVE_MEDAL_FAILURE,
            error,
        });
        dispatch(showToast('Chyba', 'Nepodařilo se odstranit medaili', true));
    });
  }
};

export {
    getAllMedals,
    editMedal,
    addNewMedal,
    removeMedal,
}
