import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AdminHomeContainer from "./admin/home/AdminHomeContainer";
import {Provider} from "react-redux";
import {persistor, store} from "./index";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserDashboardComponent from "./application/dashboard/UserDashboardComponent";
import {IconContext} from "react-icons";
import ReactBreakpoints from 'react-breakpoints'
import EshopComponent from "./application/eshop/EshopComponent";
import ProductDetailComponent from "./application/eshop/product/product-detail/ProductDetailComponent";
import {PersistGate} from 'redux-persist/integration/react';
import EshopToastContainer from "./application/eshop/eshop-toast/EshopToastContainer";
import OrderConfirmationContainer from "./application/eshop/order-confirmation/OrderConfirmationContainer";
import OrderCompleteContainer from "./application/eshop/order-confirmation/order-complete/OrderCompleteContainer";
import AgeVerificationComponent from "./application/ageverification/AgeVerificationComponent";
import ErrorComponent from "./application/error/ErrorComponent";
import ShoppingRulesContainer from "./application/common/ShoppingRulesContainer";
import CookieConsent from "react-cookie-consent";
import PgwRedirectComponent from "./application/eshop/order-confirmation/pgw-redirect/PgwRedirectComponent";
import SubsidyContainer from "./application/subsidy/SubsidyContainer";

const breakpoints = {
    mobile: 320,
    mobileLandscape: 480,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
};

const App = ({history}) => {
    return (
        <ReactBreakpoints breakpoints={breakpoints}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <IconContext.Provider value={{className: 'react-icons'}}>
                        <div className='toast-wrapper'>
                            <EshopToastContainer/>
                        </div>
                        <div>
                            <AgeVerificationComponent/>
                        </div>
                        <Router history={history}>
                            <Switch>
                                <Route
                                    exact
                                    path='/'
                                    component={UserDashboardComponent}/>
                                <Route
                                    path='/admin'
                                    component={AdminHomeContainer}/>
                                <Route
                                    exact
                                    path='/eshop'
                                    component={EshopComponent}
                                />
                                <Route
                                    exact
                                    path='/error'
                                    component={ErrorComponent}
                                />
                                <Route
                                    path='/eshop/:productId'
                                    component={ProductDetailComponent}
                                />
                                <Route
                                    path='/order-confirmation'
                                    component={OrderConfirmationContainer}
                                />
                                <Route
                                    path='/order-complete'
                                    component={OrderCompleteContainer}
                                />
                                <Route
                                    path='/pgw-redirect'
                                    component={PgwRedirectComponent}/>
                            </Switch>
                        </Router>
                        <CookieConsent
                            location="bottom"
                            buttonText="Souhlasím"
                            cookieName="myAwesomeCookieName2"
                            style={{background: "#13444A"}}
                            buttonStyle={{color: "#13444A", fontSize: "13px"}}
                            expires={150}
                        >
                            Tento web používá soubory cookie. Dalším procházením tohoto webu vyjadřujete souhlas s
                            jejich používáním.
                        </CookieConsent>
                        <ShoppingRulesContainer/>
                        <SubsidyContainer/>
                    </IconContext.Provider>
                </PersistGate>
            </Provider>
        </ReactBreakpoints>
    );
};

export default App;
