import { connect } from 'react-redux';
import LoginOperation from "../login/duck/operation";
import NavBarComponent from "./NavBarComponent";

const mapStateToProps = (state) => {
    const { oidc } = state.authReducer;
    const { orderStatus }= state.ordersReducer;
    return {
        oidc, orderStatus
    };
};

const mapDispatchToProps = (dispatch) => {
    const logoutUser = () => {
        dispatch(LoginOperation.logoutUserAction());
    };
    return {
        logoutUser,
    };
};

const NavBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavBarComponent);

export default NavBarContainer;
