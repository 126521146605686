import React, {Component} from "react";
import OrderPopUpComponent from "./OrderPopUpComponent";
import {Button, Table} from "react-bootstrap";
import OrderStatsCardComponent from "./statistics/OrderStatsCardComponent";
import LoadingComponent from "../common/LoadingComponent";
import "./OrdersComponent.scss";
import {withBreakpoints} from "react-breakpoints";
import OrderTileContainer from "./tile/OrderTileContainer";
import {FaInfoCircle} from "react-icons/all";

class OrdersComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            showPopUp: false,
            selectedOrder: null,
            orders: {},
        }
    }

    componentDidMount() {
        const { getOrderStates, getAllOrders, getOrderStatus } = this.props;
        getAllOrders();
        getOrderStates();
        getOrderStatus();
    }


    createAddressString = (customer) => {
        return customer.address+', '+customer.city+' psc: '+customer.psc;
    };

    handleShowPopup = (order) => {
        this.setState({
            selectedOrder: order,
            showPopUp: true,
        })
    };

    handleCloseModal = () => {
        this.setState({
            showPopUp: false,
        });
    };

    handleSelectedState = (event) => {
        const { changeOrderState } = this.props;
        changeOrderState(event.target.id, event.target.value);
    };

    handleConfirmState = (id) => {
        const { confirmOrderState, getAllOrders, getOrderStatus } = this.props;
        confirmOrderState(id).then(() => {
                getAllOrders();
                getOrderStatus();
            }
        );
    };

    getTextForSelectBox = (orderState) => {
        switch (orderState) {
            case 'wait':
                return 'Čeká';
            case 'delivery':
                return 'Doručováno';
            case 'settled':
                return 'Odesláno';
            case 'complete':
                return 'Kompletní';
            default:
                return 'Neznámý stav';
        }
    };
    renderStateSelectBox = (order, idx) => {
        const { orderState } = this.props;
        return (
            <select value={order.state}
                    className="uk-select" id={idx} onChange={this.handleSelectedState}>
                {
                    orderState.map(state => (
                        <option value={state}>
                            {this.getTextForSelectBox(state)}
                        </option>
                    ))
                }
            </select>
        )
    };

    renderDesktop = () => {
        const { selectedOrder, showPopUp } = this.state;
        const { orderState, orders, orderStatus, isLoading } = this.props;
        if (!orders || !orderState || !orderStatus || isLoading) {
            return (
                <LoadingComponent />
            )
        } else if(orders.length === 0){
            return (
                <div className="uk-position-center uk-text-lead">
                    <OrderStatsCardComponent stats={orderStatus} />
                    Nemate zadnou novou objednavku
                </div>
            )
        }
        else {
            return (
                <div className="orders-container">
                    <OrderStatsCardComponent stats={orderStatus} />
                    <div className="orders-container__table-block">
                        <Table hover striped>
                            <thead>
                            <tr>
                                <th>Jmeno a Prijmeni</th>
                                <th>Adresa</th>
                                <th>Telefon</th>
                                <th>Email</th>
                                <th>Detail</th>
                                <th>Cena celkem</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map((order, idx) => (
                                <tr>
                                    <td>{order.customer.name+' '+order.customer.surname}</td>
                                    <td>{this.createAddressString(order.customer)}</td>
                                    <td>{order.customer.telephone}</td>
                                    <td>{order.customer.email}</td>
                                    <td>
                                        <FaInfoCircle size={'30px'} color={'#e28602'} style={{cursor: 'pointer'}}
                                                      onClick={() => this.handleShowPopup(order)}
                                        />
                                    </td>
                                    <td>{order.sumOfShoppingCart},-</td>
                                    <td>
                                        {this.renderStateSelectBox(order, idx)}
                                    </td>
                                    <td>
                                        <Button onClick={() => this.handleConfirmState(idx)}>Potvrdit</Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <OrderPopUpComponent order={selectedOrder} isShow={showPopUp} handleClose={this.handleCloseModal}/>
                    </div>
                </div>
            )
        }
    };

    renderMobile = () => {
     const {
         orderState,
         orders,
         isLoading,
         getAllOrders
     } = this.props;

      return(
          <div className="orders-container-mobile">
              {orders ?
                  orders.map((order, idx) => {
                      return (
                          <OrderTileContainer
                              orderProp={order}
                              addressString={this.createAddressString}
                              loading={isLoading}
                              orderStates={orderState}
                              id={idx}
                              getOrders={getAllOrders}
                              getSelectboxNames={this.getTextForSelectBox}
                          />
                      )
                  })
                  :
                  <LoadingComponent/>
              }
          </div>
      );
    };

    render() {
        const { breakpoints, currentBreakpoint } = this.props;
        const { selectedOrder, showPopUp } = this.state;
        return (
            <>
                {breakpoints[currentBreakpoint] > breakpoints.tablet ? (
                    this.renderDesktop()
                ) : (
                   this.renderMobile()
                )}
            </>
        )
    }

}

export default withBreakpoints(OrdersComponent);
