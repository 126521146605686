import React, {Component} from "react";
import './ShoppingCartComponent.scss';
import ProductCartIteratorComponent from "../common/ProductCartIterratorComponent";
import {MdClose} from "react-icons/all";
import {
    bottleNameRest,
    derivedBottleName,
    getCountBottleFromShoppingCart
} from "../../../admin/common/utils/BasicUtils";
import GertnerInputComponent from "../../common/GertnerInputComponent";
import {withBreakpoints} from "react-breakpoints";
import {withRouter} from "react-router";

class ShoppingCartComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            voucherFormHidden: true,
            voucherCode: '',
        }
    }

    async removeFromCart(idx) {
        const { removeFromShoppingCart } = this.props;
        await removeFromShoppingCart(idx);
    }

    handleRemoveFromShoppingCart = (idx) => {
        const { recountShoppingCart } = this.props;
        this.removeFromCart(idx).then(() => {
            recountShoppingCart();
        });
    };

    async increaseBottle(idx, price) {
        const { increaseCountBottle } = this.props;
        await increaseCountBottle(idx, 1, price);
    }

    handleOnPlus = (idx, price) => {
        const { recountShoppingCart } = this.props;
        this.increaseBottle(idx, price).then(() => {
            recountShoppingCart();
        });
    };

    async decreaseBottle(idx, price) {
        const { decreaseCountBottle } = this.props;
        await decreaseCountBottle(idx, price);
    }

    async changeBottleCount(idx, count, price) {

        const { changeBottleCount } = this.props;
        await changeBottleCount(idx, count, price)
    }

    handleOnMinus = (idx, count, price) => {
        if(count > 0) {
            const { recountShoppingCart } = this.props;
            this.decreaseBottle(idx, price).then(()=>{
                recountShoppingCart();
            });
        }
    };

    handleOnChange = (idx, count, price, newCount) => {

        const { recountShoppingCart } = this.props;

        const computedPrice = price * newCount;

        this.changeBottleCount(idx, newCount, computedPrice).then(() => {
            recountShoppingCart();
        });
    }

    getSumText(sumOfSHoppingCart) {
        const bottleCount = getCountBottleFromShoppingCart(this.props.shoppingCart);
        return bottleCount+' '+derivedBottleName(bottleCount)+' za '+sumOfSHoppingCart+' Kč';
    }

    handleRoute = (id) => {
      const { history } = this.props;
      history.push(`/eshop/${id}`);
      window.location.reload();
      window.scrollTo(0,0,);
    };

    renderCartRow(product, count, price, idx) {
        const { breakpoints, currentBreakpoint } = this.props;
        return (
            <div className='shopping-cart-container__cart-row'>
                <div className='shopping-cart-container__cart-cell'>
                    <span onClick={() => this.handleRoute(product.id)}>
                        <div className='shopping-cart-container__cart-row--name'>{product.name}</div>
                    </span>
                    {
                        breakpoints[currentBreakpoint] < breakpoints.tabletLandscape &&
                        <div onClick={() => this.handleRemoveFromShoppingCart(idx)}>
                            <MdClose size={30} />
                        </div>
                    }
                </div>
                <div className='shopping-cart-container__cart-cell'>
                    <div className='shopping-cart-container__cart-row--vintage'>
                        {product.vintage}, {product.type}
                    </div>
                </div>
                <div className='shopping-cart-container__cart-cell'>
                    <div><ProductCartIteratorComponent
                        count={count}
                        onPlus={() => this.handleOnPlus(idx, product.price)}
                        onMinus={() => this.handleOnMinus(idx, count, product.price)}
                        onChange={(newCount) => this.handleOnChange(idx, count, product.price, newCount)}
                    /></div>
                </div>
                <div className='shopping-cart-container__cart-cell'>
                    <div className='shopping-cart-container__cart-row--price'>
                        {price} Kč
                    </div>
                </div>
                {
                  breakpoints[currentBreakpoint] >= breakpoints.tabletLandscape &&
                  <div className='shopping-cart-container__cart-cell'>
                      <div onClick={() => this.handleRemoveFromShoppingCart(idx)}>
                          <MdClose size={30} />
                      </div>
                  </div>
                }
              </div>
        )
    }

    renderCartRest() {
        const countBottle = getCountBottleFromShoppingCart(this.props.shoppingCart);
        let restText = bottleNameRest(countBottle);
        return (
            <div className='shopping-cart-container__cart-rest'>
                {restText}
            </div>
        );
    }

    handleVoucherClick = () => {
      this.setState({
          voucherFormHidden: !this.state.voucherFormHidden,
      });
    };


    handleConfirmVoucher = () => {
        const { checkVoucher } = this.props;
        checkVoucher(this.state.voucherCode);
    };

    handleChangeVoucherCode = (event) => {
        this.setState({
            voucherCode: event.target.value,
        });
    };

    handleRemoveVoucher = () => {
        const { removeVoucher } = this.props;
        removeVoucher();
    };

    handleToConfirmOrder = () => {
        const { history } = this.props;
        history.push(`/order-confirmation`);
        window.location.reload();
        window.scrollTo(0,0)
    };

    renderVoucherRow(voucher){
        const { breakpoints, currentBreakpoint } = this.props;
        return (
            <div className='shopping-cart-container__cart-row'>
                <div className='shopping-cart-container__cart-cell'>
                    <div className='shopping-cart-container__cart-row--name'>{voucher.name}</div>
                    {
                        breakpoints[currentBreakpoint] < breakpoints.tabletLandscape &&
                        <div onClick={this.handleRemoveVoucher}>
                            <MdClose size={30}/>
                        </div>
                    }
                </div>
                <div className='shopping-cart-container__cart-cell'>
                </div>
                <div className='shopping-cart-container__cart-cell'>
                </div>
                <div className='shopping-cart-container__cart-cell'>
                    <div className='shopping-cart-container__cart-row--price'>
                        -{voucher.price} {voucher.typeOfVoucher === 'price' ? 'Kč' : '%'}
                    </div>
                </div>
                {
                    breakpoints[currentBreakpoint] >= breakpoints.tabletLandscape &&
                    <div className='shopping-cart-container__cart-cell'>
                        <div onClick={this.handleRemoveVoucher}>
                            <MdClose size={30}/>
                        </div>
                    </div>
                }
            </div>
        );
    }

    renderVoucher() {
        const { voucherValid } = this.props.shoppingCart;
        const { voucherFormHidden } = this.state;
        const voucherClassName = voucherFormHidden ?
            'shopping-cart-container__voucher-form--hidden' :
            'shopping-cart-container__voucher-form';
        return (
            <div className='shopping-cart-container__voucher'>
                      <span className='shopping-cart-container__voucher-title' onClick={this.handleVoucherClick}>
                          Mám poukaz, nebo slevový kód {!voucherFormHidden && <MdClose/>}
                      </span>
                <div className={voucherClassName}>
                    <GertnerInputComponent
                        labelText='Slevový kód:'
                        placeHolder='Zadejte slevový kód'
                        handleChange={this.handleChangeVoucherCode}
                        isError={!voucherValid}
                    />
                    <div className='shopping-cart-container__voucher-btn-wrap'>
                        <button className='shopping-cart-container__voucher-btn' onClick={this.handleConfirmVoucher}>
                            Potvrdit
                        </button>
                    </div>
                </div>
                {
                    (!voucherValid) &&
                    <div className='shopping-cart-container__voucher-error'>
                        Neplatný slevový kód
                    </div>
                }
            </div>
        );
    }

    render() {
        const { shoppingCart, liteCart } = this.props;
        if(shoppingCart.products.length === 0) {
            return (
                <div className='shopping-cart-container'>
                    <div className='shopping-cart-container__title'>
                        Váš nákupní košík
                    </div>
                    <div className='shopping-cart-container__sum'>
                        <div className='shopping-cart-container__cart-sum'>
                            Váš košík je prázdný
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='shopping-cart-container'>
                    <div className='shopping-cart-container__title'>
                        Váš nákupní košík
                    </div>
                    {
                        (shoppingCart && shoppingCart.products) &&
                        <div className='shopping-cart-container__table'>
                            {
                                shoppingCart.products.map((product, idx) => {
                                    return this.renderCartRow(product.product, product.count, product.price, idx)
                                })
                            }
                            {shoppingCart.voucher && this.renderVoucherRow(shoppingCart.voucher)}
                        </div>
                    }
                    { !liteCart && this.renderCartRest()}
                    <div className='shopping-cart-container__sum'>
                        {(!shoppingCart.voucher && !liteCart) && this.renderVoucher()}
                        <div>
                            <div className='shopping-cart-container__cart-sum'> {this.getSumText(shoppingCart.sumOfShoppingCart)}</div>
                            <div className='shopping-cart-container__no-vat'>{shoppingCart.sumNoVat} Kč Bez DPH</div>
                        </div>
                    </div>
                    {
                        (getCountBottleFromShoppingCart(shoppingCart) >= 4 && !liteCart) &&
                        <div className='shopping-cart-container__order-btn'>
                            <button onClick={this.handleToConfirmOrder} className='shopping-cart-container__order-confirm-btn'>K pokladně</button>
                        </div>
                    }
                </div>
            );
        }
    }

}

ShoppingCartComponent.defaultProps = {
  liteCart: false,
};

export default withRouter(withBreakpoints(ShoppingCartComponent));
