export const GET_ALL_EVENTS_STARTED = 'GET_ALL_EVENTS_STARTED';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILURE = 'GET_ALL_EVENTS_FAILURE';
export const EDIT_EVENT_STARTED = 'EDIT_EVENT_STARTED';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';
export const ADD_NEW_EVENT_STARTED = 'ADD_NEW_EVENT_STARTED';
export const ADD_NEW_EVENT_SUCCESS = 'ADD_NEW_EVENT_SUCCESS';
export const ADD_NEW_EVENT_FAILURE = 'ADD_NEW_EVENT_FAILURE';
export const REMOVE_EVENT_STARTED = 'REMOVE_EVENT_STARTED';
export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS';
export const REMOVE_EVENT_FAILURE = 'REMOVE_EVENT_FAILURE';
