import {
    showRules,
    hideRules
} from "./action";

const showRulesAction = () => showRules();
const hideRulesAction = () => hideRules();

export default {
    showRulesAction, hideRulesAction
}
