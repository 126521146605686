import React, {PureComponent} from "react";
import LoadingDashboardComponent from "../../../common/LoadingDashboardComponent";
import {withRouter} from "react-router";
import './PgwRedirectComponent.scss';

class PgwRedirectComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            render: true //Set render state to false
        }
    }

    componentDidMount() {
        setTimeout(function() { //Start the timer
            this.setState({render: false}) //After 3 second, set render to true
        }.bind(this), 3000)
    }

    render() {

        if(this.state.render) {
            return (
                <div className='pgw-redirect-container'>
                    <LoadingDashboardComponent />
                    <div className='pgw-redirect-container__description'>
                        Budete přesměrováni na platební bránu GoPay
                    </div>
                </div>
            )
        } else {
            const { goPayRedirectUri } = this.props.location.state;
            const { history } = this.props;
            if(goPayRedirectUri) {
                window.location.href = goPayRedirectUri;
            } else {
                history.push('/error');
            }
        }
    }
}

export default withRouter(PgwRedirectComponent);
