import {connect} from "react-redux";
import VoucherAdminComponent from "./VoucherAdminComponent";
import VoucherOperations from "./duck/operation";
import ConstantsOperations from "../dashboard/duck/operation";


const mapStateToProps = (state) => {
    const { vouchers, isLoading, error } = state.vouchers;
    const voucherConstants = state.constants.voucher;
    return { vouchers, isLoading, error, voucherConstants };
};

const mapDispatchToProps = (dispatch) => {

    const getAllVouchers = () => {
      dispatch(VoucherOperations.getAllVouchersAction());
    };

    const changeVoucherActivity = (voucherId) => {
      return dispatch(VoucherOperations.changeVoucherActivityAction(voucherId));
    };

    const deleteVoucher = (voucherId) => {
        return dispatch(VoucherOperations.deleteVoucherAction(voucherId));
    };

    const addNewVoucher = (voucher) => {
        return dispatch(VoucherOperations.addNewVoucherAction(voucher));
    };

    const getAllConstants = () => {
      return dispatch(ConstantsOperations.getAllConstantsAction());
    };

    return {
        getAllVouchers,
        changeVoucherActivity,
        deleteVoucher,
        addNewVoucher,
        getAllConstants
    }
};

const VoucherAdminContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VoucherAdminComponent);


export default VoucherAdminContainer;
