import React, {PureComponent} from "react";
import {Modal} from "react-bootstrap";

export default class ShoppingRulesModal extends PureComponent {

    render() {
        const {isRulesShow, hideRules} = this.props;
        return (
            <Modal show={isRulesShow} size='lg' onHide={hideRules}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Obchodní podmínky
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="WordSection1">
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><b><span style={{fontSize: '24.0pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Obchodní podmínky</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">I.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Základní ustanovení</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;Tyto všeobecn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">obchodní podmínky (dále jen „obchodní podmínky“) jsou vydan</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">dle § 1751 a násl. zákona č. 89/2012 Sb., obč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">ansk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ý
            zákoník (dále jen „obč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">ansk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ý zákoník“)</span></p>
                        <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: 'normal'}}><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Ing.
            </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Marek Gertner - Vinařství Gertner</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">IČ: 04416678</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">DIČ: CZ</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">9109154648</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">se sídlem: Starý Poddvorov 239, 696 16</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Úřad příslušný podle </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#202124', border: 'none', background: 'white'}} lang="CS">§ </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">odst.
            2 živnostenského zákona: </span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Městský úřad Hodonín</span></b><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'windowtext', border: 'none'}} lang="CS"><br />
            <br />
          </span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">kontaktní osoba: Ing. Marek
            Gertner</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">email: info@vinarstvigertner.cz</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">telefon: </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">+420 723&nbsp;210 156, +420 </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">721</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">873</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS"> </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">340</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">w</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">eb</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">: www.vinarstvigertner.cz</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">(dále jen „prodávající“)</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;Tyto obchodní podmínky
            upravují vzájemná práva a povinnosti prodávajícího a fyzick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">osoby, která uzavírá kupní smlouvu mimo svoji podnikatelskou
            činnost jako spotřebitel, nebo v rámci sv</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">podnikatelské
            činnosti (dále jen: „kupující“) prostřednictví</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">m webov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            rozhraní umístěn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho na webov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">stránce
            dostupn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">na internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">adrese
            www.vinarstvigertner.cz (dále je „internetový obchod“).</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;Ustanovení obchodních
            podmínek jsou nedílnou součástí kupní smlouvy. Odchylná ujednání v kupní
            smlouvě mají přednost před ustanoveními těchto obchodních podmínek.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">4.&nbsp;Znění obchodních podmínek
            může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a
            povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">5.&nbsp;Tyto obchodní podmínky a
            kupní smlouva se uzavírají v česk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m jazyce.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">II.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Informace o zboží a cenách</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;Informace o zboží, včetně
            uvedení cen jednotliv</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho zboží a jeho hlavních
            vlastností jsou uvedeny u jednotliv</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho zboží
            v katalogu internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho obchodu. Ceny zboží jsou
            uvedeny včetně daně z př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">idan</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">hodnoty,
            všech souvisejících poplatků a nákladů za vrácení zboží, jestliže toto zboží ze
            sv</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">podstaty nemůže bý</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">t vr</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áceno
            obvyklou kurýrní cestou. Ceny zboží zůstávají v platnosti po dobu, po kterou
            jsou zobrazovány v internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m
            obchodě. Toto ustanovení nevylučuje sjednání kupní smlouvy za individuálně
            sjednaných podmínek.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;Veškerá prezentace zboží
            umístěná v katalogu internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            obchodu je informativního charakteru a prodávající </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">nen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">í
            povinen uzavřít kupní smlouvu ohledně tohoto zboží.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;V internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m
            obchodě jsou zveřejněny informace o nákladech spojených s balením a dodáním
            zboží. Informace o nákladech spojených s balením a dodáním zboží uveden</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">v internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m obchodě
            platí pouze v případech, kdy je zboží doručováno v rámci území Česk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">republiky nebo Slovensk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">republiky</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">4.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            může po dohodě s kupujícím poskytnout dodatečnou individuální slevu na zboží.
            Tato sleva může být poskytnuta jednorázově na jeden nákup nebo kupujícímu
            prostřednictvím slevového voucheru, viz č</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">l. IV. P</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řípadn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">slevy z kupní ceny zboží nelze navzájem kombinovat, nedohodne-li
            se prodávající s kupujícím jinak.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">III.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Objednávka a uzavření kupní smlouvy</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
              se prostřednictvím eshopu www.vinarstvigertner.cz zabývá prodejem alkoholický</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch
              n</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ápojů. Z toho důvodu je podmínkou k uzavření kupní smlouvy
              dovršení věku 18-ti let.</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2. Náklady vznikl</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">kupujícímu př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">i pou</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">žití
            komunikačních prostředků </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">na d</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">álku v
            souvislosti s uzavřením kupní smlouvy (náklady na internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">připojení, náklady na telefonní hovory), hradí kupující sám.
            Tyto náklady se neliší od základní sazby.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;Kupující provádí
            objednávku zboží těmito způsoby:</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">telefonicky (721 873 340, 723 210 156)</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">e-mailem (</span><span lang="CS"><a href="mailto:info@vinasrtvigertner.cz"><span className="Hyperlink0"><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif'}}>info@vinasrtvigertner.cz</span></span></a></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">)</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vyplněním objednávkov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">ho formul</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áře
            bez registrace.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">4.&nbsp;Při zadávání objednávky
            si kupující vybere zboží, počet kusů zboží, způsob platby a doručení.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">5.&nbsp;Př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ed odesl</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áním
            objednávky je kupujícímu umožněno kontrolovat a měnit údaje, kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">do objednávky vložil. Objednávku odešle kupující prodávajícímu
            kliknutím na tlačítko Dokončit objednávku. Údaje uveden</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">v
            objednávce jsou prodávajícím považovány za správn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">.
            Podmínkou platnosti objednávky je vyplnění všech povinný</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">údajů
            v objednávkov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m formuláři a potvrzení kupujícího o tom, že se seznámil s
            těmito obchodními podmínkami.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">6.&nbsp;Neprodleně po obdržení
            objednávky zaš</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">le prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající kupujícímu potvrzení o obdržení
            objednávky na emailovou adresu, kterou kupující při objednání zadal. Toto
            potvrzení je automatick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">a nepovažuje se za uzavření
            smlouvy. Kupní smlouva je uzavřena až </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">po p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řijetí
            objednávky prodávajícím. </span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">7.&nbsp;V případě, že některý z
            požadavků uvedených v objednávce nemůže prodávající splnit, učiní kupujícímu
            pozměněnou nabídku, a to formou emailu nebo telefonick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            hovoru. Pozměněná nabídka se považuje za nový návrh kupní smlouvy a kupní
            smlouva je v takov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řípadě
            uzavřena potvrzením kupující</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">ho o p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řijetí t</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">to
            nabídky prodávajícímu na jeho emailovou adresu uvedenu v těchto obchodních
            podmínkách.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">8.&nbsp;Všechny objednávky přijat</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">prodávajícím jsou závazn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">.
            Kupující může zrušit objednávku, dokud není kupujícímu doručeno oznámení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>o p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řijetí
            objednávky prodávajícím. Kupující může zrušit objednávku telefonicky na
            telefonní číslo nebo email prodávajícího uvedený v těchto obchodních
            podmínkách.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">9.&nbsp;V případě, že došlo ke
            zjevn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">technick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">chybě na straně prodávajícího při
            uvedení ceny zboží v internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m
            obchodě, nebo v průběhu objednávání, není prodávající povinen dodat kupujícímu
            zboží za tuto zcela zjevně chybnou cenu ani v případě, že kupujícímu bylo
            zasláno automatick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">potvrzení o obdržení objednávky
            podle těchto obchodních podmínek. Prodávající informuje kupujícího o chybě bez
            zbytečn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho odkladu a zašle kupujícímu na jeho emailovou adresu
            pozměněnou nabídku. Pozměněná nabídka se považuje za nový návrh kupní smlouvy a
            kupní smlouva je v takov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řípadě
            uzavřena potvrzení</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m o p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řijetí kupujícím na
            emailovou adresu prodávající</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">ho.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">IV.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Platební podmínky a dodání zboží</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Cenu
              zboží a případn</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">náklady
              spojen</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">s dod</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áním
              zboží dle kupní smlouvy může kupující uhradit následujícími způsoby:</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">bezhotovostně převodem na bankovní úč</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">et
              prod</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávajícího č. 272899056/0300, vedený u Poštovní spořitelny a.s. a
              na základě potvrzení objednávky ze strany Prodávajícího a zaslání pokynů k
              platbě</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">dobírkou v hotovosti </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">p</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ředáv</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">á</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ní
              zboží dopravcem</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">v hotovosti nebo platební </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">kartou p</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ři
              osobním odběru </span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;Společně s kupní cenou je
            kupující povinen uhradit prodávajícímu náklady spojen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">s
            balením a dodáním zboží ve smluven</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">výši.
            Není-li dále uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady
            spojen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">s dod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áním zboží.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;V případě platby v
            hotovosti je kupní cena splatná př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">i p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řevzetí
            zboží. V případě bezhotovostní platby je kupní cena splatná do 5 dnů od
            uzavření kupní smlouvy</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">4.&nbsp;V případě bezhotovostní
            platby je závazek kupujícího uhradit kupní cenu splněn okamžikem připsání
            příslušné částky na bankovní úč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">et prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">ho.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">5.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            nepožaduje od kupujícího př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">edem </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">žádnou
            zálohu či jinou obdobnou platbu. Úhrada kupní ceny př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ed odesl</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áním
            zboží </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">nen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">í zálohou.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">6.&nbsp;Podle zákona o evidenci
            tržeb je prodávající povinen vystavit kupujícímu účtenku. Zároveň je povinen
            zaevidovat přijatou tržbu u správce daně online, v případě technick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            výpadku pak nejpozději do 48 hodin. Pokud zvolený způsob platby podl</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">há
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">EET, k</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="ES-TRAD">ó</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>d t</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">to
            transakce je uveden na příslušn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m da</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ňov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m
            dokladu.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">7.&nbsp;Zboží je kupujícímu dodá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">no:</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">na adresu určenou kupujícím v objednávce</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">osobním odběrem v provozovně prodávajícího</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span lang="CS">8</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">.&nbsp;Volba
            způsobu dodání se provádí během objednávání zboží.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">9.&nbsp;Zákazník má možnost
              vybrat si z těchto způ</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="ES-TRAD">sob</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ů
              dopravy:</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Přepravní společnost Toptrans (+ 100,-)</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Osobní odběr (Starý Poddvorov 239), po domluvě i jinde</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Bezhotovostně platební kartou</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">10.&nbsp;Náklady na dodání zboží
            v závislosti na způsobu odeslání a převzetí zboží jsou uvedeny v objednávce
            kupujícího a v potvrzení objednávky prodávajícím. V případě, že je způsob
            dopravy smluven na základě zvláštního požadavku kupujícího, nese kupující riziko
            a případn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">dodatečn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">náklady spojen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>s
            t</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ímto způsobem dopravy.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">11.&nbsp;Je-li prodávající podle
            kupní smlouvy povinen dodat zboží na mí</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">sto ur</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">čen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">kupujícím v objednávce, je kupující povinen převzít zboží při
            dodání. V případě, že je z důvodů na straně kupujícího nutno zboží doručovat
            opakovaně nebo jiným způsobem, než bylo uvedeno v objednávce, je kupující
            povinen uhradit náklady spojen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">s
            opakovaný</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m doru</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">čováním zboží</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">, resp. n</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áklady
            spojen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">s jiným způsobem doručení.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">12.&nbsp;Př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">i p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řevzetí
            zboží od přepravce je kupující povinen zkontrolovat neporušenost obalů zboží a
            v případě jakýchkoliv závad toto neprodleně ozná</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">mit p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řepravci.
            V případě shledání porušení obalu svědčícího o neoprávněn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m
            vniknutí do zásilky nemusí kupující zásilku od přepravce převzít.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">13.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            vystaví kupujícímu daňový doklad – fakturu. Na vyžádání je daňový doklad
            odeslán na emailovou adresu kupující</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">ho. </span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">14.&nbsp;Kupující nabývá
            vlastnick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">právo ke zboží zaplacení</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m cel</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">kupní
            ceny za zboží, včetně nákladů na dodání, nejdříve však převzetím zboží.
            Odpovědnost za nahodilou zkázu, poš</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">kozen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">í či
            ztrátu zboží př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ech</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ází na kupujícího okamžikem převzetí zboží
            nebo okamžikem, kdy měl kupující povinnost zboží převzít, ale v rozporu s kupní
            smlouvou tak neuč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">inil.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">V.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Ochrana osobní</span></b><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="DE">ch </span></b><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">údajů</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Informace
              o zákaznících (jm</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">no, p</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">říjmení,
              fakturační adresa, dodací adresa, e-mailová adresa, kontaktní telefon) jsou
              uchovávány dle Nařízení Evropsk</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
              parlamentu a Rady (EU) 2016/679 (zkráceně GDPR).</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS"> Slouží
            výhradně pro vnitřní potřebu společnosti Vinařství Gertner a nejsou poskytová</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>ny t</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řetím
            osobám - s výjimkou předání nezbytný</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">údajů
            kurýrním službám pro dodání zboží </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">a partner</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ům,
            podílejících se na provozu internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            obchodu www.vinarstvigertner.cz. Zákazník může požádat o výmaz či změnu osobní</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">údajů </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">z datab</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áze Vinařství Gertner
            e-mailem na adrese info@vinarstvigertner.cz, telefonicky na </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">+
            420 </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">721 873 340 nebo </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">+420 </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">723 210
            156</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;Kupující souhlasí se
            zpracováním osobní</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">údajů prodávajícím, a to
            pro účely realizace práv a povinností z kupní smlouvy a pro další zá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">konn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">důvody zpracování těchto údajů. Těmito zá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">konn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ý</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">mi
            d</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůvody jsou:</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 pí</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">sm.
            b) GDPR,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="IT">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">opr</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávněný zájem správce na poskytování přím</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            marketingu (zejm</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">na pro zasílání obchodních sdělení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>a
            newsletter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ů) podle čl. 6 odst. 1 pí</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">sm. f) GDPR,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Váš souhlas se zpracováním pro účely poskytování přím</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            marketingu (zejm</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">na pro zasílání obchodních sdělení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>a
            newsletter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2
            zákona č. 480/2004 Sb., o některých službá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch
            informa</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ční společnosti v případě, že nedošlo k objednávce zboží nebo
            služby.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Účelem zpracování osobní</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">údajů je:</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vyřízení Vaší objednávky a výkon práv a povinností vyplývajících
            ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou vyžadovány osobní
            údaje, kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">jsou nutn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">pro
            úspěšn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vyřízení objednávky (jm</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">no a
            adresa, kontakt), poskytnutí osobní</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">údajů je
            nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobní</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">údajů </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">nen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">í možn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">smlouvu uzavřít či jí ze strany správce plnit,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">zasílání obchodních sdělení a činění </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">dal</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ších
            marketingových aktivit</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;Kupující souhlasí s
            ukládáním tzv. cookies na jeho počítač. V případě, že je nákup na webov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">strá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">nce mo</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">žn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">prov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">st
            a závazky prodávajícího z kupní smlouvy plnit, aniž by docházelo k ukládání </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>tzv.
            cookies na po</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">čítač kupující</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">ho, m</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůže
            kupující souhlas podle předchozí věty kdykoliv odvolat.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">VI.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Odstoupení od smlouvy</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;Kupující, který uzavřel
            kupní smlouvu mimo svoji podnikatelskou činnost jako spotřebitel, má právo od
            kupní smlouvy odstoupit.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Lhůta pro
              odstoupení od smlouvy činí 14 dnů</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ode dne převzetí zboží,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ode dne převzetí poslední dodávky zboží, je-li předmětem smlouvy
            několik druhů zboží nebo dodání několika částí</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ode dne převzetí první dodávky zboží, je-li předmětem smlouvy
            pravidelná opakovaná dodávka zboží.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span lang="CS">3</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">.&nbsp;Kupující
            nemůže mimo jin</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">odstoupit od kupní smlouvy:</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">poskytování služeb, jestliže byly splněny s jeho předchozím
            výslovným souhlasem před uplynutí</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůty pro
            odstoupení od smlouvy a prodávající před uzavřením smlouvy sdělil kupujícímu,
            že v takov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řípadě nemá právo na odstoupení od smlouvy,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">o dodávce zboží nebo služby, jejichž cena závisí na výchylká</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="SV">ch
            finan</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">čního trhu nezávisle na vůli prodávajícího a k němuž může dojít
            bě</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">hem lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůty pro odstoupení od smlouvy,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">o dodání alkoholický</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch n</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ápojů,
            jež mohou bý</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">t dod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ány až po uplynutí třiceti dnů a jejichž cena
            závisí na výchylká</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="SV">ch finan</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">čního
            trhu nezávislých na vůli prodávající</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">ho,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">o dodávce zboží, kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">bylo
            upraveno podle přání kupujícího nebo pro jeho osobu,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">dodávce zboží v uzavřen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m obalu,
            kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">kupující z obalu vyňal a z hygienický</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch d</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůvodů
            jej není možn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vrátit,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">v dalších případech uvedených v § 1837 obč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">ansk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            zákoníku.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span lang="CS">4</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">.&nbsp;Pro
            dodržení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůty pro odstoupení od smlouvy musí kupující odeslat prohlášení o
            odstoupení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">ve lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůtě pro odstoupení od smlouvy.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">5.&nbsp;Pro odstoupení od kupní
            smlouvy může kupující využít vzorový </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">formul</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ář
            k odstoupení od smlouvy poskytovaný prodávajícím. Odstoupení od kupní smlouvy
            zašle kupující na emailovou nebo doručovací adresu prodávajícího uvedenou v
            těchto obchodních podmínkách. Prodávající potvrdí kupujícímu bezodkladně
            přijetí </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">formul</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ář</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">e.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">6.&nbsp;</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Kupující,
              který odstoupil od smlouvy, je povinen vrá</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">tit prod</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávajícímu
              zboží do 14 dnů od odstoupení od smlouvy prodávajícímu.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">
            Kupující </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">nese n</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áklady spojen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">s
            navrácením zboží prodávajícímu, a to i v tom případě, kdy zboží nemůže bý</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">t
            vr</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">áceno pro svou povahu obvyklou poštovní cestou.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">7.&nbsp;</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Odstoupí-li
              kupující od smlouvy, vrátí mu prodávající bezodkladně, nejpozději však do 14
              dnů od odstoupení od smlouvy, všechny peněžní prostředky včetně nákladů na
              dodání, kter</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">od něho
              přijal, a to stejným způsobem</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">. Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            vrátí kupujícímu přijat</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">peněžení prostředky jiným způsobem
            jen tehdy, pokud s tím kupující souhlasí a pokud mu tím nevzniknou další
            náklady.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">8.&nbsp;Jestliže kupující zvolil
            jiný, než nejlevnější způsob dodání zboží, který prodávající nabízí, vrátí
            prodávající kupujícímu náklady na dodání zboží ve výši odpovídající
            nejlevnějšímu nabízen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">mu způsobu dodání zboží.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">9.&nbsp;Odstoupí-li kupující od
            kupní smlouvy, není prodávající povinen vrá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">tit p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řijat</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">peněžní prostředky kupujícímu dří</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">ve, ne</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ž
            mu kupující zboží předá nebo prokáže, že zboží prodávajícímu odeslal.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">10.&nbsp;Zboží musí vrátit
            kupující prodávajícímu nepoš</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">kozen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">,
            neopotř</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">eben</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">a neznečištěn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">a je-li to možn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">, v
            původním obalu. Nárok na náhradu škody vznikl</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">na
            zboží je prodávající </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">opr</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávněn jednostranně
            započíst proti nároku kupujícího na vrácení kupní ceny.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">11.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            je oprávněn odstoupit od kupní smlouvy z důvodu vyprodání zásob, nedostupnosti
            zboží, anebo když výrobce, dovozce anebo dodavatel zboží př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">eru</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">š</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">il
            v</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ýrobu nebo dovoz zboží</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">. Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            bezodkladně informuje kupujícího prostřednictví emailov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">adresy
            uveden</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">v objednávce a vrátí </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">ve lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůtě 14
            dnů od oznámení o odstoupení od kupní smlouvy všechny peněžní prostředky včetně
            nákladů na dodání, kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">od něho na základě smlouvy
            přijal, a to stejným způsobem, popřípadě způsobem určeným kupujícím.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">VII.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Práva z vadn</span></b><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="FR">é</span></b><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">ho plnění</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            odpovídá kupujícímu, že zboží př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">i p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řevzetí
            nemá vady. Zejm</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">na prodávající odpovídá kupujícímu, že v době, kdy kupující
            zboží převzal:</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">má zboží vlastnosti, kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">si strany
            ujednaly, a chybí-li ujednání, má takov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vlastnosti,
            kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">prodávající nebo výrobce popsal nebo kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">kupující
            očekával s ohledem na povahu zboží a na základě reklamy jimi prováděn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">se zboží hodí k účelu, který pro jeho použití prodávající </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">uv</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ádí
            nebo ke kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">mu se zboží tohoto druhu obvykle používá,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">zboží odpovídá jakostí nebo provedením smluven</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">mu
            vzorku nebo předloze, byla-li jakost nebo provedení určeno podle smluven</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            vzorku nebo předlohy,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">je zboží v odpovídajícím množství, míře nebo hmotnosti a</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">zboží vyhovuje požadavků</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m pr</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávních
            předpisů.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Po
              převzetí objednan</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho zboží
              Kupující</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">m Prod</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající neručí </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">za
            </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">škody způsoben</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">nevhodným
              skladováním nebo zacházením se zbožím ze strany Kupujícího. Jako oprávněný
              nárok na reklamaci jsou uznány pouze prokazateln</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">senzorick</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
            </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">závady kvality vín, kter</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vznikly
              před jejich dodáním Kupujícímu, a to při navrácení většiny obsahu láhve.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">
            Ze strany dodavatele jsou vína skladovány v optimálních podmínkách, v
            klimatizovan</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m prostoru.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;Trvanlivost jednotlivých
            druhů vín je individuální</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">. Optim</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ální dobu
            spotřeby a případnou dobu archivace doporučujeme 2 roky. U nepotravinářských
            produktů (vinařsk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">doplňky - sklenice, karafy, bedýnky, kazety) se záruční doba
            řídí novelou obč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">ansk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho zákoníku a je stanovena na 24
            měsíců.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">4.</span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;V
              případě výskytu prokazateln</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vady může
              Kupující </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávajícímu předložit reklamaci a
              požadovat:</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">výměnu za nov</span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span></b><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">zboží,</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">přiměřenou slevu z kupní ceny,</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><b><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">odstoupit od smlouvy.</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">5.&nbsp;Kupující má právo
            odstoupit od smlouvy</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">pokud má zboží podstatnou vadu,</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">pokud nemůže věc řádně užívat pro opakovaný výskyt vady nebo vad</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '.5in', textIndent: '-.25in', lineHeight: 'normal'}}><span style={{fontSize: '10.0pt', fontFamily: 'Symbol', color: '#404040'}} lang="CS">·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">při větším počtu vad zboží</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">6.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            je povinen přijmout reklamaci v kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">koli
            provozovně, v níž je přijetí reklamace možn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">,
            případně i v sídle nebo místě podnikání</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">. Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            je povinen kupujícímu vydat písemn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">potvrzení
            o tom, kdy kupující právo uplatnil, co je obsahem reklamace a jaký způsob
            vyřízení reklamace kupující požaduje, jakož i potvrzení o datu a způsobu
            vyřízení reklamace, včetně potvrzení o provedení opravy a době jejího trvání,
            případně písemn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">odůvodnění zamítnutí reklamace.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">7.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            nebo jím pověřený pracovník rozhodne o reklamaci ihned, ve složitých případech
            do tří pracovních dnů. Do t</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">to lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůty
            se nezapočítává doba přiměřená podle druhu výrobku či služby potřebná k odborn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">mu
            posouzení vady. Reklamace včetně odstranění vady musí být vyřízena bezodkladně,
            nejpozději do 30 dnů ode dne uplatnění reklamace, pokud se prodávající s
            kupujícím nedohodne na delší </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůtě</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">.
            Marné </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">uplynutí t</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">to lh</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůty
            se považuje za podstatn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">porušení smlouvy a kupující má
            právo od kupní smlouvy odstoupit. Za okamžik uplatnění reklamace se považuje
            okamžik, kdy dojde projev vůle kupujícího (uplatnění práva z vadn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            plnění) prodávajícímu.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">8.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            písemně informuje kupující</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">ho o v</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ýsledku
            reklamace.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">9.&nbsp;Právo z vadn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            plnění kupujícímu nenáleží, pokud kupující př</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>ed p</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řevzetím
            věci věděl, že věc má vadu, anebo pokud kupující vadu sám způsobil.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">10.&nbsp;V případě </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">opr</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávněn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">reklamace má kupující právo na náhradu účelně vynaložený</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">ch
            n</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ákladů vzniklých v souvislosti s uplatněním reklamace. Toto prá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">vo
            m</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ůže kupující u prodávajícího uplatnit ve lhůtě do jednoho měsíce
            po uplynutí záruční doby.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">11.&nbsp;Volbu způsobu reklamace
            má kupující.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">12.&nbsp;Práva a povinnosti
            smluvních stran ohledně práv z vadn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho plnění
            se řídí § 1914 až 1925, § 2099 až 2117 a § 2161 až 2174 obč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">ansk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            zákoníku a zákonem č. 634/1992 Sb., o ochraně </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>spot</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řebitele.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">13.&nbsp;Další práva a povinnosti
            stran související s odpovědností prodávajícího za vady upravuje reklamační řá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>d prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">ho.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">VIII.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Doručování</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;Smluvní strany si mohou
            veškerou písemnou korespondenci vzájemně doručovat prostřednictvím elektronick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">pošty.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;Kupující doručuje
            prodávajícímu korespondenci na emailovou adresu uvedenu v těchto obchodních
            podmínkách. Prodávající doručuje kupujícímu korespondenci na emailovou adresu
            uvedenou v jeho zákaznick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m účtu
            nebo v objednávce.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">IX.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Mimosoudní řešení sporů</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;K mimosoudnímu řešení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>spot</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řebitelských
            sporů z kupní smlouvy je příslušná Česká obchodní inspekce, se sídlem Štěpá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">nsk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">á
            567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa:
            https://adr.coi.cz/cs. Platformu pro řešení sporů </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">on-line
            nach</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ázející se na internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">adrese
            http://ec.europa.eu/consumers/odr je mo</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">žn</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">využít
            při řešení sporů mezi prodávajícím a kupujícím z kupní smlouvy.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;Evropsk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>spot</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řebitelsk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">centrum Česká republika, se sídlem Štěpá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">nsk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">á
            567/15, 120 00 Praha 2, internetová adresa: http://www.evropskyspotrebitel.cz
            je kontaktním místem podle Nařízení Evropsk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            parlamentu a Rady (EU) č. 524/2013 ze dne 21. května 2013 o řešení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>spot</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řebitelských
            sporů on-line a o změně nařízení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">(ES) </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">č.
            2006/2004 a směrnice 2009/22/ES (nařízení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>o </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řešení </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>spot</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řebitelských
            sporů on-line).</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            je oprávněn k prodeji zboží na základě živnostensk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            oprávnění. Živnostenskou kontrolu provádí v rámci sv</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">působnosti
            příslušný živnostenský úřad. Česká obchodní inspekce vykonává ve vymezen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">m
            rozsahu mimo jin</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>spot</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">řebitele.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal'}} align="center"><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">X.</span></b><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><b><span style={{fontSize: '12.0pt', fontFamily: '"Arial",sans-serif', color: 'maroon'}} lang="CS">Závěrečná ustanovení</span></b></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1.&nbsp;Veškerá ujednání mezi
            prodávajícím a kupujícím se řídí právním řá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">dem </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Česk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">republiky. Pokud vztah založený kupní smlouvou obsahuje
            mezinárodní prvek, pak strany sjednávají, že vztah se řídí prá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">vem
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Česk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">republiky. Tímto nejsou dotčena
            práva spotřebitele vyplývající z obecně závazných právních předpisů.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">nen</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">í ve vztahu ke kupujícímu vázán žádnými
            kodexy chování ve smyslu ustanovení § 1826 odst. 1 písm. e) obč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">ansk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            zákoníku.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">3.&nbsp;Všechna práva k webovým
            stránká</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávajícího, zejm</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">na
            autorská práva k obsahu, včetně rozvržení stránky, fotek, filmů, grafik,
            ochranných známek, loga a dalšího obsahu a prvků, náleží prodávajícímu. Je
            zakázáno kopírovat, upravovat nebo jinak použí</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="NL">vat webov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">stránky nebo jejich část bez souhlasu prodávající</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="IT">ho.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">4.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DE">Prod</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ávající
            nenese odpovědnost za chyby vznikl</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">v
            důsledku zásahů třetích osob do internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            obchodu nebo v důsledku jeho užití v rozporu s jeho určením. Kupující nesmí při
            využívání internetov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho obchodu používat postupy, kter</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">by mohly mít negativní vliv na jeho provoz a nesmí vykonávat
            žádnou činnost, která by mohla jemu nebo třetím osobá</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="PT">m umo</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">žnit
            neoprávněně zasahovat či neoprávněně užít programov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vybavení
            nebo další </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">sou</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">části tvořící internetový obchod a užívat
            internetový obchod nebo jeho části č</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}}>i softwarov</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">vybavení
            takovým způsobem, který by byl v rozporu s jeho určením či účelem.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">5.&nbsp;Kupující tímto přebírá na
            sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 obč</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="DA">ansk</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">ho
            zákoníku.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">6.&nbsp;Kupní smlouva včetně
            obchodních podmínek je archivována prodávajícím v elektronick</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="FR">é
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">podobě a není přístupná.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">7.&nbsp;Znění obchodních podmínek
            může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a
            povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial Unicode MS",sans-serif', color: '#404040'}} lang="CS"><br />
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">Tyto obchodní podmínky nabývají účinnosti dnem </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">13</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">.
          </span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">2</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">. 202</span><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">1</span></p>
                        <p className="MsoNormal" style={{marginTop: '5.0pt', marginRight: '0in', marginBottom: '5.0pt', marginLeft: '0in', lineHeight: 'normal'}}><span style={{fontSize: '10.5pt', fontFamily: '"Arial",sans-serif', color: '#404040'}} lang="CS">&nbsp;</span></p>
                    </div>

                </Modal.Body>
            </Modal>
        )
    }
}
