import React, {Component} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import './../GlobalAdmin.scss';

export default class VoucherPopUpComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            code: '',
            price: '',
            active: '',
            isNameValid: false,
            isCodeValid: false,
            isPriceValid: false,
            isError: false,
            voucherType: props.voucherConstants[0].key,
            voucherLabel: 'Sleva v %',
        }

    }

    nameValidation = (name) => {
        return name.length > 3 && name.length < 15;
    };

    codeValidation = (code) => {
        return code.length > 6 && code.length < 15;
    };

    priceValidation = (price) => {
        return price > 0 && price <= 1000;
    };

    handleNameChange = (event) => {
      this.setState({
          name: event.target.value,
          isNameValid: this.nameValidation(event.target.value),
      });
    };

    handleCodeChange = (event) => {
        this.setState({
            code: event.target.value,
            isCodeValid: this.codeValidation(event.target.value)
        })
    };

    handlePriceChange = (event) => {
        this.setState({
            price: event.target.value,
            isPriceValid: this.priceValidation(event.target.value)
        });
    };

    handleOnSaveClick = () => {
        const {
            code,
            price,
            name,
            active,
            voucherType,
            isNameValid,
            isPriceValid,
            isCodeValid
        } = this.state;
        const { addNewVoucherMethod, getAllVouchersMethod } = this.props;
        if(isNameValid && isPriceValid && isCodeValid) {
            this.setState({
                isError: false,
            });
            const voucher = {
                name: name,
                code: code,
                price: price,
                active: active,
                typeOfVoucher: voucherType,
            };
            addNewVoucherMethod(voucher).then(() => {
                this.props.handleClose();
                getAllVouchersMethod();
            });
        } else {
            this.setState({
                isError: true,
            })
        }
    };

    handleActiveChange = (event) => {
      this.setState({
          active: event.target.checked,
      });
    };

    handleChangeTypeVoucher = (event) => {
        let label = 'Sleva v %';
        if(event.target.id === 'price') {
            label = 'Sleva v Kč';
        }
        this.setState({
            voucherType: event.target.id,
            voucherLabel: label
        });
    };

    render() {
        const { isShow, handleClose, voucherConstants } = this.props;
        return (
            <div>
                <Modal show={isShow} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Nový voucher
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Jméno</Form.Label>
                                <Form.Control isInvalid={!this.state.isNameValid}
                                              id="form-horizontal-text"
                                              type="text"
                                              placeholder="Jmeno" value={this.state.name} name="name"
                                              onChange={this.handleNameChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Kod voucheru</Form.Label>
                                <Form.Control isInvalid={!this.state.isCodeValid}
                                              id="form-horizontal-text"
                                              type="text"
                                              placeholder="Kod voucheru" value={this.state.code} name="code"
                                              onChange={this.handleCodeChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Typ voucheru</Form.Label>
                                {
                                    voucherConstants.map(voucherConst => (
                                            <Form.Check
                                                type='radio'
                                                label={voucherConst.value}
                                                id={voucherConst.key}
                                                checked={voucherConst.key === this.state.voucherType}
                                                onChange={this.handleChangeTypeVoucher}
                                            />
                                        )
                                    )
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Sleva</Form.Label>
                                <Form.Control isInvalid={!this.state.isPriceValid}
                                              id="form-horizontal-text"
                                              type="number"
                                              placeholder={this.state.voucherLabel} value={this.state.price} name="price"
                                              onChange={this.handlePriceChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="form-horizontal-text">Aktivní</Form.Label>
                                <Form.Check type="checkbox"
                                            id="form-horizontal-text"
                                            placeholder="Link" value={this.state.active} name="active"
                                            onChange={this.handleActiveChange}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.isError &&
                            <p className="error-validation">
                                Chybně vyplněné údaje
                            </p>
                        }
                            <Button variant='outline-secondary' onClick={handleClose}>
                                Zrušit
                            </Button>
                            <Button variant='outline-success' onClick={this.handleOnSaveClick}>
                                Uložit
                            </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
