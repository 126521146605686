export const GET_ALL_VOUCHERS_STARTED = 'GET_ALL_VOUCHERS_STARTED';
export const GET_ALL_VOUCHERS_SUCCESS = 'GET_ALL_VOUCHERS_SUCCESS';
export const GET_ALL_VOUCHERS_FAILURE = 'GET_ALL_VOUCHERS_FAILURE';
export const CHANGE_VOUCHER_ACTIVITY_STARTED = 'CHANGE_VOUCHER_ACTIVITY_STARTED';
export const CHANGE_VOUCHER_ACTIVITY_SUCCESS = 'CHANGE_VOUCHER_ACTIVITY_SUCCESS';
export const CHANGE_VOUCHER_ACTIVITY_FAILURE = 'CHANGE_VOUCHER_ACTIVITY_FAILURE';
export const DELETE_VOUCHER_STARTED = 'DELETE_VOUCHER_STARTED';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAILURE = 'DELETE_VOUCHER_FAILURE';
export const ADD_NEW_VOUCHER_STARTED = 'ADD_NEW_VOUCHER_STARTED';
export const ADD_NEW_VOUCHER_SUCCESS = 'ADD_NEW_VOUCHER_SUCCESS';
export const ADD_NEW_VOUCHER_FAILURE = 'ADD_NEW_VOUCHER_FAILURE';
