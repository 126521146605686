import React, {Component} from "react";
import './ProductFullDetailComponent.scss';
import LoadingDashboardComponent from "../../../../common/LoadingDashboardComponent";
import ProductIteratorComponent from "../../../common/ProductIteratorComponent";
import GertnerButtonComponent from "../../../../common/GertnerButtonComponent";
import {getImageProductPathByProduct} from "../../../../../admin/common/utils/BasicUtils";
import {Image} from "react-bootstrap";

const colorMap = {
    red: "Červená vína",
    white: "Bílá vína",
    rose: "Růžová vína",
};

export default class ProductFullDetailComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countBottle: 1,
            computedPrice: 0,
            buttonText: 'cena',
            imageSrc: null,
        };
    }

    componentDidMount() {
        const { getProductById, productId } = this.props;
        getProductById(productId).then(() => {
            this.setState({
                computedPrice: this.props.selectedProduct.price,
                buttonText: this.props.selectedProduct.price+' Kč',
            })
        });
    }

    renderColor(color) {
        return (
            <div className='product-full-container__color'>
                {colorMap[color]}
            </div>
        );
    }

    handleMinus = (price) => {
      const { countBottle } = this.state;
      if(countBottle !== 0 ) {
          this.setState({
              countBottle: countBottle-1,
              computedPrice: price*(countBottle-1),
              buttonText: price*(countBottle-1)+' Kč',
          });
      }
    };

    handlePlus = (price) => {
        const { countBottle } = this.state;
        this.setState({
            countBottle: countBottle+1,
            computedPrice: price*(countBottle+1),
            buttonText: price*(countBottle+1)+' Kč',
        });
    };

    handleMouseEnter = () => {
        this.setState({
            buttonText: `Koupit za ${this.state.computedPrice} Kč`,
        });
    };

    handleMouseLeave = () => {
        this.setState({
            buttonText: `${this.state.computedPrice} Kč`,
        })
    };

    async addToCart(selectedProduct, countBottle, computedPrice) {
        const { addToShoppingCart } = this.props;
        await addToShoppingCart(selectedProduct, countBottle, computedPrice);
    }

    handleClickAddToShoppingCart = () => {
      const { selectedProduct, recountShoppingCart } = this.props;
      const { countBottle, computedPrice } = this.state;
        this.addToCart(selectedProduct, countBottle, computedPrice).then(() =>{
            recountShoppingCart();
        });
    };

    handleErrorImage = () => {
        this.setState({
            imageSrc: `/images/products/${this.props.selectedProduct.color}.png`,
        });
    };

    renderParams(product) {
        return (
            <div className='product-full-container__params'>
                {
                    product.sugar && (
                        <div>
                            <div className='product-full-container__params--label'>
                                Zbytkový cukr
                            </div>
                            <div className='product-full-container__params--value'>
                                {product.sugar} g/l
                            </div>
                        </div>
                    )
                }
                {
                    product.acid && (
                        <div>
                            <div className='product-full-container__params--label'>
                                Kyseliny
                            </div>
                            <div className='product-full-container__params--value'>
                                {product.acid} g/l
                            </div>
                        </div>
                    )
                }
                {
                    product.alcohol && (
                        <div>
                            <div className='product-full-container__params--label'>
                                Alkohol
                            </div>
                            <div className='product-full-container__params--value'>
                                {product.alcohol} %
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    renderMedals(selectedProduct){
        if(selectedProduct.medal && selectedProduct.medal.length > 0) {
            return (
                <div className='product-full-container__medals'>
                    <ul>
                    {
                        selectedProduct.medal.map(medalItem => {
                            return (
                                <li>{medalItem.type}<br/>
                                    <b>{medalItem.description}</b>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    render() {
        const { isLoading, error, selectedProduct } = this.props;
        const { imageSrc } = this.state;
        if(isLoading || !selectedProduct) {
            return (
                <LoadingDashboardComponent />
            );
        } else if(error) {
            return (
                <></>
            );
        } else {
            const price = selectedProduct.price;
            return (
                <div className='product-full-container'>
                    <div className='product-full-container__image'>
                        <Image src={imageSrc ? imageSrc : getImageProductPathByProduct(selectedProduct)}
                             onError={this.handleErrorImage}
                        />
                    </div>
                    <div className='product-full-container__description'>
                        {this.renderColor(selectedProduct.color)}
                        <div className='product-full-container__name'>
                            {selectedProduct.name}
                        </div>
                        <div className='product-full-container__vintage'>
                            {selectedProduct.vintage}/{selectedProduct.type}
                        </div>
                        <div className='product-full-container__category'>
                            <div className='product-full-container__category--label'>
                                Víno s přívlastkem
                            </div>
                            <div className='product-full-container__category--value'>
                                {selectedProduct.category}
                            </div>
                        </div>
                        <div className='product-full-container__text'>
                            {selectedProduct.description}
                        </div>
                        <div className='product-full-container__iterator'>
                            <ProductIteratorComponent
                                onMinus={() => this.handleMinus(price)}
                                onPlus={() => this.handlePlus(price)}
                            />
                        </div>
                        <div className='product-full-container__button'>
                            <GertnerButtonComponent
                                handleClick={this.handleClickAddToShoppingCart}
                                handleMouseEnter={this.handleMouseEnter}
                                handleMouseLeave={this.handleMouseLeave}
                                text={this.state.buttonText} />
                        </div>
                        <div className='product-full-container__info'>
                            {this.renderParams(selectedProduct)}
                            {this.renderMedals(selectedProduct)}
                        </div>
                    </div>
                </div>
            );
        }
    }

}
