import React, {Component} from 'react';
import './EventItemComponent.scss';
import moment from "moment";

export default class EventItemComponent extends Component {

    formatDate(date) {
        return moment(date).format('D.M.YYYY');
    }

    render() {
        const { event } = this.props;

        return (
          <div className='event-item-container'>
              <span className='event-item-container__title'>
                  {
                      event.link ?
                      <a href={event.link}>{event.title}</a> :
                      <a>{event.title}</a>
                  }
              </span>
              <span className='event-item-container__date'>{this.formatDate(event.eventDate)}</span>
              { event.venue &&
                <span className='event-item-container__venue'>{event.venue}</span>
              }
              <div className='event-item-container__text'>{event.text}</div>
          </div>
        );
    }

}
