import React, {Component} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {
    validateDescription,
    validatePrice,
    validateProductName,
    validateVintage,
} from "./ProductValidatorUtil";
import './ProductPopUpComponent.scss';
import './../GlobalAdmin.scss';

export default class ProductPopUpComponent extends Component {

    constructor(props){
        super(props);
        if(props.isAdding){
            this.state = {
                name: '',
                vintage: '',
                category: props.categories[0].value,
                kind: props.kinds[0].value,
                price: '',
                description: '',
                available: false,
                color: props.colors[0].key,
                sugar: '',
                acid: '',
                alcohol: '',
                isNameValid: false,
                isVintageValid: false,
                isDescriptionValid: false,
                isPriceValid: false,
                isError: false,
            }
        } else {
            this.state = {
                name: props.product.name || '',
                vintage: props.product.vintage || '',
                category: props.product.category || props.categories[0].value,
                price: props.product.price || '',
                description: props.product.description || '',
                available: props.product.available || false,
                color: props.product.color || props.colors[0].value,
                kind: props.product.type || props.kinds[0].value,
                sugar: props.product.sugar || '',
                acid: props.product.acid || '',
                alcohol: props.product.alcohol || '',
                isNameValid: true,
                isVintageValid: true,
                isDescriptionValid: true,
                isPriceValid: true,
                isError: false,
            }
        }
    }

    handleNameChange = (event) => {
          this.setState(
              {name: event.target.value, isNameValid: validateProductName(event.target.value)}
          );
    };

    handleVintageChange = (event) => {
        const { value } = event.target;
        this.setState({
            vintage: value,
            isVintageValid: validateVintage(value)
        });
    };

    handleDescriptionChange = (event) => {
        const { value } = event.target;
        this.setState({
            description: value,
            isDescriptionValid: validateDescription(value)
        })
    };

    handlePriceChange = (event) => {
      const { value } = event.target;
      this.setState({
         price: value,
         isPriceValid: validatePrice(value)
      });
    };

    handleColorChange = (event) => {
        const { value } = event.target;
        this.setState({
            color: value,
        });
    };

    handleAvailableChange = (event) => {
        this.setState({
            available: event.target.checked,
        });
    };

    handleSugarChange = (event) => {
        this.setState({
            sugar: event.target.value,
        });
    };

    handleAcidChange = (event) => {
        this.setState({
            acid: event.target.value
        });
    };

    handleAlcoholChange = (event) => {
        this.setState({
            alcohol: event.target.value
        });
    };

    updateAndGetAll =  (id, product) => {
        const { updateProductMethod, getProducts, isAdding, addNewProductMethod } = this.props;
        if(isAdding) {
            addNewProductMethod(product).then(() => {
                getProducts();
            });
        } else {
            updateProductMethod(id, product).then(() => {
                getProducts();
            });
        }
    };

    handleOnSaveClick = (event) => {
        const { isAdding } = this.props;
        if(isAdding) {
            this.addNewProduct(event);
        } else {
            this.editProduct(event);
        }

    };

    handleCategoryChange = (event) => {
        this.setState({
            category: event.target.value,
        });
    };

    handleKindChange = (event) => {
        this.setState({
            kind: event.target.value,
        });
    };

    addNewProduct = (event) => {
      const { handleClose } = this.props;
      event.preventDefault();
      const { isDescriptionValid, isNameValid, isVintageValid, isPriceValid } = this.state;
        if( isDescriptionValid && isNameValid && isVintageValid && isPriceValid) {
            this.setState({isError: false});
            const product = {
                name: this.state.name,
                price: this.state.price,
                vintage: this.state.vintage,
                description: this.state.description,
                category: this.state.category,
                type: this.state.kind,
                available: this.state.available,
                color: this.state.color,
                sugar: this.state.sugar,
                acid: this.state.acid,
                alcohol: this.state.alcohol,
            };
            this.updateAndGetAll(null, product);
            handleClose();
        } else {
            this.setState({
                isError: true,
            })
        }
    };

    editProduct = (event) => {
        const { product, handleClose } = this.props;
        const { id, amount } = product;
        event.preventDefault();
        const { isDescriptionValid, isNameValid, isVintageValid, isPriceValid } = this.state;
        if(isDescriptionValid && isNameValid && isVintageValid && isPriceValid) {
            this.setState({isError: false});
            const product = {
                id: id,
                name: this.state.name,
                price: this.state.price,
                vintage: this.state.vintage,
                amount: amount,
                description: this.state.description,
                category: this.state.category,
                type: this.state.kind,
                available: this.state.available,
                color: this.state.color,
                sugar: this.state.sugar,
                alcohol: this.state.alcohol,
                acid: this.state.acid,
            };
            this.updateAndGetAll(id, product);
            handleClose();
        } else {
            this.setState({
                isError: true,
            });
        }
    };

    render() {
        const { isShow, handleClose, isAdding, categories, colors, kinds } = this.props;
        const productFromProps = this.props.product;
        return(
            <div className="product-popup-container">
                <Modal show={isShow} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                isAdding ?
                                    'Novy Produkt' :
                                    `${productFromProps.name} ${productFromProps.vintage}`
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                        <Form.Group>
                            <Form.Label htmlFor="form-horizontal-text">Nazev vina</Form.Label>
                            <Form.Control isInvalid={!this.state.isNameValid}
                                          id="form-horizontal-text"
                                          type="text"
                                          placeholder="Nazev" value={this.state.name} name="name"
                                          onChange={(event) => this.handleNameChange(event)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="vintage">Rocnik</Form.Label>
                            <Form.Control isInvalid={!this.state.isVintageValid}
                                          id="vintage"
                                          type="number"
                                          placeholder="Rocnik" value={this.state.vintage}
                                          onChange={this.handleVintageChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="form-horizontal-text">Popis</Form.Label>
                            <Form.Control as='textarea'
                                          isInvalid={!this.state.isDescriptionValid}
                                          id="form-horizontal-text" rows={8}
                                          placeholder="popis" value={this.state.description}
                                          onChange={this.handleDescriptionChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="form-horizontal-text">Cena</Form.Label>
                            <Form.Control isInvalid={!this.state.isPriceValid}
                                          id="form-horizontal-text"
                                          type="number"
                                          placeholder="Cena" value={this.state.price}
                                          onChange={this.handlePriceChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="form-horizontal-text">Typ</Form.Label>
                                <Form.Control as='select' onChange={this.handleKindChange}>
                                    {
                                        kinds.map(kind => {
                                            return (
                                                <option name={kind.key}
                                                        selected={kind.value === this.state.kind}
                                                >
                                                    {kind.value}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="form-horizontal-text">Kategorie</Form.Label>
                            <Form.Control as='select' onChange={this.handleCategoryChange}>
                                {
                                    categories.map(category => {
                                        return (
                                            <option name={category.key}
                                                    key={category.key}
                                                    selected={category.value === this.state.category}
                                            >
                                                {category.value}
                                            </option>
                                        );
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="form-horizontal-text">Barva</Form.Label>
                            <Form.Control as='select' onChange={this.handleColorChange} >
                                {
                                    colors.map(color => {
                                        return (
                                            <option value={color.key}
                                                    selected={this.state.color === color.key}
                                            >
                                                {color.value}
                                            </option>
                                        );
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="sugar">Zbytkovy cukr</Form.Label>
                            <Form.Control id="sugar" type="number" step="0.01"
                                   placeholder="Cukr" value={this.state.sugar}
                                   onChange={this.handleSugarChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="acid">Kyselinka</Form.Label>
                            <Form.Control id="acid"
                                          step="0.01"
                                          type="number"
                                          placeholder="Kyselinka" value={this.state.acid}
                                          onChange={this.handleAcidChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="alcohol">Alkohol</Form.Label>
                            <Form.Control id="alcohol"
                                          step="0.01"
                                          type="number"
                                          placeholder="Alkohol" value={this.state.alcohol}
                                          onChange={this.handleAlcoholChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="form-horizontal-text">Dostupnost</Form.Label>
                            <Form.Check type="checkbox"
                                        checked={this.state.available}
                                        onChange={this.handleAvailableChange}
                            />
                        </Form.Group>
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.isError &&
                                <p className="error-validation">
                                    Chybně vyplněné údaje
                                </p>
                        }
                            <Button variant="outline-secondary" onClick={handleClose}>
                                Zrušit
                            </Button>
                            <Button variant="outline-success" onClick={this.handleOnSaveClick}>
                                Uložit
                            </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
