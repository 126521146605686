import ProductOperation from '../../duck/operation'
import {connect} from "react-redux";
import TopThreeProductComponent from "./TopThreeProductComponent";

const mapStateToProps = (state) => {
    const { topThreeProducts, isLoading } = state.productsDashboard;
    return { topThreeProducts, isLoading };
};

const mapDispatchToProps = (dispatch) => {

    const getTopThreeProducts = () => {
        dispatch(ProductOperation.getTopThreeProductsAction());
    };

    return {
        getTopThreeProducts
    }
};

const TopThreeProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopThreeProductComponent);


export default TopThreeProductsContainer;
