import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NavBarContainer from "../common/NavBarContainer";
import OrdersContainer from "../orders/OrdersContainer";
import ProductsContainer from "../products/ProductContainer";
import ToastContainer from "../common/toast/ToastContainer";
import "./DashboardComponent.scss";
import MedalAdminContainer from "../actualities/MedalAdminContainer";
import OrderStatisticsContainer from "../orders/statistics/OrderStatisticsContainer";
import EventAdminContainer from "../events/EventAdminContainer";
import VoucherAdminContainer from "../vouchers/VoucherAdminContainer";
import OrdersHistoryContainer from "../orders/order_history/OrdersHistoryContainer";

class DashboardComponent extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        const { getAllConstants } = this.props;
        getAllConstants();
    }

    render() {
        const { showToast } = this.props;
        let toastClassName = 'toast-container';
        if(!showToast) {
            toastClassName = 'toast-container toast-container__hidden';
        }
        return(
          <div className='admin-dashboard'>
              <div className={toastClassName}>
                  <ToastContainer/>
              </div>
              <Router>
                <NavBarContainer />
                    <Switch>
                        <Route
                            path='/admin/orders'
                            component={OrdersContainer}
                        />
                        <Route
                            path='/admin/history'
                            component={OrdersHistoryContainer}
                        />
                        <Route
                            path='/admin/products'
                            component={ProductsContainer}
                        />
                        <Route
                            path='/admin/medals'
                            component={MedalAdminContainer}
                        />
                        <Route
                            path='/admin/stats'
                            component={OrderStatisticsContainer}
                        />
                        <Route
                            path='/admin/events'
                            component={EventAdminContainer}
                        />
                        <Route
                            path='/admin/vouchers'
                            component={VoucherAdminContainer}
                        />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default DashboardComponent;
