import React, {Component} from "react";
import './ProductCartIteratorComponent.scss';

export default class ProductCartIteratorComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countBottle: props.count,
        };
    }

    derivedBottleName(count) {
        if (count === 0 || count > 4) {
            return 'lahví';
        } else if (count === 1) {
            return 'láhev';
        } else if (count === 2 || count === 3 || count === 4) {
            return 'láhve';
        }
    }

    handleMinus = () => {
        this.props.onMinus();
        if (this.state.countBottle !== 0) {
            this.setState({
                countBottle: this.state.countBottle - 1,
            })
        }
    };

    handlePlus = () => {
        this.props.onPlus();
        this.setState({
            countBottle: this.state.countBottle + 1,
        });
    };

    handleChangeCountByInput = (newCount) => {

        const count = newCount === "" ? newCount : parseInt(newCount);

        if(!isNaN(count)) {
            this.props.onChange(count);
        }

        this.setState({
            countBottle: count
        });

    }

    render() {
        const {count} = this.props;
        const {countBottle} = this.state;
        return (
            <div className='product-cart-iterator-container'>
                <button className='product-cart-iterator-container__btn' onClick={this.handleMinus}>-</button>
                <input className='product-cart-iterator-container__input'
                       pattern="[0-9]*"
                       size={1}
                       type={"number"}
                       min={0}
                       value={count}
                       onChange={e => this.handleChangeCountByInput(e.target.value)}/>
                <span className='product-cart-iterator-container__text'>{this.derivedBottleName(countBottle)}</span>
                <button className='product-cart-iterator-container__btn' onClick={this.handlePlus}>+</button>
            </div>
        );
    }

}
