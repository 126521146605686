import React from "react";
import {Link} from "react-router-dom";
import logo from './../../../assets/images/eshop/logo-gertner-horizontalni.svg';
import {Image} from "react-bootstrap";

export function EshopLinkComponent() {
    return (
        <div className='eshop-header-container__link'>
            <Link to={'/'}>
                <Image width={218} height={90} className='eshop-header-container__logo'
                     src={logo} />
            </Link>
        </div>
    )
}
