import React, {PureComponent} from "react";
import './OurPlaceInfo.scss';

export default class OurPlaceInfo extends PureComponent {

    render() {
        return (
            <div className="our-place-container">
                <div className='our-place-container__grey-bcg'>
                    {/*<Image className="our-place-container__cross-image" src="/images/x-bile.svg"/>*/}
                    <div className="our-place-container__text">
                        <span className="our-place-container__title">Odkud jedeme?</span>
                        <div className="our-place-container__description">
                            Na etiketách našich vín uvidíte kříž – zvolili jsme jej jako symbol místa, kde se naše vína rodí, Starého Poddvorova.
                            Připomíná lopatky starého větrného mlýna, podle kterého Poddvorov zdálky poznáte.
                            Na křížovém půdorysu vznikl i samotný Poddvorov.
                        </div>
                    </div>
                </div>
                <div className="our-place-container__mill">

                </div>
            </div>
        );
    }

}
