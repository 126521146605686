import {
    GET_TYPES_DELIVERY_FAILURE,
    GET_TYPES_DELIVERY_SUCCESS,
    GET_TYPES_DELIVERY_STARTED,
    SELECT_TYPE_DELIVERY,
    SUBMIT_ORDER_STARTED,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_FAILURE,
    CLEAN_COMPLETED_ORDER,
    GET_PAYMENT_METHOD_STARTED,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_FAILURE, SELECT_PAYMENT_METHOD,
} from "./type";
import {countNoVatSum, recountShoppingCart} from "../../utils/EshopUtils";

const initialState = {
    typesDelivery: [],
    paymentMethods: [],
    order: {},
    isLoading: false,
    completedOrder: null,
};


const orderConfirmationReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TYPES_DELIVERY_STARTED : {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_TYPES_DELIVERY_SUCCESS : {
            return {
                ...state,
                typesDelivery: action.payload.delivery,
                isLoading: false,
            }
        }

        case GET_TYPES_DELIVERY_FAILURE : {
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        }

        case GET_PAYMENT_METHOD_STARTED : {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_PAYMENT_METHOD_SUCCESS : {
            return {
                ...state,
                paymentMethods: action.payload.methods,
                isLoading: false,
            }
        }

        case GET_PAYMENT_METHOD_FAILURE : {
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        }

        case SELECT_TYPE_DELIVERY : {
            return {
                ...state,
                order: {
                    ...state.order,
                    typeDelivery: {
                        id: action.delivery.id,
                    }
                }
            }
        }

        case SELECT_PAYMENT_METHOD : {
            return {
                ...state,
                order: {
                    ...state.order,
                    paymentMethod: {
                        id: action.paymentMethod.id,
                    }
                }
            }
        }

        case SUBMIT_ORDER_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case SUBMIT_ORDER_SUCCESS : {
            const sum = recountShoppingCart(action.order.product, action.order.voucher);
            const sumNoVatWithDelivery = sum.sumNoVat + countNoVatSum(action.order.typeDelivery.price);
            return {
                ...state,
                completedOrder: {
                    ...action.order,
                    sumNoVat: sumNoVatWithDelivery,
                },
                error: null,
                isLoading: false,
            }
        }

        case SUBMIT_ORDER_FAILURE : {
            return {
                ...state,
                completedOrder: null,
                error: action.error.message,
            }
        }

        case CLEAN_COMPLETED_ORDER : {
            return {
                ...state,
                completedOrder: null,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default orderConfirmationReducer;
