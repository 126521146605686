
export const recountShoppingCart = (products, voucher) => {
    let sum = {
        sumNoVat: 0,
        sumWithVat: 0,
    };
    products.forEach(product => {
        sum.sumWithVat += product.price
    });
    sum.sumNoVat = countNoVatSum(sum.sumWithVat);
    if(voucher && voucher.price > 0) {
        sum.sumNoVat = countNoVatSum(sum.sumWithVat);
        if(voucher.typeOfVoucher === 'price') {
            sum.sumNoVat = sum.sumNoVat - voucher.price;
        } else {
            sum.sumNoVat = Math.round(sum.sumNoVat - (sum.sumNoVat / 100 * voucher.price));
        }
        sum.sumWithVat = addVatToSum(sum.sumNoVat);
    }
    return sum;
};

/**
 * adding VAT to sum with no VAT
 * @param sumNoVat
 * @returns {number}
 */
export const addVatToSum = (sumNoVat) => {
    let sumWithVat = 0;
    sumWithVat = Math.round(sumNoVat + ((sumNoVat/100) * 21));
    return sumWithVat;
};

/**
 * counting no VAT sum from sum with VAT
 * @param sumWithVat
 * @returns {number}
 */
export const countNoVatSum = (sumWithVat) => {
    let sumNoVat = 0;
    sumNoVat = Math.round((sumWithVat/121) * 100);
    return sumNoVat;
};
