export const GET_ALL_MEDALS_STARTED = 'GET_ALL_MEDALS_STARTED';
export const GET_ALL_MEDALS_SUCCESS = 'GET_ALL_MEDALS_SUCCESS';
export const GET_ALL_MEDALS_FAILURE = 'GET_ALL_MEDALS_FAILURE';
export const EDIT_MEDAL_STARTED = 'EDIT_MEDAL_STARTED';
export const EDIT_MEDAL_SUCCESS = 'EDIT_MEDAL_SUCCESS';
export const EDIT_MEDAL_FAILURE = 'EDIT_MEDAL_FAILURE';
export const ADD_NEW_MEDAL_STARTED = 'ADD_NEW_MEDAL_STARTED';
export const ADD_NEW_MEDAL_SUCCESS = 'ADD_NEW_MEDAL_SUCCESS';
export const ADD_NEW_MEDAL_FAILURE = 'ADD_NEW_MEDAL_FAILURE';
export const REMOVE_MEDAL_STARTED = 'REMOVE_MEDAL_STARTED';
export const REMOVE_MEDAL_SUCCESS = 'REMOVE_MEDAL_SUCCESS';
export const REMOVE_MEDAL_FAILURE = 'REMOVE_MEDAL_FAILURE';
