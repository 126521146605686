import { connect } from 'react-redux';
import EventAdminComponent from "./EventAdminComponent";
import EventOperations from "./duck/operation";

const mapStateToProps = (state) => {
    const { events, isLoading } = state.eventsReducer;
    return {
        events, isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {

    const getAllEvents = () => {
        return dispatch(EventOperations.getAllEventsAction());
    };

    const addNewEvent = (event) => {
        return dispatch(EventOperations.addNewEventAction(event));
    };

    const editEvent = (event, eventId) => {
        return dispatch(EventOperations.editEventAction(event, eventId));
    };

    const removeEvent = (eventId) => {
        return dispatch(EventOperations.removeEventAction(eventId));
    };

    return {
        getAllEvents,
        addNewEvent,
        editEvent,
        removeEvent
    };
};

const EventAdminContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventAdminComponent);

export default EventAdminContainer;
