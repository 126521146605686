import {addNewEvent, editEvent, getAllEvents, removeEvent} from "./action";

const getAllEventsAction = () => getAllEvents();
const addNewEventAction = (event) => addNewEvent(event);
const editEventAction = (event, eventId) => editEvent(event, eventId);
const removeEventAction = (eventId) => removeEvent(eventId);

export default {
    getAllEventsAction,
    addNewEventAction,
    editEventAction,
    removeEventAction
}
