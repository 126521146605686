import React, {Component} from 'react';
import './ProductListComponent.scss';
import LoadingDashboardComponent from "../../../common/LoadingDashboardComponent";
import { Element } from 'react-scroll';
import {withBreakpoints} from "react-breakpoints";
import ProductViewContainer from "../ProductViewContainer";
import crossFilter from './../../../../assets/images/eshop/cross-filter.png';
import {Image} from "react-bootstrap";

const colorMap = {
    red: "Červené",
    white: "Bílé",
    rose: "Rosé",
};


class ProductListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredProducts: [],
        }
    }

    componentDidMount() {
        const { getAllProducts } = this.props;
        getAllProducts();
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleRemoveFilter = (filter) => {
        const { removeFilter } = this.props;
        removeFilter(filter);
    };

    renderFilterLabel(filter) {
        let filterToShow = filter;
        if(filter === 'rose' || filter === 'white' || filter === 'red') {
            filterToShow = colorMap[filter];
        }
        return (
            <div className='filter-label'>
                <span className='filter-label__cross' onClick={() => this.handleRemoveFilter(filter)}>
                    <Image width={20} height={20} src={crossFilter} />
                </span>
                <span className='filter-label__text'>
                    {this.capitalizeFirstLetter(filterToShow)}
                </span>
            </div>
        )
    }

    filterProcess = (filters) => {
        const { allProducts } = this.props;
        let filteredProducts = [];

        if(filters.length > 0) {
            filters.forEach(filter => {
                filteredProducts = filteredProducts.concat(this.filterProducts(filter, allProducts));
            });
            return Array.from(new Set(filteredProducts));
       } else {
            return allProducts;
        }
    };

    filterProducts = (filter, products) => {
        return products.filter(product => {
          if(filter === 'red' || filter === 'rose' || filter === 'white') {
              return product.color === filter;
          }
          else if(filter === 'polosuché' || filter === 'suché'
              || filter === 'sladké' || filter ==='polosladké') {
              return product.type === filter;
          } else {
              return true;
          }
      });
    };

    renderProductsForMobile(filters) {
        return(
            <div className='product-list-container__list'>
                <div className='product-list-container__products'>
                    {
                        this.filterProcess(filters).map((product) => {
                            return (
                                <div className='product-list-container__product'>
                                    <ProductViewContainer product={product} size='mobile'/>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    renderProductsForDesktop(filters) {
        return(
            <div className='product-list-container__list'>
                <div className='product-list-container__products'>
                    {
                        this.filterProcess(filters).map((product) => {
                            return (
                                <div className='product-list-container__product'>
                                    <ProductViewContainer product={product} size='desktop'/>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    render() {
        const {
            allProducts,
            isLoading,
            error,
            filters,
            breakpoints,
            currentBreakpoint
        } = this.props;

        if(isLoading) {
            return (
                <LoadingDashboardComponent />
            );
        } else if(!allProducts || error || allProducts.length === 0) {
            return (
                <></>
            );
        } else {
            return(
                <Element name='product-list-element'>
                    <div className='product-list-container__filter'>
                        {
                            filters.map(filter => {
                                   return this.renderFilterLabel(filter)
                            })
                        }
                    </div>
                    <div className='product-list-container'>
                        {
                            breakpoints[currentBreakpoint] < breakpoints.tablet ?
                            this.renderProductsForMobile(filters) :
                            this.renderProductsForDesktop(filters)

                        }

                    </div>
                </Element>
            );
        }
    }
}

export default withBreakpoints(ProductListComponent);
