import React from "react";
import './LoadingDashboardComponent.scss';
import {Spinner} from "react-bootstrap";

function LoadingDashboardComponent() {

    return(
        <div className="loading-dashboard-container">
            <Spinner animation='grow' size='lg' className='loading-dashboard-container__spin'/>
            <Spinner animation='grow' size='lg' className='loading-dashboard-container__spin'/>
            <Spinner animation='grow' size='lg' className='loading-dashboard-container__spin'/>
        </div>
    )
}

export default LoadingDashboardComponent;
