import { connect } from 'react-redux';
import OrdersOperation from './../duck/operation';
import OrderTileComponent from "./OrderTileComponent";

const mapStateToProps = (state) => {
    const { isLoading, ordersState } = state.ordersReducer;
    const { orderState } = ordersState;
    return {orderState, isLoading };
};

const mapDispatchToProps = (dispatch) => {
    const getAllOrders = () => {
        dispatch(OrdersOperation.getAllOrdersAction());
    };

    const getOrderStates = () => {
        dispatch(OrdersOperation.getOrdersStateAction());
    };

    const changeOrderState = (orderId, orderState) => {
        dispatch(OrdersOperation.changeOrderStateAction(orderId, orderState));
    };

    const confirmOrderState = (orderId) => {
        return dispatch(OrdersOperation.confirmOrderStateAction(orderId));
    };

    const getOrderStatus = () => {
        return dispatch(OrdersOperation.getOrderStatusAction());
    };


    return {
        getAllOrders,
        getOrderStates,
        changeOrderState,
        confirmOrderState,
        getOrderStatus,
    };
};

const OrderTileContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderTileComponent);


export default OrderTileContainer;
