import {environment} from "../configApi";

export const getAllMedalsAPI = () => {
    return fetch(`${environment}/api/medal`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};

export const updateMedalAPI = (medalId, medal) => {
  return fetch(`${environment}/api/medal/${medalId}`, {
      method: 'put',
      headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token'),
          'Content-Type': 'application/json',
      }, body: JSON.stringify(medal)
  })
      .then(res => {
          if (res.status >= 400) {
              return res.json().then(data => {
                  return Promise.reject(new Error(data.message));
              });

          }
      }).catch(error => {
          return Promise.reject(error)
      });
};

export const addNewMedalAPI = (medal) => {
  return fetch(`${environment}/api/medal`, {
      method: 'post',
      headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token'),
          'Content-Type': 'application/json',
      }, body: JSON.stringify(medal)
  })
      .then(res => {
          if (res.status >= 400) {
              return res.json().then(data => {
                  return Promise.reject(new Error(data.message));
              });

          }
      }).catch(error => {
          return Promise.reject(error)
      });
};

export const removeMedalApi = (medalId) => {
    return fetch(`${environment}/api/medal/${medalId}`, {
        method: 'delete',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
        }).catch(error => {
            return Promise.reject(error)
        });
};
