import React, {Component} from "react";
import './ProductViewComponent.scss';
import GertnerButtonComponent from "../../common/GertnerButtonComponent";
import {withRouter} from "react-router";
import {getImageProductPathByProduct} from "../../../admin/common/utils/BasicUtils";
import {Image} from "react-bootstrap";

class ProductViewComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            textButton: props.product.price+' Kč',
            imageSrc: null,
        }
    }

    handleFocusButton = () => {
        const { size } = this.props;
        if(size === 'desktop') {
            this.setState({
                textButton: 'Koupit za '+this.props.product.price+' Kč',
            });
        }
    };

    handleMouseLeaveButton = () => {
        const { size } = this.props;
        if(size === 'desktop') {
            this.setState({
                textButton: this.props.product.price+' Kč'
            })
        }
    };

    async addToCart(selectedProduct, countBottle, computedPrice) {
        const { addToShoppingCart } = this.props;
        await addToShoppingCart(selectedProduct, countBottle, computedPrice);
    }

    handleAddToCart = () => {
        const { product, recountShoppingCart } = this.props;
        this.addToCart(product, 1, product.price).then(() => {
            recountShoppingCart();
        })
    };

    handleRoute = () => {
        const { history, product } = this.props;
        history.push(`/eshop/${product.id}`);
        window.location.reload();
        window.scrollTo(0,0)
    };

    handleErrorImage = () => {
        this.setState({
            imageSrc: `/images/products/${this.props.product.color}.png`,
        });
    };

    render() {
        const { product, size } = this.props;
        const { textButton, imageSrc } = this.state;
        return (
            <div className='product-view-container'>
                <span className='product-view-container__image' onClick={this.handleRoute}>
                    <Image src={imageSrc ? imageSrc : getImageProductPathByProduct(product)}
                         onError={this.handleErrorImage}/>
                </span>
                <span className='product-view-container__name' onClick={this.handleRoute} >
                    <span>{product.name}</span>
                </span>
                <span className='product-view-container__category'>{product.vintage}, {product.type}</span>
                <div className='product-view-container__button' onClick={this.handleAddToCart}>
                    <GertnerButtonComponent text={textButton}
                                            handleMouseEnter={this.handleFocusButton}
                                            handleMouseLeave={this.handleMouseLeaveButton}
                                            size={size}
                    />
                </div>
            </div>
        )
    }
}

ProductViewComponent.defaultProps = {
    size: 'desktop',
};

export default withRouter(ProductViewComponent);
