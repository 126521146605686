import React, {PureComponent} from "react";
import {Image, Modal} from "react-bootstrap";
import subsidyImage from './../../assets/images/subsidy/subsidy.jpg';
import './SubsidyModal.scss';

export default class SubsidyModal extends PureComponent {
    render() {
        const {isSubsidyShow, hideSubsidy} = this.props;
        return (
            <Modal show={isSubsidyShow} size='lg' onHide={hideSubsidy}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <Image src={subsidyImage}/>
                    <div className='subsidy-modal-description-container'>
                        <span className='subsidy-modal-description-container__title'>Popis projektu:</span>
                        <div className='subsidy-modal-description-container__text'>
                            Projekt byl realizován v záměru „Zahájení činnosti mladých zemědělců“. Realizace projektu
                            umožnila provést investice do strojového a technologického vybavení pro pěstování a následné
                            zpracování révy vinné.
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
