import {environment} from "../configApi";

export const getTypesDeliveryAPI = () => {
    return fetch(`${environment}/api/enum/typeDelivery`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};


export const getPaymentMethodsAPI = () => {
    return fetch(`${environment}/api/enum/paymentMethods`, {
        method: "get",
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(res => {
            if (res.status >= 400) {
                return res.json().then(data => {
                    return Promise.reject(new Error(data.message));
                });

            }
            return res.json();
        }).catch(error => {
            return Promise.reject(error)
        });
};
