import {
    GET_ALL_VOUCHERS_FAILURE,
    GET_ALL_VOUCHERS_SUCCESS,
    GET_ALL_VOUCHERS_STARTED,
    CHANGE_VOUCHER_ACTIVITY_STARTED,
    CHANGE_VOUCHER_ACTIVITY_SUCCESS,
    CHANGE_VOUCHER_ACTIVITY_FAILURE,
    DELETE_VOUCHER_STARTED,
    DELETE_VOUCHER_SUCCESS,
    DELETE_VOUCHER_FAILURE,
    ADD_NEW_VOUCHER_STARTED, ADD_NEW_VOUCHER_SUCCESS, ADD_NEW_VOUCHER_FAILURE
} from "./type";

const initialState = {
    vouchers: [],
};

const vouchersReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_VOUCHERS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_VOUCHERS_SUCCESS: {
            return {
                ...state,
                vouchers: action.payload,
                isLoading: false,
            }
        }

        case GET_ALL_VOUCHERS_FAILURE: {
            return {
                ...state,
                error: action.error.message,
                isLoading: false,
            }
        }

        case CHANGE_VOUCHER_ACTIVITY_STARTED: {
            return {
                ...state,
            }
        }

        case CHANGE_VOUCHER_ACTIVITY_SUCCESS: {
            return {
                ...state,
            }
        }

        case CHANGE_VOUCHER_ACTIVITY_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        case DELETE_VOUCHER_STARTED: {
            return {
                ...state,
            }
        }

        case DELETE_VOUCHER_SUCCESS: {
            return {
                ...state,
            }
        }

        case DELETE_VOUCHER_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        case ADD_NEW_VOUCHER_STARTED: {
            return {
                ...state,
            }
        }

        case ADD_NEW_VOUCHER_SUCCESS: {
            return {
                ...state,
            }
        }

        case ADD_NEW_VOUCHER_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default vouchersReducer;
