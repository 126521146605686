import React, {Component} from "react";
import OrderPopUpComponent from "../OrderPopUpComponent";
import {Table} from "react-bootstrap";
import LoadingComponent from "../../common/LoadingComponent";
import "./OrdersHistoryComponent.scss";
import {withBreakpoints} from "react-breakpoints";
import OrderTileContainer from "../tile/OrderTileContainer";
import {FaInfoCircle} from "react-icons/all";
import InfiniteScroll from "react-infinite-scroll-component";

class OrdersHistoryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
            selectedOrder: null,
            orders: {},
        }
    }

    componentDidMount() {
        const {getOrderStates, getOrdersHistory, getOrderStatus, cleanOrdersReducer} = this.props;
        cleanOrdersReducer();
        getOrdersHistory(0);
        getOrderStates();
        getOrderStatus();
    }


    createAddressString = (customer) => {
        return customer.address + ', ' + customer.city + ' psc: ' + customer.psc;
    };

    handleShowPopup = (order) => {
        this.setState({
            selectedOrder: order,
            showPopUp: true,
        })
    };

    handleCloseModal = () => {
        this.setState({
            showPopUp: false,
        });
    };

    getTextForSelectBox = (orderState) => {
        switch (orderState) {
            case 'wait':
                return 'Čeká';
            case 'delivery':
                return 'Doručováno';
            case 'settled':
                return 'Odesláno';
            case 'complete':
                return 'Kompletní';
            default:
                return 'Neznámý stav';
        }
    };

    renderDesktop = () => {
        const {selectedOrder, showPopUp} = this.state;
        const {orders, isLoading, getOrdersHistory, page, last} = this.props;

        return (
            <div className="orders-container">
                <div className="orders-container__table-block">
                    <InfiniteScroll
                        next={() => getOrdersHistory(page + 1)}
                        hasMore={!last}
                        loader={<p>Loading...</p>}
                        dataLength={orders ? orders.length : 0}
                    >
                        <Table hover striped>
                            <thead>
                            <tr>
                                <th>Jmeno a Prijmeni</th>
                                <th>Adresa</th>
                                <th>Telefon</th>
                                <th>Email</th>
                                <th>Detail</th>
                                <th>Cena celkem</th>
                                <th>Stav objednávky</th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                orders ? (orders.map((order) => (
                                    <tr>
                                        <td>{order.customer.name + ' ' + order.customer.surname}</td>
                                        <td>{this.createAddressString(order.customer)}</td>
                                        <td>{order.customer.telephone}</td>
                                        <td>{order.customer.email}</td>
                                        <td>
                                            <FaInfoCircle size={'30px'} color={'#e28602'} style={{cursor: 'pointer'}}
                                                          onClick={() => this.handleShowPopup(order)}
                                            />
                                        </td>
                                        <td>{order.sumOfShoppingCart},-</td>
                                        <td>{this.getTextForSelectBox(order.state)}</td>
                                    </tr>
                                ))) : <LoadingComponent/>
                            }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                    <OrderPopUpComponent order={selectedOrder} isShow={showPopUp}
                                         handleClose={this.handleCloseModal}/>
                </div>
            </div>);

    };

    renderMobile = () => {
        const {
            orderState,
            orders,
            isLoading,
            getOrdersHistory,
            page,
            last
        } = this.props;

        return (
            <div className="orders-container-mobile">
                <InfiniteScroll
                    next={() => getOrdersHistory(page + 1)}
                    hasMore={!last}
                    loader={<LoadingComponent/>}
                    dataLength={orders ? orders.length : 0}
                >
                {orders ?
                    orders.map((order, idx) => {
                        return (
                            <OrderTileContainer
                                orderProp={order}
                                addressString={this.createAddressString}
                                loading={isLoading}
                                orderStates={orderState}
                                id={idx}
                                getOrders={getOrdersHistory}
                                getSelectboxNames={this.getTextForSelectBox}
                                readOnly={true}
                            />
                        )
                    })
                    :
                    <LoadingComponent/>
                }
                </InfiniteScroll>
            </div>
        );
    };

    render() {
        const {breakpoints, currentBreakpoint} = this.props;
        return (
            <>
                {breakpoints[currentBreakpoint] > breakpoints.tablet ? (
                    this.renderDesktop()
                ) : (
                    this.renderMobile()
                )}
            </>
        )
    }

}

export default withBreakpoints(OrdersHistoryComponent);
