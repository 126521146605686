import {
    GET_ALL_PRODUCTS_STARTED,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_FAILURE,
    UPDATE_PRODUCT_STARTED,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    ADD_NEW_PRODUCT_STARTED,
    ADD_NEW_PRODUCT_SUCCESS,
    ADD_NEW_PRODUCT_FAILURE,
} from "./type";

const initialState = {
    products: null,
    isLoading: false,
};


const productsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_PRODUCTS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: action.payload.products,
                isLoading: false,
                error: null
            }
        }

        case GET_ALL_PRODUCTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error.message,
            }
        }

        case UPDATE_PRODUCT_STARTED: {
            return {
                ...state,
            }
        }

        case UPDATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                error: null,
            }
        }

        case UPDATE_PRODUCT_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        case ADD_NEW_PRODUCT_STARTED: {
            return {
                ...state,
            }
        }

        case ADD_NEW_PRODUCT_SUCCESS: {
            return {
                ...state,
                error: null,
            }
        }

        case ADD_NEW_PRODUCT_FAILURE: {
            return {
                ...state,
                error: action.error.message,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default productsReducer;
