export function getStatusLocalizationText(status) {

    switch (status) {
        case 'CREATED':
            return 'Platba založena';
        case 'PAYMENT_METHOD_CHOSEN':
            return 'Platební metoda vybrána';
        case 'PAID':
            return 'Platba zaplacena';
        case 'AUTHORIZED':
            return 'Platba předautorizována';
        case 'CANCELED':
            return 'Platba zrušena';
        case 'TIMEOUTED':
            return 'Vypršelá platnost platby';
        case 'REFUNDED':
            return 'Platba refundována';
        case 'PARTIALLY_REFUNDED':
            return 'Platba částečně refundována';
        default:
            return 'Neznámý stav platby';
    }
}
