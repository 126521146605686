import {
    SHOW_CART_TOAST,
    HIDE_CART_TOAST,
} from "./type";

const initialState = {
    toasts: [],
    showToast: false,
    product: null,
    price: null,
    count: null
};

const eshopToastReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CART_TOAST: {
            return {
                ...state,
                toasts: state.toasts.concat({
                    showToast: true,
                    product: action.payload.product,
                    price: action.payload.price,
                    count: action.payload.count,
                })
            }
        }

        case HIDE_CART_TOAST: {
            const lengthToasts = state.toasts.length;
            return {
                ...state,
                toasts: [
                    ...state.toasts.slice(0, action.index),
                    ...state.toasts.slice(action.index+1, lengthToasts)
                ]
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default eshopToastReducer;
