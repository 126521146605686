import {
    showSubsidy,
    hideSubsidy
} from "./action";

const showSubsidyAction = () => showSubsidy();
const hideSubsidyAction = () => hideSubsidy();

export default {
    showSubsidyAction, hideSubsidyAction
}
