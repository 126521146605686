import {
    SHOW_SUBSIDY,
    HIDE_SUBSIDY,
} from "./type";


const showSubsidy = () => (dispatch) => {
    dispatch({
        type: SHOW_SUBSIDY,
    });
};

const hideSubsidy = () => (dispatch) => {
    dispatch({
        type: HIDE_SUBSIDY,
    })
};


export {
    showSubsidy,
    hideSubsidy,
}
