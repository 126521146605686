import React, {PureComponent} from "react";
import './GertnerButtonComponent.scss';

export default class GertnerButtonComponent extends PureComponent {

    render() {
        const { text, handleClick, handleMouseEnter, handleMouseLeave, size } = this.props;
        if(size === 'desktop') {
            return (
                <button className="gertner-button"
                        onClick={handleClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                >
                    {text}
                </button>
            );
        } else { //Mobile size
            return (
                <button className="gertner-button-mobile"
                        onClick={handleClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                >
                    {text}
                </button>
            )
        }
    }

}

GertnerButtonComponent.defaultProps = {
    size: 'desktop'
};
