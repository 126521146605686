import React, {PureComponent} from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './SlideShowComponent.scss';
import {withBreakpoints} from "react-breakpoints";
import photo1 from './../../../../assets/images/slideshow/slideshow-foto-01.png';
import photo2 from './../../../../assets/images/slideshow/slideshow-foto-02.png';
import photo3 from './../../../../assets/images/slideshow/slideshow-foto-03.png';
import photo4 from './../../../../assets/images/slideshow/slideshow-foto-04.png';
import photo5 from './../../../../assets/images/slideshow/slideshow-foto-05.png';
import splitter from './../../../../assets/images/slideshow/slideshow-splitter.svg';
import {Image} from "react-bootstrap";

class SlideShowComponent extends PureComponent {

    renderCarouselContent(imageSrc, text) {
        return (
            <div className="slideshow-container">
                <Image className="slideshow-image" src={imageSrc}/>
                <Image className="slideshow-splitter" src={splitter}/>
                <div className="slideshow-text">{text}</div>
            </div>

        )
    }

    render() {
        const { currentBreakpoint, breakpoints } = this.props;
        let carouselWidth = '572px';
        if(breakpoints[currentBreakpoint] < breakpoints.tablet){
            carouselWidth = '330px';
        }
        return(
            <div className="motorbike-container">
                <span className='motorbike-title'>Proč máme za symbol také motorku ?</span>
            <Carousel autoPlay={true}
                      showArrows={false}
                      swipeable={false}
                      showStatus={false}
                      showIndicators={false}
                      showThumbs={false}
                      infiniteLoop={true}
                      interval={5000}
                      width={carouselWidth}
                      stopOnHover={true}
            >
                    {this.renderCarouselContent(photo1,
                        'Je to náš koníček a také životní styl. Na motorce jezdíme klidně i pracovat do vinohradu.'
                    )}
                    {this.renderCarouselContent(photo2,
                        'Víme, že nehoda není náhoda – neriskujeme proto nikdy kvalitu našich vín ' +
                        'a hlídáme ji od vlastnoruční práce na vinici až do chvíle, kdy se víno dostane k vám.'
                    )}
                    {this.renderCarouselContent(photo3,
                        'Jedeme jen tak rychle, kolik s jistotou zvládneme – ' +
                        'jsme malé vinařství a vyrobíme pouze tolik vína, abychom na to stačili sami.')}
                    {this.renderCarouselContent(photo4,
                        'Nebojíme se objevovat nové cesty – hledáme je ke každému ' +
                        'ročníku a líbí se nám, že nikdy nejsou stejné.')}
                    {this.renderCarouselContent(photo5,
                        'Vinařství je pro nás jízda – rádi poznáváme nová místa a lidi. ' +
                        'Často nás proto uvidíte na festivalech a akcích pod širým nebem.')}
            </Carousel>
            </div>
        )
    }

}

export default withBreakpoints(SlideShowComponent);
