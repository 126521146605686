import {
    GET_ALL_EVENTS_FAILURE,
    GET_ALL_EVENTS_STARTED,
    GET_ALL_EVENTS_SUCCESS
} from "./type";
import { getAllNotHiddenEventsAPI } from "../../../../api/event/EventApi";

const getAllEvents = () => {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_EVENTS_STARTED,
        });
        return getAllNotHiddenEventsAPI().then((data) => {
            dispatch({
                type: GET_ALL_EVENTS_SUCCESS,
                payload: data,
            });
        }).catch((error) => {
            dispatch({
                type: GET_ALL_EVENTS_FAILURE,
                error: error,
            });
        })
    }
};

export {
    getAllEvents,
}
