import React, {PureComponent} from "react";
import './GertnerCheckboxComponent.scss';

export default class GertnerCheckboxComponent extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isChecked: props.isChecked || false,
        }
    }

    handleCheck = () => {
        const {checkAction} = this.props;
        this.setState({
            isChecked: !this.state.isChecked,
        });
        checkAction();
    };

    getPriceText(price) {
        if (price === 0) {
            return 'zdarma'
        } else {
            return `+ ${price} Kč`;
        }
    }

    render() {
        const {
            title,
            description,
            price,
            isChecked,
            size
        } = this.props;
        const checkMarkClassName = size === 'small' ? 'checkmark checkmark--small' : 'checkmark';
        const checked = isChecked ? "checked" : "";
        return(
            <div className='gertner-checkbox-container'>
                <label className='container-checkbox'>
                    <input type='checkbox' checked={checked} onChange={this.handleCheck}/>
                    <span className={checkMarkClassName}/>
                </label>
                <div className='gertner-checkbox-container__texts'>
                    <div className='gertner-checkbox-container__title'>
                        {title}
                    </div>
                    <div className='gertner-checkbox-container__description'>
                        {description}
                    </div>
                    {
                        (price || price === 0) && (
                            <div className='gertner-checkbox-container__price'>
                                {this.getPriceText(price)}
                            </div>
                        )
                    }
                </div>
            </div>

        )
    }

}

GertnerCheckboxComponent.defaultProps = {
  size: 'large'
};
