import React, {Component} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import './../GlobalAdmin.scss';
import Row from "react-bootstrap/Row";

export default class MedalPopUpComponent extends Component {

    constructor(props) {
        super(props);
        if(!props.isAdding) {
            this.state = {
                type: props.medal.type || '',
                description: props.medal.description || '',
                isTypeValid: true,
                isDescriptionValid: true,
                isError: false,
            }
        } else {
            this.state = {
                type: '',
                description: '',
                isTypeValid: false,
                isDescriptionValid: false,
                isError: false,
            }
        }
    }

    typeValidation = (title) => {
      return title.length > 0 && title.length < 30;
    };

    descriptionValidation = (text) => {
        return text.length > 0 && text.length < 300;
    };

    handleTypeChange = (event) => {
      this.setState({
          type: event.target.value,
          isTypeValid: this.typeValidation(event.target.value),
      });
    };

    handleDescriptionChange = (event) => {
      this.setState({
          description: event.target.value,
          isDescriptionValid: this.descriptionValidation(event.target.value),
      });
    };

    addNewMedal = (event) => {
        event.preventDefault();
        const { isTypeValid, isDescriptionValid, type, description } = this.state;
        const { addNewMedalMethod, getAllMedalsMethod, handleClose } = this.props;
        if(isDescriptionValid && isTypeValid) {
            this.setState({
                isError: false
            });
            const medal = {
              type: type,
              description: description
            };
            addNewMedalMethod(medal).then(() => {
                getAllMedalsMethod();
                handleClose();
            })
        } else {
            this.setState({
                isError: true,
            })
        }
    };

    editMedal = (event) => {
        event.preventDefault();
        const { isTypeValid, isDescriptionValid, type, description } = this.state;
        const { editMedalMethod, getAllMedalsMethod, handleClose } = this.props;
        const medalFromProps = this.props.medal;
        if(isTypeValid && isDescriptionValid) {
            this.setState({
                isError: false
            });
            const medal = {
                type: type,
                description: description
            };
            editMedalMethod(medalFromProps.id, medal).then(() => {
                getAllMedalsMethod();
                handleClose();
            })
        } else {
            this.setState({
                isError: true,
            })
        }
    };

    handleOnSaveClick = (event) => {
        const { isAdding } = this.props;
        if(isAdding) {
            this.addNewMedal(event);
        } else {
            this.editMedal(event);
        }
    };


    render() {
        const { isShow, handleClose, isAdding, medal } = this.props;
        const {
            description,
            type,
            isTypeValid,
            isDescriptionValid
        } = this.state;

        return (
            <div>
                <Modal show={isShow} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                isAdding ?
                                    'Nová medaile' :
                                    medal.title
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='admin-modal-center'>
                        <Form>
                            <div className="admin-margin">
                                <Form.Group as={Row}>
                                    <Form.Label column htmlFor="form-horizontal-text">Typ medaile</Form.Label>
                                    <Form.Control isInvalid={!isTypeValid}
                                           id="form-horizontal-text" type="text"
                                           placeholder="Typ medaile" value={type} name="type"
                                           onChange={this.handleTypeChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="admin-margin">
                                <Form.Group as={Row}>
                                    <Form.Label htmlFor="form-horizontal-text">Popis</Form.Label>
                                    <Form.Control as='textarea' isInvalid={!isDescriptionValid}
                                              id="form-horizontal-text" rows={8}
                                              placeholder="Popis" value={description}
                                              onChange={this.handleDescriptionChange}
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.isError &&
                            <p className="error-validation">
                                Chybně vyplněné údaje
                            </p>
                        }
                            <Button variant='outline-secondary' onClick={handleClose}>
                                Zrušit
                            </Button>
                            <Button variant='outline-success' onClick={this.handleOnSaveClick}>
                                Uložit
                            </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
