import {connect} from "react-redux";
import OrderConfirmationComponent from "./OrderConfirmationComponent";

const mapStateToProps = (state) => {

    const { shoppingCart } = state.shoppingCart;
    return { shoppingCart };
};

const mapDispatchToProps = (dispatch) => {

    return {

    }
};

const OrderConfirmationContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderConfirmationComponent);


export default OrderConfirmationContainer;
