export const GET_ALL_PRODUCTS_STARTED = 'GET_ALL_PRODUCTS_STARTED';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE';
export const UPDATE_PRODUCT_STARTED = 'UPDATE_PRODUCT_STARTED';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const ADD_NEW_PRODUCT_STARTED = 'ADD_NEW_PRODUCT_STARTED';
export const ADD_NEW_PRODUCT_SUCCESS = 'ADD_NEW_PRODUCT_SUCCESS';
export const ADD_NEW_PRODUCT_FAILURE = 'ADD_NEW_PRODUCT_FAILURE';
export const REMOVE_PRODUCT_STARTED = 'REMOVE_PRODUCT_STARTED';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_FAILURE = 'REMOVE_PRODUCT_FAILURE';

