import {
    ADD_TO_SHOPPING_CART,
    DECREASE_COUNT_BOTTLE,
    INCREASE_COUNT_BOTTLE,
    RECOUNT_SUM_OF_SHOPPING_CART,
    REMOVE_FROM_SHOPPING_CART,
    CHECK_VOUCHER_CODE_FAILURE,
    CHECK_VOUCHER_CODE_STARTED,
    CHECK_VOUCHER_CODE_SUCCESS,
    REMOVE_VOUCHER_CODE, CLEAN_SHOPPING_CART, CHANGE_BOTTLE_COUNT,
} from './type';
import {checkVoucherAPI} from "../../../../api/voucher/VoucherApi";
import {showToast} from "../../eshop-toast/duck/action";

async function foundProduct(products, product) {
    let productFound = undefined;
    await products.forEach((productInState, idx) => {
        if (productInState.product.id === product.id) {
            productFound = idx;
        }
    });

    return productFound;
}

const addToShoppingCart = (product, count, price) => {
    return (dispatch, getState) => {
        dispatch(showToast(product, count, price));
        const products = getState().shoppingCart.shoppingCart.products;
        foundProduct(products, product).then((productFound) => {
            if (productFound || productFound === 0) {
                return dispatch(increaseCountBottle(productFound, count, price));
            } else {
                return dispatch({
                    type: ADD_TO_SHOPPING_CART,
                    product: product,
                    count: count,
                    price: price,
                });

            }
        });
    }
};

const removeFromShoppingCart = (idx) => (dispatch) => {
    dispatch({
        type: REMOVE_FROM_SHOPPING_CART,
        productId: idx,
    });
};

const increaseCountBottle = (idx, count, price) => (dispatch) => {
    dispatch({
        type: INCREASE_COUNT_BOTTLE,
        productId: idx,
        count: count,
        price: price
    })
};

const decreaseCountBottle = (idx, price) => (dispatch, getState) => {
    const count = getState().shoppingCart.shoppingCart.products[idx].count;
    if (count - 1 === 0) {
        dispatch(removeFromShoppingCart(idx));
    } else {
        dispatch({
            type: DECREASE_COUNT_BOTTLE,
            productId: idx,
            price: price,
        })
    }
};

const changeBottleCount = (idx, count, price) => (dispatch, getState) => {

    dispatch({
        type: CHANGE_BOTTLE_COUNT,
        productId: idx,
        price: price,
        count: count
    })
}

const recountShoppingCart = () => (dispatch) => {
    dispatch({
        type: RECOUNT_SUM_OF_SHOPPING_CART,
    });
};

const checkVoucherCode = (voucherCode) => {
    return (dispatch) => {
        dispatch({
            type: CHECK_VOUCHER_CODE_STARTED,
        });
        checkVoucherAPI(voucherCode).then((data) => {
            dispatch({
                type: CHECK_VOUCHER_CODE_SUCCESS,
                voucher: data,
            });
            dispatch({
                type: RECOUNT_SUM_OF_SHOPPING_CART,
            })
        }).catch(error => {
            dispatch({
                type: CHECK_VOUCHER_CODE_FAILURE,
                error: error,
            })
        })
    }
};

const removeVoucher = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_VOUCHER_CODE,
        });
        dispatch({
            type: RECOUNT_SUM_OF_SHOPPING_CART,
        })
    }
};

const cleanShoppingCart = () => (dispatch) => {
    dispatch({
        type: CLEAN_SHOPPING_CART,
    });
};

export {
    addToShoppingCart,
    removeFromShoppingCart,
    increaseCountBottle,
    decreaseCountBottle,
    recountShoppingCart,
    checkVoucherCode,
    removeVoucher,
    cleanShoppingCart,
    changeBottleCount
};
