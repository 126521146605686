import React, { PureComponent } from "react";
import './EventHeaderComponent.scss';

export default class EventHeaderComponent extends PureComponent {

    render() {

        return (
          <div className='event-header-container'>
                <div className='event-header-container__title'>
                    Chcete se svézt s námi?
                </div>
              <div className='event-header-container__sub-title'>
                  Podívejte se, kdy a kde nás potkáte.
              </div>
          </div>
        );
    }

}
