import {
    GET_ALL_CONSTANTS_FAILURE,
    GET_ALL_CONSTANTS_STARTED,
    GET_ALL_CONSTANTS_SUCCESS,
} from "./type";
import {getAllConstantsAPI} from "../../../api/constants/ConstantsApi";

const getAllConstants = () => {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_CONSTANTS_STARTED,
        });
        return getAllConstantsAPI().then((data) => {
            dispatch({
                type: GET_ALL_CONSTANTS_SUCCESS,
                payload: data,
            });
        }).catch((error) => {
            dispatch({
                type: GET_ALL_CONSTANTS_FAILURE,
                error: error,
            });
        })
    }
};



export {
    getAllConstants,
}
