import {
    APPLY_FILTER,
    GET_ALL_PRODUCTS_FAILURE,
    GET_ALL_PRODUCTS_STARTED,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_PRODUCT_BY_ID_FAILURE,
    GET_PRODUCT_BY_ID_STARTED,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_TOP_THREE_PRODUCTS_FAILURE,
    GET_TOP_THREE_PRODUCTS_STARTED,
    GET_TOP_THREE_PRODUCTS_SUCCESS,
    REMOVE_FILTER,
} from './type';
import {
    getAllActiveProductsAPI,
    getProductByIdAPI,
    getRandomProductsAPI
} from "../../../api/product/ProductApi";

const getTopThreeProducts = () => (dispatch) => {
    dispatch({
        type: GET_TOP_THREE_PRODUCTS_STARTED,
    });
    return getRandomProductsAPI().then((data) => {
        return dispatch({
            type: GET_TOP_THREE_PRODUCTS_SUCCESS,
            payload: {
                products: data
            }
        });
    }).catch(error => {
        dispatch({
            type: GET_TOP_THREE_PRODUCTS_FAILURE,
            error
        })
    });
};

const applyFilter = (filter) => (dispatch, getState) => {
    const { filters } = getState().productsDashboard;
    let filterExist = false;
    if(filters.length > 0) {
        filterExist = filters.find(reducerFilter => {
            return filter === reducerFilter;
        });
    }
    if(!filterExist) {
        dispatch({
            type: APPLY_FILTER,
            payload: filter,
        });
    }
};

const removeFilter = (filter) => (dispatch, getState) => {
  const { filters } =  getState().productsDashboard;
  let filterExist = false;
  if(filters.length > 0) {
      filterExist = filters.find(reducerFilter => {
          return filter === reducerFilter;
      });
  }
  if(filterExist) {
      dispatch({
          type: REMOVE_FILTER,
          payload: filter,
      })
  }
};

const getAllProducts = () => (dispatch) => {
  dispatch({
      type: GET_ALL_PRODUCTS_STARTED,
  });
  return getAllActiveProductsAPI().then((data) => {
    return dispatch({
        type: GET_ALL_PRODUCTS_SUCCESS,
        payload: {
            products: data
        }
    });
  }).catch(error => {
      dispatch({
          type: GET_ALL_PRODUCTS_FAILURE,
          error
      });
  })
};

const getProductById = (productId) => (dispatch) => {
    dispatch({
        type: GET_PRODUCT_BY_ID_STARTED,
    });
    return getProductByIdAPI(productId).then((data) => {
        return dispatch({
            type: GET_PRODUCT_BY_ID_SUCCESS,
            payload: data,
        });
    }).catch(error => {
        dispatch({
            type: GET_PRODUCT_BY_ID_FAILURE,
            error
        });
    })
};


export {
    getTopThreeProducts,
    getAllProducts,
    applyFilter,
    removeFilter,
    getProductById,
};
