import { connect } from 'react-redux';
import RulesOperation from './duck/operation';
import ShoppingRulesModal from "./ShoppingRulesModal";

const mapStateToProps = (state) => {
    const isRulesShow = state.shoppingRules ? state.shoppingRules.isRulesShow : false;
    return { isRulesShow };
};

const mapDispatchToProps = (dispatch) => {
    const hideRules = () => {
        return dispatch(RulesOperation.hideRulesAction());
    };

    return {
        hideRules,
    };
};

const ShoppingRulesContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShoppingRulesModal);


export default ShoppingRulesContainer;
