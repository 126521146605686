import React, {Component} from 'react';
import LoginContainer from "../login/LoginContainer";
import DashboardContainer from "../dashboard/DashboardContainer";


class AdminHomeComponent extends Component{

    render() {
        const { oidc } = this.props;
        if(!oidc.isLogin) {
            return (
                <LoginContainer/>
            );
        } else {
            return (
                <DashboardContainer />
            );
        }
    }
}

export default AdminHomeComponent;
