import React, {PureComponent} from "react";
import './ErrorComponent.scss';
import {withRouter} from "react-router";
import {EshopLinkComponent} from "../eshop/link/EshopLinkComponent";

class ErrorComponent extends PureComponent {

    handleLink = () => {
      const { history } = this.props;
      history.push('/');
      window.location.reload();
      window.scrollTo(0,0,);
    };

    render() {
        return (
            <div className='error-component'>
                <EshopLinkComponent/>
                <div className='error-component__header'>
                    <div className='error-component__header-image'>
                    </div>
                    <div className='error-component__title'>
                        Něco se nepovedlo, zkuste to prosím později.
                    </div>
                </div>
                <div className='error-component__content'>
                    <div className='error-component__link-parent'>
                        <span className='error-component__link' onClick={this.handleLink}>
                            Přejít na web vinařství
                        </span>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(ErrorComponent);
