import { connect } from 'react-redux';
import MedalAdminComponent from "./MedalAdminComponent";
import MedalsOperation from "./duck/operation";

const mapStateToProps = (state) => {
    const { medals, isLoading } = state.medals;
    return {
        medals, isLoading
    };
};

const mapDispatchToProps = (dispatch) => {

    const getAllMedals = () => {
        return dispatch(MedalsOperation.getAllMedalsAction())
    };

    const editMedal = (actualityId, actuality) => {
        return dispatch(MedalsOperation.editMedalAction(actualityId, actuality));
    };

    const addNewMedal = (actuality) => {
        return dispatch(MedalsOperation.addNewMedalAction(actuality));
    };

    const removeMedal = (medalId) => {
        return dispatch(MedalsOperation.removeMedalAction(medalId));
    };

    return {
        getAllMedals, editMedal, addNewMedal, removeMedal,
    };
};

const MedalAdminContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MedalAdminComponent);

export default MedalAdminContainer;
