import { connect } from 'react-redux';
import RulesOperation from './duck/operation';
import SubsidyModal from "./SubsidyModal";

const mapStateToProps = (state) => {
    const isSubsidyShow = state.subsidy ? state.subsidy.isSubsidyShow : false;
    return { isSubsidyShow };
};

const mapDispatchToProps = (dispatch) => {
    const hideSubsidy = () => {
        return dispatch(RulesOperation.hideSubsidyAction());
    };

    return {
        hideSubsidy,
    };
};

const SubsidyContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubsidyModal);


export default SubsidyContainer;
