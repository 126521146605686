import React, {PureComponent} from "react";
import './TastingComponent.scss';

export default class TastingComponent extends PureComponent {

    render() {
        return (
            <div className='tasting-container'>
                <div className='tasting-container__content'>
                    <span className='tasting-container__title'>
                         Degustace
                    </span>
                    <div className='tasting-container__text'>
                        Můžete se zastavit ochutnat vína či posedět v našem sklepě v Čejkovicích, vždy po domluvě.
                        Rádi přijedeme také za vámi a uděláme degustaci.
                    </div>
                    <div className='tasting-container__btn'  >
                        <a href="mailto:info@vinarstvigertner.cz" className='tasting-container__button'>
                            Dohodněte se s Lenkou
                        </a>
                    </div>
                </div>
            </div>
        )
    }

}
