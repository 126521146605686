import {addNewProduct, getAllProducts, removeProduct, updateProduct} from "./action";


const getAllProductsAction = () => getAllProducts();
const updateProductAction = (productId, product) => updateProduct(productId, product);
const addNewProductAction = (product) => addNewProduct(product);
const removeProductAction = (productId) => removeProduct(productId);

export default {
    getAllProductsAction, updateProductAction, addNewProductAction, removeProductAction
}
