import {
    GET_ALL_ORDERS_FAILURE,
    GET_ALL_ORDERS_SUCCESS,
    GET_ALL_ORDERS_STARTED,
    GET_ORDERS_STATE_STARTED,
    GET_ORDERS_STATE_SUCCESS,
    GET_ORDERS_STATE_FAILURE,
    CHANGE_ORDER_STATE_STARTED,
    CHANGE_ORDER_STATE_SUCCESS,
    CONFIRM_ORDER_STATE_STARTED,
    CONFIRM_ORDER_STATE_SUCCESS,
    CONFIRM_ORDER_STATE_FAILURE,
    GET_ORDER_STATISTICS_STARTED,
    GET_ORDER_STATISTICS_SUCCESS,
    GET_ORDER_STATISTICS_FAILURE,
    GET_ORDERS_STATUS_FAILURE,
    GET_ORDERS_STATUS_SUCCESS,
    GET_ORDERS_STATUS_STARTED,
    GET_ORDER_HISTORY_STARTED,
    GET_ORDER_HISTORY_SUCCESS,
    GET_ORDER_HISTORY_FAILURE, CLEAN_ORDERS_REDUCER,
} from "./type";

const initialState = {
    ordersData: {
        orders: null,
        statistics: null,
        error: null,
    },
    ordersState: {
        orderState: [],
    },
    orderStatus: {
        countOfWait: 0,
        countOfComplete: 0,
    },
    isLoading: false,
};


const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER_HISTORY_STARTED: {
            return {
                ...state,
                isLoading: true,
                last: false
            }
        }

        case GET_ORDER_HISTORY_SUCCESS: {
            let loadedOrders = state.ordersData.orders ? state.ordersData.orders : [];
            return {
                ...state,
                ordersData: {
                    orders: [...loadedOrders, ...action.payload.orders],
                    error: null
                },
                page: action.payload.page,
                last: action.payload.last,
                isLoading: false,
            }
        }

        case GET_ORDER_HISTORY_FAILURE: {
            return {
                ...state,
                ordersData: {
                    error: action.error.message
                },
                isLoading: false
            }
        }

        case GET_ALL_ORDERS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ALL_ORDERS_SUCCESS: {
            return {
                ...state,
                ordersData: {
                    ...action.payload,
                    statistics: state.ordersData.statistics,
                    error: null
                },
                isLoading: false,
            }
        }

        case GET_ALL_ORDERS_FAILURE: {
            return {
                ...state,
                ordersData: {
                    error: action.error.message,
                },
                isLoading: false,
            }
        }

        case GET_ORDERS_STATE_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ORDERS_STATE_SUCCESS: {
            return {
                ...state,
                ordersState: {
                    orderState: action.payload,
                    error: null
                },
                isLoading: false,
            }
        }

        case GET_ORDERS_STATE_FAILURE: {
            return {
                ...state,
                ordersState: {
                    error: action.error.message,
                },
                isLoading: false,
            }
        }

        case CHANGE_ORDER_STATE_STARTED: {
            return {
                ...state
            }
        }

        case CHANGE_ORDER_STATE_SUCCESS: {
            const { idOrder, stateOrder } = action.payload;
            const lengthOrders = state.ordersData.orders.length;
            const idNum = parseInt(idOrder, 10);

            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    orders: [
                        ...state.ordersData.orders.slice(0, idNum),
                        {
                            ...state.ordersData.orders[idNum],
                            state: stateOrder
                        },
                        ...state.ordersData.orders.slice(idNum+1, lengthOrders)
                    ]
                }
            }
        }

        case CONFIRM_ORDER_STATE_STARTED: {
            return {
                ...state,
            }
        }

        case CONFIRM_ORDER_STATE_SUCCESS: {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    error: null,
                }
            }
        }

        case CONFIRM_ORDER_STATE_FAILURE: {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    error: action.error.message,
                }
            }
        }

        case GET_ORDER_STATISTICS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ORDER_STATISTICS_SUCCESS: {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    statistics: action.payload,
                    error: null,
                },
                isLoading: false,
            }
        }

        case GET_ORDER_STATISTICS_FAILURE: {
            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    error: action.error.message,
                },
                isLoading: false,
            }
        }

        case GET_ORDERS_STATUS_STARTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case GET_ORDERS_STATUS_SUCCESS: {
            return {
                ...state,
                orderStatus: action.payload,
                isLoading: false,
            }
        }

        case GET_ORDERS_STATUS_FAILURE: {
            return {
                ...state,
                orderStatus: {
                    error: action.error.message,
                },
                isLoading: false,
            }
        }

        case CLEAN_ORDERS_REDUCER: {

            return initialState;
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default ordersReducer;
