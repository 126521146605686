import {
    addToShoppingCart,
    checkVoucherCode, cleanShoppingCart,
    decreaseCountBottle,
    changeBottleCount,
    increaseCountBottle,
    recountShoppingCart,
    removeFromShoppingCart, removeVoucher,
} from "./action";

const addToShoppingCartAction = (product, count, price) => addToShoppingCart(product, count, price);
const removeFromShoppingCartAction = (idx) => removeFromShoppingCart(idx);
const increaseCountBottleAction = (idx, count, price) => increaseCountBottle(idx, count, price);
const decreaseCountBottleAction = (idx, price) => decreaseCountBottle(idx, price);
const recountSumShoppingCartAction = () => recountShoppingCart();
const checkVoucherAction = (voucherCode) => checkVoucherCode(voucherCode);
const removeVoucherAction = () => removeVoucher();
const cleanShoppingCartAction = () => cleanShoppingCart();
const changeBottleCountAction = (idx, count, price) => changeBottleCount(idx, count, price);

export default {
    addToShoppingCartAction,
    removeFromShoppingCartAction,
    increaseCountBottleAction,
    decreaseCountBottleAction,
    recountSumShoppingCartAction,
    checkVoucherAction,
    removeVoucherAction,
    cleanShoppingCartAction,
    changeBottleCountAction
}
