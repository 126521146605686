import React, {Component} from 'react';
import './../GlobalAdmin.scss';
import './EventAdminComponent.scss';
import LoadingComponent from "../common/LoadingComponent";
import AddButtonComponent from "../common/buttons/AddButtonComponent";
import {AiFillDelete, FaEdit} from "react-icons/all";
import {withBreakpoints} from "react-breakpoints";
import EventPopUpComponent from "./EventPopUpComponent";
import {Table} from "react-bootstrap";

class EventAdminComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
            eventToEdit: null,
            isAddingClick: false,
        }
    }

    componentDidMount() {
        const { getAllEvents } = this.props;
        getAllEvents();
    }

    handleCloseEdit = () => {
        this.setState({
            showPopUp: false,
            eventToEdit: null,
            isAddingClick: false,
        });
    };

    handleEdit = (idx) => {
        const { events } = this.props;
        this.setState({
            showPopUp: true,
            eventToEdit: events[idx],
            isAddingClick: false,
        });
    };

    handleRemove = (idx) => {
        const { removeEvent, events, getAllEvents } = this.props;
        removeEvent(events[idx].id).then(()=>{
            getAllEvents();
        })
    };

    handleClickNew = () => {
        this.setState({
            showPopUp: true,
            isAddingClick: true,
        });
    };
    formatDate = (dateAsString) => {
        const date = new Date(dateAsString);
        return `${date.toLocaleDateString("cs-CZ")}, ${date.toLocaleTimeString("cs-CZ")}`
    };

    formatLongText = (text) => {
        if(text.length > 30) {
            return `${text.substring(0, 30)}...`;
        }
        return text;
    };

    render() {
        const {
            events,
            isLoading,
            breakpoints,
            currentBreakpoint,
            getAllEvents,
            addNewEvent,
            editEvent,
        } = this.props;

        const {
            eventToEdit,
            isAddingClick,
            showPopUp,
        } = this.state;

        let mobileTdClassName = '';
        if(breakpoints[currentBreakpoint] < breakpoints.tablet) {
            mobileTdClassName = 'event-admin-container__mobile-td';
        }

        if(!events || isLoading) {
            return (
                <LoadingComponent />
            );
        } else {
            return(
                <div className="event-admin-container">
                    <span className='title-page'>Události</span>
                    <span className='product-admin-container__new-btn' onClick={this.handleClickNew}>
                        <AddButtonComponent />
                            Nová
                    </span>
                    <Table hover striped className="event-admin-container__table">
                        <thead>
                        <tr>
                            <th>Nadpis</th>
                            <th className={mobileTdClassName}>Text</th>
                            <th>Datum události</th>
                        </tr>
                        </thead>
                        <tbody>
                        {events.map((event, idx) => {
                            return (
                                <tr>
                                    <td>{event.title}</td>
                                    <td className={mobileTdClassName}>{this.formatLongText(event.text)}</td>
                                    <td>{this.formatDate(event.eventDate)}</td>
                                    <td><FaEdit className="event-admin-container__edit-icon" size={'30px'}
                                                color={'#D4AF37'} id={idx} onClick={() => this.handleEdit(idx)} />
                                    </td>
                                    <td>
                                        <AiFillDelete className="product-admin-container__edit-icon"
                                                      color={'#d42116'}
                                                      size={'30px'}
                                                      id={idx} onClick={() => this.handleRemove(idx)} />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    {
                        (eventToEdit || isAddingClick) &&
                        <EventPopUpComponent
                            event={eventToEdit}
                            isShow={showPopUp}
                            handleClose={this.handleCloseEdit}
                            editEventMethod={editEvent}
                            addNewEventMethod={addNewEvent}
                            getAllEventsMethod={getAllEvents}
                            isAdding={isAddingClick}
                        />
                    }
                </div>
            );
        }
    }
}

export default withBreakpoints(EventAdminComponent);
