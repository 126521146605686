import React, { Component } from "react";
import './EventDashboardContent.scss';
import EventItemComponent from "./EventItemComponent";
import LoadingDashboardComponent from "../../../common/LoadingDashboardComponent";

export default class EventDashboardContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: props.events || [],
            nextEvents: false,
        }
    }

    componentDidMount() {
        const { getAllEvents } = this.props;
            getAllEvents().then(() => {
                this.setState({
                    events: this.props.events
                });
            }
        );
    }

    cutEvents(events) {
        const { nextEvents } = this.state;
        if(events.length > 3 && !nextEvents) {
            return events.slice(0, 3);
        }
        return events
    }

    handleNextEvents = () => {
      this.setState({
          nextEvents: true,
      })
    };

    render() {
        const { isLoading, error } = this.props;
        const { events, nextEvents } = this.state;
        if(isLoading) {
            return (
                <LoadingDashboardComponent />
            )
        } else if(!events || error) {
            return(
                <></>
            );
        } else {
            const cuttedEvents = this.cutEvents(events);
            return (
                <div className='event-dashboard-content'>
                    <div className='event-dashboard-content__container'>
                        {
                            cuttedEvents.map((event) => {
                                return (
                                    <div className='event-dashboard-content__item'>
                                        <EventItemComponent event={event}/>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        (events.length > 3 && !nextEvents) &&
                            <span className='event-dashboard-content__next-events'
                                  onClick={this.handleNextEvents}
                            >
                                Další akce
                            </span>
                    }
                </div>
            );
        }
    }
}
