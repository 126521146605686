import React from "react";
import {Spinner} from "react-bootstrap";
import './LoadingComponent.scss';

function LoadingComponent() {

    return(
        <div className='spinner-container'>
            <Spinner animation='grow' size='lg' className='spinner-container__spin'/>
            <Spinner animation='grow' size='lg' className='spinner-container__spin'/>
            <Spinner animation='grow' size='lg' className='spinner-container__spin'/>
        </div>
    )
}

export default LoadingComponent;
