export const GET_TYPES_DELIVERY_STARTED = 'GET_TYPES_DELIVERY_STARTED';
export const GET_TYPES_DELIVERY_SUCCESS = 'GET_TYPES_DELIVERY_SUCCESS';
export const GET_TYPES_DELIVERY_FAILURE = 'GET_TYPES_DELIVERY_FAILURE';
export const SELECT_TYPE_DELIVERY = 'SELECT_TYPE_DELIVERY';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SUBMIT_ORDER_STARTED = 'SUBMIT_ORDER_STARTED';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';
export const CLEAN_COMPLETED_ORDER = 'CLEAN_COMPLETED_ORDER';
export const GET_PAYMENT_METHOD_STARTED = 'GET_PAYMENT_METHOD_STARTED';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';
